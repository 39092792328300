import { useMemo } from 'react'

import {
  useFleetsDriversTokensQuery,
  useFleetsCardOrdersQuery,
  EjnEjnCardOrderStatusChoices,
} from '@electro/fleets/generated/graphql'

import {
  AllElectroCardsAssignedModal,
  AssignElectrocardModal,
  OrderMoreElectroCardsModal,
  YourElectrocardsAreOnTheWayModal,
} from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/components/AssignElectroCardModalScreen/components'
import { LoadingOverlay } from '@electro/shared-ui-components'

export const AssignElectroCardModalScreen = () => {
  const { data, loading } = useFleetsDriversTokensQuery({
    variables: {
      first: 50,
      offset: 0,
    },
  })
  const { data: cardOrderData, loading: cardOrderLoading } = useFleetsCardOrdersQuery()
  const hasElectrocards = useMemo(() => data?.fleetsDriversTokens.edgeCount > 0, [data])
  const hasUnassignedCards = useMemo(() => data?.unassignedTokens?.totalCount > 0, [data])
  const hasOrderedElectroCards = useMemo(
    () =>
      cardOrderData?.fleetsCardOrders?.edges?.filter(
        (cardOrder) =>
          cardOrder.node.status === EjnEjnCardOrderStatusChoices.New ||
          cardOrder.node.status === EjnEjnCardOrderStatusChoices.Pending,
      )?.length > 0,
    [cardOrderData],
  )
  const hasNoUnAssignedElectrocards = useMemo(
    () => !hasUnassignedCards && !hasOrderedElectroCards && hasElectrocards,
    [hasUnassignedCards, hasOrderedElectroCards, hasElectrocards],
  )
  const hasPlacedAnOrder = useMemo(
    () => !hasUnassignedCards && hasOrderedElectroCards,
    [hasUnassignedCards, hasOrderedElectroCards],
  )

  if (loading || cardOrderLoading) {
    return (
      <div className="h-112">
        <LoadingOverlay fixed />
      </div>
    )
  }

  return (
    <>
      <AssignElectrocardModal show={hasUnassignedCards} />
      <OrderMoreElectroCardsModal show={!hasElectrocards && !hasOrderedElectroCards} />
      <AllElectroCardsAssignedModal show={hasNoUnAssignedElectrocards} />
      <YourElectrocardsAreOnTheWayModal show={hasPlacedAnOrder} />
    </>
  )
}
