import { Button, Card, Typography } from '@electro/shared-ui-components'
import { DriverType } from '@electro/fleets/generated/graphql'
import { PlusIcon } from '@heroicons/react/24/outline'
import { NoSymbolIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/solid'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import { ModalScreensEnum } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/DriverWidgets.types'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  driver: DriverType
}

export const ElectrocardWidget = ({ driver }: Props) => {
  const { t } = useTranslation('common')
  const { setActiveModalScreen } = useDriverWidgets()
  const hasElectroCardAssigned = driver.user.hasElectricJuiceCard

  const openDeactivateModalScreen = () => {
    setActiveModalScreen(ModalScreensEnum.DEACTIVATE_ELECTROCARD_MODAL_SCREEN)
  }

  const openAssignCardModalScreen = () => {
    setActiveModalScreen(ModalScreensEnum.ASSIGN_ELECTROCARD_MODAL_SCREEN)
  }

  const openReAssignCardModalScreen = () => {
    setActiveModalScreen(ModalScreensEnum.REASSIGN_ELECTROCARD_MODAL_SCREEN)
  }

  return (
    <Card className="col-span-2 lg:p-8 p-3">
      <div className="flex flex-row justify-between">
        <Typography variant="h3" className="lg:flex-1 lg:text-lg text-base">
          {t('common.electrocard')}
        </Typography>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-2">
        <Typography variant="h5">{t('common.number')}</Typography>
        <Typography>{driver?.ejnCard?.number || '-'}</Typography>

        <Typography variant="h5">{t('common.status')}</Typography>
        <Typography>{driver?.ejnCard?.status || '-'}</Typography>
      </div>
      <div className="flex justify-end lg:w-full flex-row mx-1 mt-5">
        <div className="flex gap-2 basis-1/2 lg:basis-full md:flex-wrap lg:flex-nowrap justify-end">
          {hasElectroCardAssigned ? (
            <>
              <Button size="xs" onClick={openReAssignCardModalScreen} fullWidth>
                <ArrowsRightLeftIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
                {t('drivers.widgets.driver_details.driver_actions.reassign_card')}
              </Button>

              <Button size="xs" onClick={openDeactivateModalScreen} fullWidth>
                <NoSymbolIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
                {t('drivers.widgets.driver_details.driver_actions.deactivate_card')}
              </Button>
            </>
          ) : (
            <Button size="xs" onClick={openAssignCardModalScreen} fullWidth className="basis-1/2">
              <PlusIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
              {t('drivers.widgets.driver_details.driver_actions.assign_card')}
            </Button>
          )}
        </div>
      </div>
    </Card>
  )
}
