import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  EmailType: { input: any; output: any; }
  FileUpload: { input: any; output: any; }
  GenericScalar: { input: any; output: any; }
  PointJSON: { input: any; output: any; }
};

export type AccountType = {
  __typename?: 'AccountType';
  entityDomain: Scalars['String']['output'];
  entityName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  isFleetAdmin?: Maybe<Scalars['Boolean']['output']>;
  isFleetDriver?: Maybe<Scalars['Boolean']['output']>;
};

export type AddDriverToGroups = {
  __typename?: 'AddDriverToGroups';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AddDriverVehicle = {
  __typename?: 'AddDriverVehicle';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AddFleetToCampaign = {
  __typename?: 'AddFleetToCampaign';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Mutation for adding tags. */
export type AddTag = {
  __typename?: 'AddTag';
  pk: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type AddVehicle = {
  __typename?: 'AddVehicle';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Mutation for fleets admins to request a reset password email */
export type AdminSendResetPasswordEmail = {
  __typename?: 'AdminSendResetPasswordEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Mutation for sending admin's sign up email.
 * It allows admins to resend the email
 * if the admin hasn't been activated yet.
 */
export type AdminSendSignupEmail = {
  __typename?: 'AdminSendSignupEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Mutation for admin's first time sign up.
 * It allows admins to set their own passwords.
 */
export type AdminSetPassword = {
  __typename?: 'AdminSetPassword';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminType = Node & {
  __typename?: 'AdminType';
  businessEntity: BusinessEntityType;
  createdAt: Scalars['DateTime']['output'];
  hasActiveAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Designates admins who can edit billing info */
  hasBillingPermission: Scalars['Boolean']['output'];
  hasSignedUp?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Designates primary admin for the fleet */
  isPrimaryAdmin: Scalars['Boolean']['output'];
  /** Designates that this user has all Fleets permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<UserType>;
};

export type AdminTypeConnection = {
  __typename?: 'AdminTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdminTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `AdminType` and its cursor. */
export type AdminTypeEdge = {
  __typename?: 'AdminTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AdminType>;
};

export type AdminTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: AdminTypeOrderingFields;
};

/** An enumeration. */
export enum AdminTypeOrderingFields {
  Pk = 'PK'
}

export type AffiliateType = Node & {
  __typename?: 'AffiliateType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
};

export type ApproximateSpendType = {
  __typename?: 'ApproximateSpendType';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
};

export type AssignVehicle = {
  __typename?: 'AssignVehicle';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum AvatarOptions {
  Adventurer = 'ADVENTURER',
  Battery = 'BATTERY',
  BringThePower = 'BRING_THE_POWER',
  Charged_250Kwh = 'CHARGED_250KWH',
  Charged_1000Kwh = 'CHARGED_1000KWH',
  Charged_5000Kwh = 'CHARGED_5000KWH',
  Co2Saved_50Kg = 'CO2_SAVED_50KG',
  Co2Saved_250Kg = 'CO2_SAVED_250KG',
  Co2Saved_1000Kg = 'CO2_SAVED_1000KG',
  Connoisseur = 'CONNOISSEUR',
  Constantine = 'CONSTANTINE',
  ElectricWheels = 'ELECTRIC_WHEELS',
  Electroverse = 'ELECTROVERSE',
  Experimenter = 'EXPERIMENTER',
  Explorer = 'EXPLORER',
  FriendlyFace = 'FRIENDLY_FACE',
  Magician = 'MAGICIAN',
  ShellCoffee = 'SHELL_COFFEE'
}

export type BaseIacOptions = {
  __typename?: 'BaseIACOptions';
  isPlugInSecond: Scalars['Boolean']['output'];
  timeoutSeconds: Scalars['Int']['output'];
};

/**
 * This is a copy of ocpi_models.SessionStatus enum.
 * It needs to be copied because Graphene tries to instantiate this enum
 * with name `Status` and such enum already exists for EVSE which is really
 * annoying.
 * Note that the graphene.Enum.from_enum won't work because graphene
 * underneath does
 * ```
 * type(meta_class.enum.__name__, (Enum,), {"Meta": meta_class})
 * ```
 * which ignores any name given by the new class and uses the base name
 * instead.
 */
export enum BasicSessionStatusEnum {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Invalid = 'INVALID',
  Pending = 'PENDING'
}

export type BasicVehicleLookupResultType = {
  __typename?: 'BasicVehicleLookupResultType';
  isElectric?: Maybe<Scalars['Boolean']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<VehicleType>>>;
};

export type BillingDetailsType = {
  __typename?: 'BillingDetailsType';
  billingAddressLine1?: Maybe<Scalars['String']['output']>;
  billingAddressLine2?: Maybe<Scalars['String']['output']>;
  billingAddressPostcode?: Maybe<Scalars['String']['output']>;
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  concatenatedAddressLines345?: Maybe<Scalars['String']['output']>;
};

export type BulkCreateDrivers = {
  __typename?: 'BulkCreateDrivers';
  success: Scalars['Boolean']['output'];
};

export type BusinessEntityExpensesAggregateConnectionTypeConnection = {
  __typename?: 'BusinessEntityExpensesAggregateConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BusinessEntityExpensesAggregateConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `BusinessEntityExpensesAggregateConnectionType` and its cursor. */
export type BusinessEntityExpensesAggregateConnectionTypeEdge = {
  __typename?: 'BusinessEntityExpensesAggregateConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BusinessEntityExpensesAggregateType>;
};

export type BusinessEntityExpensesAggregateConnectionTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: BusinessEntityExpensesAggregateConnectionTypeOrderingFields;
};

/** An enumeration. */
export enum BusinessEntityExpensesAggregateConnectionTypeOrderingFields {
  Count = 'COUNT',
  Name = 'NAME',
  Pk = 'PK'
}

export type BusinessEntityExpensesAggregateType = {
  __typename?: 'BusinessEntityExpensesAggregateType';
  businessEntity?: Maybe<BusinessEntityType>;
  count?: Maybe<Scalars['Int']['output']>;
  totalCost?: Maybe<Scalars['Decimal']['output']>;
};

export type BusinessEntityReceiptsAggregateConnectionTypeConnection = {
  __typename?: 'BusinessEntityReceiptsAggregateConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BusinessEntityReceiptsAggregateConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `BusinessEntityReceiptsAggregateConnectionType` and its cursor. */
export type BusinessEntityReceiptsAggregateConnectionTypeEdge = {
  __typename?: 'BusinessEntityReceiptsAggregateConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BusinessEntityReceiptsAggregateType>;
};

export type BusinessEntityReceiptsAggregateConnectionTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: BusinessEntityReceiptsAggregateConnectionTypeOrderingFields;
};

/** An enumeration. */
export enum BusinessEntityReceiptsAggregateConnectionTypeOrderingFields {
  Name = 'NAME',
  NumberOfReceipts = 'NUMBER_OF_RECEIPTS',
  Pk = 'PK',
  TotalConsumption = 'TOTAL_CONSUMPTION',
  TotalCost = 'TOTAL_COST'
}

export type BusinessEntityReceiptsAggregateType = {
  __typename?: 'BusinessEntityReceiptsAggregateType';
  businessEntity?: Maybe<BusinessEntityType>;
  numberOfReceipts?: Maybe<Scalars['Int']['output']>;
  totalConsumption?: Maybe<Scalars['Decimal']['output']>;
  totalCost?: Maybe<Scalars['Decimal']['output']>;
  totalPricePerKwh?: Maybe<Scalars['String']['output']>;
};

/**
 * Mutation for resending verification email.
 * Allows creating admin to resend email to themselves
 * if they haven't yet verified.
 */
export type BusinessEntitySendVerificationEmail = {
  __typename?: 'BusinessEntitySendVerificationEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type BusinessEntityType = {
  __typename?: 'BusinessEntityType';
  billingAccount?: Maybe<FleetsBillingAccountType>;
  charityNumber?: Maybe<Scalars['String']['output']>;
  companyHouseNumber?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<FleetSignUpCompanyType>;
  countryCode: Scalars['String']['output'];
  deliveryAddressLine1: Scalars['String']['output'];
  deliveryAddressLine2: Scalars['String']['output'];
  deliveryAddressLine3: Scalars['String']['output'];
  deliveryAddressLine4: Scalars['String']['output'];
  deliveryCountry: Scalars['String']['output'];
  deliveryPostcode: Scalars['String']['output'];
  hasCharged?: Maybe<Scalars['Boolean']['output']>;
  hasPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  isOnboarded: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  registeredAt: Scalars['DateTime']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export type CampaignType = Node & {
  __typename?: 'CampaignType';
  affiliate: AffiliateType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** This is for internal use only */
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
};

export type CampaignTypeConnection = {
  __typename?: 'CampaignTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CampaignTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CampaignType` and its cursor. */
export type CampaignTypeEdge = {
  __typename?: 'CampaignTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CampaignType>;
};

export type CancelPaymentMethod = {
  __typename?: 'CancelPaymentMethod';
  success: Scalars['Boolean']['output'];
};

export type Capability = {
  name?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum CardDesignOptions {
  Biz = 'BIZ',
  Ejn = 'EJN',
  Oeu = 'OEU',
  Rtc = 'RTC'
}

/**
 * Mutation which lets the user change their password - only works for
 * user chosen passwords, not those set by magic link.
 */
export type ChangePassword = {
  __typename?: 'ChangePassword';
  success: Scalars['Boolean']['output'];
};

export type ChargerUsageSplitType = {
  __typename?: 'ChargerUsageSplitType';
  chargerSpeed?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type ChargingSessionStatusType = {
  __typename?: 'ChargingSessionStatusType';
  message?: Maybe<Scalars['String']['output']>;
  status: SessionStatus;
};

export type ChargingSessionType = {
  approxSpend?: Maybe<ApproximateSpendType>;
  basicStatus: BasicSessionStatusEnum;
  connector?: Maybe<EjnConnectorType>;
  createdAt: Scalars['DateTime']['output'];
  dismissedAt?: Maybe<Scalars['DateTime']['output']>;
  duration: Scalars['Int']['output'];
  evse: EjnevseType;
  isDismissed: Scalars['Boolean']['output'];
  kwh?: Maybe<Scalars['Decimal']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  sessionType: ChargingSessionTypeEnum;
  soc?: Maybe<Scalars['Decimal']['output']>;
  status: ChargingSessionStatusType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ChargingSessionTypeEnum {
  InAppCharging = 'IN_APP_CHARGING',
  PlugAndCharge = 'PLUG_AND_CHARGE',
  Rfid = 'RFID'
}

export type CharityAddressType = {
  __typename?: 'CharityAddressType';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  addressLine4?: Maybe<Scalars['String']['output']>;
  addressLine5?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type CharityInformationType = {
  __typename?: 'CharityInformationType';
  address: CharityAddressType;
  charityName: Scalars['String']['output'];
  charityNumber: Scalars['String']['output'];
};

export type CompanyHouseInformationType = {
  __typename?: 'CompanyHouseInformationType';
  companyName: Scalars['String']['output'];
  companyNumber: Scalars['String']['output'];
  registeredOfficeAddress: CompanyHouseOfficeAddressType;
};

export type CompanyHouseOfficeAddressType = {
  __typename?: 'CompanyHouseOfficeAddressType';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  locality: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type CompleteFleetSignUp = {
  __typename?: 'CompleteFleetSignUp';
  payload?: Maybe<Scalars['String']['output']>;
  refreshExpiresIn?: Maybe<Scalars['Int']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type CountryType = Node & {
  __typename?: 'CountryType';
  alpha2: Scalars['String']['output'];
  alpha3: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isSignupSupported: Scalars['Boolean']['output'];
  isValidDeliveryLocation: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  numeric: Scalars['String']['output'];
  officialName: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
};

export type CountryTypeConnection = {
  __typename?: 'CountryTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CountryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CountryType` and its cursor. */
export type CountryTypeEdge = {
  __typename?: 'CountryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CountryType>;
};

export type CpoDetailsType = {
  __typename?: 'CpoDetailsType';
  name?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
};

export type CpoUsageSplitType = {
  __typename?: 'CpoUsageSplitType';
  cpo?: Maybe<CpoDetailsType>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

/** Creates a new admin for an existing fleet. */
export type CreateAdmin = {
  __typename?: 'CreateAdmin';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateBusinessEntity = {
  __typename?: 'CreateBusinessEntity';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Creates a fleet driver and all the associated models. */
export type CreateDriver = {
  __typename?: 'CreateDriver';
  pk: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateDriverGroup = {
  __typename?: 'CreateDriverGroup';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CurrencyType = Node & {
  __typename?: 'CurrencyType';
  /** How many decimal places the major currency units may have */
  decimalDigits: Scalars['Int']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** ISO_4217 alphabetic currency code */
  isoCode: Scalars['String']['output'];
  /** Conversion from minor to major currency unit e.g. 100 pence to a pound */
  minorUnitConversion: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  namePlural: Scalars['String']['output'];
  /** Symbol to represent currency when displaying formatted values e.g. $ */
  nativeSymbol: Scalars['String']['output'];
  /** Symbol to represent currency when displaying formatted values e.g. AU$ */
  symbol: Scalars['String']['output'];
};

export type CurrentBalance = {
  __typename?: 'CurrentBalance';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
};

export type CustomisationOptions = {
  __typename?: 'CustomisationOptions';
  avatar?: Maybe<AvatarOptions>;
  mapStyle?: Maybe<MapStyleOptions>;
};

/**
 *
 *     Status of users debt situation. First we create a debt entry with
 *     status ISSUED which at the moment corresponds to user being warned.
 *     After meeting certain criteria we move the status up to ESCALATED which at
 *     the moment means the user will be suspended.
 *     If all the outstanding payment records get settled we RESOLVE it.
 *
 */
export enum DebtWarningStatus {
  Escalated = 'ESCALATED',
  Issued = 'ISSUED',
  Resolved = 'RESOLVED'
}

/** Mutation for deleting tags. */
export type DeleteTag = {
  __typename?: 'DeleteTag';
  success: Scalars['Boolean']['output'];
};

export type DirectDebitType = PaymentMethodDetailsType & {
  __typename?: 'DirectDebitType';
  accountHolder?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  sortCode?: Maybe<Scalars['String']['output']>;
};

export type DiscountInterface = {
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  rate: Scalars['Float']['output'];
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
  variety: DiscountVariety;
};

/** An enumeration. */
export enum DiscountVariety {
  Country = 'COUNTRY',
  Global = 'GLOBAL',
  Membership = 'MEMBERSHIP',
  Subscription = 'SUBSCRIPTION'
}

export type DismissChargingSession = {
  __typename?: 'DismissChargingSession';
  success: Scalars['Boolean']['output'];
};

/** Mutation for assigning a card to a driver. */
export type DriverAssignCard = {
  __typename?: 'DriverAssignCard';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DriverCpoUsageSplitType = {
  __typename?: 'DriverCpoUsageSplitType';
  cpoSplit?: Maybe<Array<Maybe<CpoUsageSplitType>>>;
  driverId?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type DriverExpensesConnectionTypeConnection = {
  __typename?: 'DriverExpensesConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverExpensesConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `DriverExpensesConnectionType` and its cursor. */
export type DriverExpensesConnectionTypeEdge = {
  __typename?: 'DriverExpensesConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverExpensesType>;
};

export type DriverExpensesConnectionTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: DriverExpensesConnectionTypeOrderingFields;
};

/** An enumeration. */
export enum DriverExpensesConnectionTypeOrderingFields {
  DriverName = 'DRIVER_NAME',
  Pk = 'PK'
}

export type DriverExpensesType = Node & {
  __typename?: 'DriverExpensesType';
  amount: Scalars['Decimal']['output'];
  currency: CurrencyType;
  driver: DriverType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  merchant?: Maybe<MerchantType>;
  status?: Maybe<DriversExpenseStatusOptions>;
};

/** Mutation for cancelling driver's card. */
export type DriverFreezeCard = {
  __typename?: 'DriverFreezeCard';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DriverGroupType = Node & {
  __typename?: 'DriverGroupType';
  businessEntity: BusinessEntityType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
};

export type DriverGroupTypeConnection = {
  __typename?: 'DriverGroupTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverGroupTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DriverGroupType` and its cursor. */
export type DriverGroupTypeEdge = {
  __typename?: 'DriverGroupTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverGroupType>;
};

export type DriverGroupsReceiptsAggregateConnectionTypeConnection = {
  __typename?: 'DriverGroupsReceiptsAggregateConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverGroupsReceiptsAggregateConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `DriverGroupsReceiptsAggregateConnectionType` and its cursor. */
export type DriverGroupsReceiptsAggregateConnectionTypeEdge = {
  __typename?: 'DriverGroupsReceiptsAggregateConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverGroupsReceiptsAggregateType>;
};

export type DriverGroupsReceiptsAggregateConnectionTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: DriverGroupsReceiptsAggregateConnectionTypeOrderingFields;
};

/** An enumeration. */
export enum DriverGroupsReceiptsAggregateConnectionTypeOrderingFields {
  Name = 'NAME',
  NumberOfReceipts = 'NUMBER_OF_RECEIPTS',
  Pk = 'PK',
  TotalConsumption = 'TOTAL_CONSUMPTION',
  TotalCost = 'TOTAL_COST'
}

export type DriverGroupsReceiptsAggregateType = {
  __typename?: 'DriverGroupsReceiptsAggregateType';
  businessEntity?: Maybe<BusinessEntityType>;
  driverGroup?: Maybe<DriverGroupType>;
  numberOfReceipts?: Maybe<Scalars['Int']['output']>;
  totalConsumption?: Maybe<Scalars['Decimal']['output']>;
  totalCost?: Maybe<Scalars['Decimal']['output']>;
  totalPricePerKwh?: Maybe<Scalars['String']['output']>;
};

/**
 * Mutation for reassigning a card from one driver to another.
 * The target driver is specified in the mutation argument.
 */
export type DriverReassignCard = {
  __typename?: 'DriverReassignCard';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DriverReceiptType = Node & {
  __typename?: 'DriverReceiptType';
  additionalFees: Scalars['Decimal']['output'];
  chargingLocationId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  driver?: Maybe<DriverType>;
  driverId: Scalars['Int']['output'];
  /** Duration in seconds */
  duration: Scalars['Int']['output'];
  grossAmount: Scalars['Decimal']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  kwhUsed: Scalars['Decimal']['output'];
  location?: Maybe<FleetReceiptLocationType>;
  locationId: Scalars['Int']['output'];
  netAmount: Scalars['Decimal']['output'];
  operator?: Maybe<OperatorType>;
  operatorId: Scalars['Int']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  pk: Scalars['Int']['output'];
  pricePerKwh: Scalars['Decimal']['output'];
  status?: Maybe<DriversReceiptStatusOptions>;
};

export type DriverReceiptsConnectionTypeConnection = {
  __typename?: 'DriverReceiptsConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverReceiptsConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `DriverReceiptsConnectionType` and its cursor. */
export type DriverReceiptsConnectionTypeEdge = {
  __typename?: 'DriverReceiptsConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverReceiptType>;
};

export type DriverReceiptsConnectionTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: DriverReceiptsConnectionTypeOrderingFields;
};

/** An enumeration. */
export enum DriverReceiptsConnectionTypeOrderingFields {
  DriverName = 'DRIVER_NAME',
  GrossAmount = 'GROSS_AMOUNT',
  KwhUsed = 'KWH_USED',
  NetAmount = 'NET_AMOUNT',
  PeriodEnd = 'PERIOD_END',
  Pk = 'PK'
}

/** Mutation for a fleet admin to send a driver password reset email. */
export type DriverSendPasswordResetEmail = {
  __typename?: 'DriverSendPasswordResetEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Mutation for sending driver's sign up email.
 * It allows admins to resend the email
 * if the driver hasn't been activated yet.
 */
export type DriverSendSignupEmail = {
  __typename?: 'DriverSendSignupEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Mutation for driver's first time sign up.
 * It allows drivers to set their own passwords.
 */
export type DriverSetPassword = {
  __typename?: 'DriverSetPassword';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DriverTokenType = Node & {
  __typename?: 'DriverTokenType';
  createdAt: Scalars['DateTime']['output'];
  driver?: Maybe<DriverType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  status: EjnEjnTokenStatusChoices;
};

export type DriverTokenTypeConnection = {
  __typename?: 'DriverTokenTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverTokenTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `DriverTokenType` and its cursor. */
export type DriverTokenTypeEdge = {
  __typename?: 'DriverTokenTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverTokenType>;
};

export type DriverTokenTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: DriverTokenTypeOrderingFields;
};

/** An enumeration. */
export enum DriverTokenTypeOrderingFields {
  CreatedAt = 'CREATED_AT',
  DriverName = 'DRIVER_NAME',
  Number = 'NUMBER',
  Pk = 'PK',
  Status = 'STATUS'
}

export type DriverType = Node & {
  __typename?: 'DriverType';
  businessEntity: BusinessEntityType;
  createdAt: Scalars['DateTime']['output'];
  drivingLicence?: Maybe<Scalars['String']['output']>;
  ejnCard?: Maybe<EjnCard>;
  hasActiveAccount?: Maybe<Scalars['Boolean']['output']>;
  hasSignedUp?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  mobileNumber?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Maybe<FleetsTagType>>>;
  user?: Maybe<UserType>;
};

export type DriverTypeConnection = {
  __typename?: 'DriverTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `DriverType` and its cursor. */
export type DriverTypeEdge = {
  __typename?: 'DriverTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriverType>;
};

export type DriverTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: DriverTypeOrderingFields;
};

/** An enumeration. */
export enum DriverTypeOrderingFields {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Pk = 'PK'
}

/** An enumeration. */
export enum DriversExpenseStatusOptions {
  Invoiced = 'INVOICED',
  Pending = 'PENDING',
  Processed = 'PROCESSED'
}

export type DriversExpensesAggregateConnectionTypeConnection = {
  __typename?: 'DriversExpensesAggregateConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriversExpensesAggregateConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `DriversExpensesAggregateConnectionType` and its cursor. */
export type DriversExpensesAggregateConnectionTypeEdge = {
  __typename?: 'DriversExpensesAggregateConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriversExpensesAggregateType>;
};

export type DriversExpensesAggregateConnectionTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: DriversExpensesAggregateConnectionTypeOrderingFields;
};

/** An enumeration. */
export enum DriversExpensesAggregateConnectionTypeOrderingFields {
  Count = 'COUNT',
  Name = 'NAME',
  Pk = 'PK',
  TotalCost = 'TOTAL_COST'
}

export type DriversExpensesAggregateType = {
  __typename?: 'DriversExpensesAggregateType';
  businessEntity?: Maybe<BusinessEntityType>;
  count?: Maybe<Scalars['Int']['output']>;
  driver?: Maybe<DriverType>;
  totalCost?: Maybe<Scalars['Decimal']['output']>;
};

/** An enumeration. */
export enum DriversReceiptStatusOptions {
  Invoiced = 'INVOICED',
  Pending = 'PENDING',
  Processed = 'PROCESSED'
}

export type DriversReceiptsAggregateConnectionTypeConnection = {
  __typename?: 'DriversReceiptsAggregateConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriversReceiptsAggregateConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `DriversReceiptsAggregateConnectionType` and its cursor. */
export type DriversReceiptsAggregateConnectionTypeEdge = {
  __typename?: 'DriversReceiptsAggregateConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DriversReceiptsAggregateType>;
};

export type DriversReceiptsAggregateConnectionTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: DriversReceiptsAggregateConnectionTypeOrderingFields;
};

/** An enumeration. */
export enum DriversReceiptsAggregateConnectionTypeOrderingFields {
  Name = 'NAME',
  NumberOfReceipts = 'NUMBER_OF_RECEIPTS',
  Pk = 'PK',
  TotalConsumption = 'TOTAL_CONSUMPTION',
  TotalCost = 'TOTAL_COST',
  TotalPricePerKwh = 'TOTAL_PRICE_PER_KWH'
}

export type DriversReceiptsAggregateType = {
  __typename?: 'DriversReceiptsAggregateType';
  businessEntity?: Maybe<BusinessEntityType>;
  driver?: Maybe<DriverType>;
  numberOfReceipts?: Maybe<Scalars['Int']['output']>;
  totalConsumption?: Maybe<Scalars['Decimal']['output']>;
  totalCost?: Maybe<Scalars['Decimal']['output']>;
  totalPricePerKwh?: Maybe<Scalars['String']['output']>;
};

export type EjnCard = Node & {
  __typename?: 'EJNCard';
  design?: Maybe<CardDesignOptions>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  nameOnCard?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  status: EjnEjnTokenStatusChoices;
};

export type EjnCardDeliveryDetailsInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  addressLine4?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
};

export type EjnConnectorType = GenericConnectorInterface & {
  __typename?: 'EJNConnectorType';
  evse: EjnevseType;
  isChargingFree?: Maybe<Scalars['Boolean']['output']>;
  kilowatts?: Maybe<Scalars['Int']['output']>;
  pk: Scalars['Int']['output'];
  priceComponents?: Maybe<Array<Maybe<PriceComponent>>>;
  pricing?: Maybe<PricingType>;
  speed?: Maybe<Speeds>;
  standard?: Maybe<GenericConnectorStandardInterface>;
};

export type EjnevseType = GenericEvseInterface & {
  __typename?: 'EJNEVSEType';
  connectors?: Maybe<GenericConnectorConnectionTypeConnection>;
  lastSessionStartedAt?: Maybe<Scalars['DateTime']['output']>;
  location: EjnLocationType;
  physicalReference?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  status?: Maybe<Status>;
  supportsInAppCharging: Scalars['Boolean']['output'];
};


export type EjnevseTypeConnectorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  socketGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  speeds?: InputMaybe<Array<InputMaybe<Speeds>>>;
};

export type EjnLocationType = GenericLocationInterface & {
  __typename?: 'EJNLocationType';
  address?: Maybe<Scalars['String']['output']>;
  alerts?: Maybe<Array<Maybe<LocationAlertType>>>;
  capabilities?: Maybe<Array<Maybe<Capability>>>;
  chargingLocationPk: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  coordinates: Scalars['PointJSON']['output'];
  country?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  evses?: Maybe<GenericEvseConnectionTypeConnection>;
  facilities: Array<Maybe<Facility>>;
  gridState?: Maybe<GridState>;
  isEjnLocation: Scalars['Boolean']['output'];
  isGreenEnergy?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<GenericOpeningTimes>;
  operator?: Maybe<GenericOperatorInterface>;
  pk: Scalars['Int']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};


export type EjnLocationTypeEvsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  connectorSocketGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  connectorSpeeds?: InputMaybe<Array<InputMaybe<Speeds>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Status>>>;
};

export type EditAdminActiveStatus = {
  __typename?: 'EditAdminActiveStatus';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type EditBusinessEntity = {
  __typename?: 'EditBusinessEntity';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type EditBusinessEntityDisplayName = {
  __typename?: 'EditBusinessEntityDisplayName';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type EditDriver = {
  __typename?: 'EditDriver';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Mutation for editing tags. */
export type EditTag = {
  __typename?: 'EditTag';
  success: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum EjnEjnCardOrderStatusChoices {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  New = 'NEW',
  Pending = 'PENDING',
  Unregistered = 'UNREGISTERED'
}

/** An enumeration. */
export enum EjnEjnTokenStatusChoices {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Unassigned = 'UNASSIGNED'
}

/**
 *
 *     List of error codes describing specific error conditions raised by
 *     the application.
 *
 */
export enum ErrorCode {
  ActiveKrakenAccountAlreadyExists = 'ACTIVE_KRAKEN_ACCOUNT_ALREADY_EXISTS',
  ActiveKrakenAccountDoesNotExist = 'ACTIVE_KRAKEN_ACCOUNT_DOES_NOT_EXIST',
  AddressLookupFailed = 'ADDRESS_LOOKUP_FAILED',
  AdminDoesNotExist = 'ADMIN_DOES_NOT_EXIST',
  AdminResetPasswordError = 'ADMIN_RESET_PASSWORD_ERROR',
  AuthorizationError = 'AUTHORIZATION_ERROR',
  CampaignAlreadyRedeemed = 'CAMPAIGN_ALREADY_REDEEMED',
  CampaignDoesNotExist = 'CAMPAIGN_DOES_NOT_EXIST',
  CannotAssignCardToDriver = 'CANNOT_ASSIGN_CARD_TO_DRIVER',
  ChargingSessionDoesNotExist = 'CHARGING_SESSION_DOES_NOT_EXIST',
  CharityApiUnavailable = 'CHARITY_API_UNAVAILABLE',
  CharityBadResponse = 'CHARITY_BAD_RESPONSE',
  CharityNumberNotFound = 'CHARITY_NUMBER_NOT_FOUND',
  CompanyHouseApiUnavailable = 'COMPANY_HOUSE_API_UNAVAILABLE',
  CompanyHouseBadResponse = 'COMPANY_HOUSE_BAD_RESPONSE',
  CompanyHouseNumberNotFound = 'COMPANY_HOUSE_NUMBER_NOT_FOUND',
  CompanyLiquidated = 'COMPANY_LIQUIDATED',
  CoordinateAddressLookupFailed = 'COORDINATE_ADDRESS_LOOKUP_FAILED',
  CountryCodeInvalid = 'COUNTRY_CODE_INVALID',
  CpmsUpdateError = 'CPMS_UPDATE_ERROR',
  CreditCardNotFound = 'CREDIT_CARD_NOT_FOUND',
  CreditCardStatusNotUpdated = 'CREDIT_CARD_STATUS_NOT_UPDATED',
  CreditCodeAlreadyRedeemed = 'CREDIT_CODE_ALREADY_REDEEMED',
  CreditCodeDoesNotExist = 'CREDIT_CODE_DOES_NOT_EXIST',
  CreditCodeExpired = 'CREDIT_CODE_EXPIRED',
  CsvDecodingError = 'CSV_DECODING_ERROR',
  CurrencyDoesNotExist = 'CURRENCY_DOES_NOT_EXIST',
  CurrentPasswordInvalid = 'CURRENT_PASSWORD_INVALID',
  DemoRequestAlreadyExists = 'DEMO_REQUEST_ALREADY_EXISTS',
  DriverCannotBeBillingUser = 'DRIVER_CANNOT_BE_BILLING_USER',
  DriverDoesNotExist = 'DRIVER_DOES_NOT_EXIST',
  EjnCardAlreadyAssigned = 'EJN_CARD_ALREADY_ASSIGNED',
  EjnCardAssignmentFailed = 'EJN_CARD_ASSIGNMENT_FAILED',
  EjnCardDoesNotExist = 'EJN_CARD_DOES_NOT_EXIST',
  EjnCardFailedToRegisterInKraken = 'EJN_CARD_FAILED_TO_REGISTER_IN_KRAKEN',
  EjnCardNotAvailableForAssignment = 'EJN_CARD_NOT_AVAILABLE_FOR_ASSIGNMENT',
  EjnCardNotTheOwner = 'EJN_CARD_NOT_THE_OWNER',
  EjnCardOrderAddressTooLong = 'EJN_CARD_ORDER_ADDRESS_TOO_LONG',
  EjnCardOrderLimitExceeded = 'EJN_CARD_ORDER_LIMIT_EXCEEDED',
  EjnConnectorDoesNotExist = 'EJN_CONNECTOR_DOES_NOT_EXIST',
  EjnEvseDoesNotExist = 'EJN_EVSE_DOES_NOT_EXIST',
  EmailAlreadyRegistered = 'EMAIL_ALREADY_REGISTERED',
  EmailAlreadyTaken = 'EMAIL_ALREADY_TAKEN',
  EmailTemplateNotFound = 'EMAIL_TEMPLATE_NOT_FOUND',
  ExternalPromotionError = 'EXTERNAL_PROMOTION_ERROR',
  FallbackErrorCode = 'FALLBACK_ERROR_CODE',
  FeatureNotFound = 'FEATURE_NOT_FOUND',
  FileTooLarge = 'FILE_TOO_LARGE',
  FleetsBusinessEntityAlreadyExists = 'FLEETS_BUSINESS_ENTITY_ALREADY_EXISTS',
  FleetsCannotCreateBusinessEntity = 'FLEETS_CANNOT_CREATE_BUSINESS_ENTITY',
  FleetsCouldNotStartCheckout = 'FLEETS_COULD_NOT_START_CHECKOUT',
  FleetsNotAuthorized = 'FLEETS_NOT_AUTHORIZED',
  FleetsNoBillingAccount = 'FLEETS_NO_BILLING_ACCOUNT',
  FleetsNoBillingInfoToEdit = 'FLEETS_NO_BILLING_INFO_TO_EDIT',
  FleetsNoValidPaymentMethod = 'FLEETS_NO_VALID_PAYMENT_METHOD',
  FleetAssociationAlreadyExists = 'FLEET_ASSOCIATION_ALREADY_EXISTS',
  GroupAlreadyExists = 'GROUP_ALREADY_EXISTS',
  GroupDoesNotExists = 'GROUP_DOES_NOT_EXISTS',
  HomeChargerDoesNotExist = 'HOME_CHARGER_DOES_NOT_EXIST',
  HybridVehiclesUnsupported = 'HYBRID_VEHICLES_UNSUPPORTED',
  InactiveUser = 'INACTIVE_USER',
  InvalidAdminEmail = 'INVALID_ADMIN_EMAIL',
  InvalidBillingEmail = 'INVALID_BILLING_EMAIL',
  InvalidChargingLocation = 'INVALID_CHARGING_LOCATION',
  InvalidDemoToken = 'INVALID_DEMO_TOKEN',
  InvalidDriverData = 'INVALID_DRIVER_DATA',
  InvalidDriverEmail = 'INVALID_DRIVER_EMAIL',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidEncoding = 'INVALID_ENCODING',
  InvalidIssue = 'INVALID_ISSUE',
  InvalidJwtToken = 'INVALID_JWT_TOKEN',
  InvalidLocationSearch = 'INVALID_LOCATION_SEARCH',
  InvalidMarketingToken = 'INVALID_MARKETING_TOKEN',
  InvalidNameData = 'INVALID_NAME_DATA',
  InvalidRefreshToken = 'INVALID_REFRESH_TOKEN',
  InAppChargingDistanceExceeded = 'IN_APP_CHARGING_DISTANCE_EXCEEDED',
  InAppChargingNotSupported = 'IN_APP_CHARGING_NOT_SUPPORTED',
  InAppChargingOverSessionLimit = 'IN_APP_CHARGING_OVER_SESSION_LIMIT',
  InAppChargingSessionDoesNotExist = 'IN_APP_CHARGING_SESSION_DOES_NOT_EXIST',
  InAppChargingSessionIsActive = 'IN_APP_CHARGING_SESSION_IS_ACTIVE',
  InAppChargingSessionIsNotActive = 'IN_APP_CHARGING_SESSION_IS_NOT_ACTIVE',
  InAppChargingTokenDoesNotExist = 'IN_APP_CHARGING_TOKEN_DOES_NOT_EXIST',
  JwtTokenExpired = 'JWT_TOKEN_EXPIRED',
  KrakenAccountError = 'KRAKEN_ACCOUNT_ERROR',
  KrakenOauthCodeInvalid = 'KRAKEN_OAUTH_CODE_INVALID',
  KrakenPaymentMethodNotActivated = 'KRAKEN_PAYMENT_METHOD_NOT_ACTIVATED',
  LocationNotFound = 'LOCATION_NOT_FOUND',
  MalformedCsvHeaders = 'MALFORMED_CSV_HEADERS',
  MissingHeader = 'MISSING_HEADER',
  MultipleClosestLocations = 'MULTIPLE_CLOSEST_LOCATIONS',
  OctoUserDoesNotExist = 'OCTO_USER_DOES_NOT_EXIST',
  PasswordAlreadySet = 'PASSWORD_ALREADY_SET',
  PasswordInvalid = 'PASSWORD_INVALID',
  PasswordNotYetSet = 'PASSWORD_NOT_YET_SET',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentMethodBadRequest = 'PAYMENT_METHOD_BAD_REQUEST',
  PaymentMethodCancelled = 'PAYMENT_METHOD_CANCELLED',
  PaymentMethodCancelFailure = 'PAYMENT_METHOD_CANCEL_FAILURE',
  PaymentMethodCancelNoAlternative = 'PAYMENT_METHOD_CANCEL_NO_ALTERNATIVE',
  PaymentMethodDoesNotExist = 'PAYMENT_METHOD_DOES_NOT_EXIST',
  PaymentMethodSetupFailure = 'PAYMENT_METHOD_SETUP_FAILURE',
  PaymentMethodSetPreferredError = 'PAYMENT_METHOD_SET_PREFERRED_ERROR',
  PaymentMethodStoreFailure = 'PAYMENT_METHOD_STORE_FAILURE',
  PaymentMethodUpdateFailure = 'PAYMENT_METHOD_UPDATE_FAILURE',
  PaymentNotFound = 'PAYMENT_NOT_FOUND',
  PncContractDataCreationError = 'PNC_CONTRACT_DATA_CREATION_ERROR',
  PncContractInvalidPaymentMethod = 'PNC_CONTRACT_INVALID_PAYMENT_METHOD',
  PncContractNotFound = 'PNC_CONTRACT_NOT_FOUND',
  PncProviderError = 'PNC_PROVIDER_ERROR',
  PncRegionNotSupported = 'PNC_REGION_NOT_SUPPORTED',
  PncVinLookupError = 'PNC_VIN_LOOKUP_ERROR',
  PncVinLookupErrorNewReg = 'PNC_VIN_LOOKUP_ERROR_NEW_REG',
  RateLimited = 'RATE_LIMITED',
  ReceiptNotFound = 'RECEIPT_NOT_FOUND',
  RefreshTokenExpired = 'REFRESH_TOKEN_EXPIRED',
  RegLookupFailed = 'REG_LOOKUP_FAILED',
  ReminderAlreadySent = 'REMINDER_ALREADY_SENT',
  RemoteTokenExpired = 'REMOTE_TOKEN_EXPIRED',
  RemoteTokenInvalid = 'REMOTE_TOKEN_INVALID',
  ResetPasswordTokenDoesNotExist = 'RESET_PASSWORD_TOKEN_DOES_NOT_EXIST',
  RoutePlanningError = 'ROUTE_PLANNING_ERROR',
  RoutePlanningInvalidId = 'ROUTE_PLANNING_INVALID_ID',
  RoutePlanningVehicleNotSupported = 'ROUTE_PLANNING_VEHICLE_NOT_SUPPORTED',
  SelfDeactivationError = 'SELF_DEACTIVATION_ERROR',
  ShortLivedTokenAlreadyClaimed = 'SHORT_LIVED_TOKEN_ALREADY_CLAIMED',
  ShortLivedTokenExpired = 'SHORT_LIVED_TOKEN_EXPIRED',
  ShortLivedTokenInvalid = 'SHORT_LIVED_TOKEN_INVALID',
  SignupIntentAlreadyExists = 'SIGNUP_INTENT_ALREADY_EXISTS',
  SignupIntentExistingFleet = 'SIGNUP_INTENT_EXISTING_FLEET',
  SignupTokenDoesNotExist = 'SIGNUP_TOKEN_DOES_NOT_EXIST',
  SignupTokenNotActive = 'SIGNUP_TOKEN_NOT_ACTIVE',
  SubscriptionCancelFailure = 'SUBSCRIPTION_CANCEL_FAILURE',
  SubscriptionEnrolFailure = 'SUBSCRIPTION_ENROL_FAILURE',
  SubscriptionMembershipAlreadyExists = 'SUBSCRIPTION_MEMBERSHIP_ALREADY_EXISTS',
  SubscriptionMembershipDoesNotExist = 'SUBSCRIPTION_MEMBERSHIP_DOES_NOT_EXIST',
  SubscriptionOfferDoesNotExist = 'SUBSCRIPTION_OFFER_DOES_NOT_EXIST',
  SubscriptionOfferIneligible = 'SUBSCRIPTION_OFFER_INELIGIBLE',
  TagAlreadyExists = 'TAG_ALREADY_EXISTS',
  TagDoesNotExist = 'TAG_DOES_NOT_EXIST',
  ThirdPartyAuthIncorrectFlow = 'THIRD_PARTY_AUTH_INCORRECT_FLOW',
  ThirdPartySignupNoCountry = 'THIRD_PARTY_SIGNUP_NO_COUNTRY',
  TooManyCsvRows = 'TOO_MANY_CSV_ROWS',
  Unauthorized = 'UNAUTHORIZED',
  UnsupportedMediaType = 'UNSUPPORTED_MEDIA_TYPE',
  UserDisabled = 'USER_DISABLED',
  UserVehicleCannotDeletePrimary = 'USER_VEHICLE_CANNOT_DELETE_PRIMARY',
  UserVehicleDoesNotExist = 'USER_VEHICLE_DOES_NOT_EXIST',
  ValidationError = 'VALIDATION_ERROR',
  VatRegistrationApiUnavailable = 'VAT_REGISTRATION_API_UNAVAILABLE',
  VatRegistrationBadResponse = 'VAT_REGISTRATION_BAD_RESPONSE',
  VatRegistrationNotFound = 'VAT_REGISTRATION_NOT_FOUND',
  VehicleAlreadyExists = 'VEHICLE_ALREADY_EXISTS',
  VehicleDoesNotExist = 'VEHICLE_DOES_NOT_EXIST',
  VehicleRegistrationNotFound = 'VEHICLE_REGISTRATION_NOT_FOUND',
  VirtualCardAlreadyExists = 'VIRTUAL_CARD_ALREADY_EXISTS',
  VirtualCardDoesNotExist = 'VIRTUAL_CARD_DOES_NOT_EXIST'
}

export type EstimatedCostType = {
  __typename?: 'EstimatedCostType';
  amount?: Maybe<Scalars['Decimal']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Decimal']['output']>;
  type?: Maybe<SubscriptionCostType>;
};

export type ExtendedVehicleLookupResultType = {
  __typename?: 'ExtendedVehicleLookupResultType';
  confidence?: Maybe<Scalars['String']['output']>;
  isElectric?: Maybe<Scalars['Boolean']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<VehicleType>>>;
  useHeuristics?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum Facility {
  Covered = 'COVERED',
  Entertainment = 'ENTERTAINMENT',
  FoodDrink = 'FOOD_DRINK',
  Lighting = 'LIGHTING',
  Lodging = 'LODGING',
  Nature = 'NATURE',
  Parking = 'PARKING',
  PhoneSignal = 'PHONE_SIGNAL',
  Retail = 'RETAIL',
  Toilets = 'TOILETS',
  Transport = 'TRANSPORT'
}

export type FinancialInfo = {
  __typename?: 'FinancialInfo';
  debtStatus?: Maybe<DebtWarningStatus>;
};

export type FleetChargeRecordType = Node & {
  __typename?: 'FleetChargeRecordType';
  downloadLink?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  numberOfReceipts?: Maybe<Scalars['Int']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  totalConsumption?: Maybe<Scalars['Decimal']['output']>;
  totalCost?: Maybe<Scalars['Decimal']['output']>;
};

export type FleetChargeRecordTypeConnection = {
  __typename?: 'FleetChargeRecordTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FleetChargeRecordTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FleetChargeRecordType` and its cursor. */
export type FleetChargeRecordTypeEdge = {
  __typename?: 'FleetChargeRecordTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FleetChargeRecordType>;
};

export type FleetChargeRecordTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: FleetChargeRecordTypeOrderingFields;
};

/** An enumeration. */
export enum FleetChargeRecordTypeOrderingFields {
  EndDate = 'END_DATE',
  NumberOfReceipts = 'NUMBER_OF_RECEIPTS',
  Pk = 'PK',
  StartDate = 'START_DATE',
  TotalConsumption = 'TOTAL_CONSUMPTION',
  TotalCost = 'TOTAL_COST'
}

export type FleetCreditType = {
  __typename?: 'FleetCreditType';
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
};

export type FleetInvoiceType = Node & {
  __typename?: 'FleetInvoiceType';
  chargeBreakdownDownloadLink?: Maybe<Scalars['String']['output']>;
  chargesTotal?: Maybe<Scalars['Decimal']['output']>;
  createdAt: Scalars['DateTime']['output'];
  downloadLink?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<InvoiceStatus>;
  subscriptionTotal?: Maybe<Scalars['Decimal']['output']>;
  totalCredit?: Maybe<Scalars['Decimal']['output']>;
  totalGross?: Maybe<Scalars['Decimal']['output']>;
  totalNet?: Maybe<Scalars['Decimal']['output']>;
  totalVat?: Maybe<Scalars['Decimal']['output']>;
};

export type FleetInvoiceTypeConnection = {
  __typename?: 'FleetInvoiceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FleetInvoiceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FleetInvoiceType` and its cursor. */
export type FleetInvoiceTypeEdge = {
  __typename?: 'FleetInvoiceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FleetInvoiceType>;
};

export type FleetInvoiceTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: FleetInvoiceTypeOrderingFields;
};

/** An enumeration. */
export enum FleetInvoiceTypeOrderingFields {
  CreatedAt = 'CREATED_AT',
  Pk = 'PK'
}

export type FleetReceiptLocationType = {
  __typename?: 'FleetReceiptLocationType';
  address?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Scalars['PointJSON']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
};

/** Enum indicating the type of company that is signing up */
export enum FleetSignUpCompanyType {
  Charity = 'CHARITY',
  Limited = 'LIMITED',
  Partnership = 'PARTNERSHIP',
  SoleTrader = 'SOLE_TRADER'
}

export type FleetSubscriptionType = Node & {
  __typename?: 'FleetSubscriptionType';
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  endDatetime?: Maybe<Scalars['DateTime']['output']>;
  estimatedCost?: Maybe<Array<Maybe<EstimatedCostType>>>;
  freeCount: Scalars['Int']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  inTrialPeriod?: Maybe<Scalars['Boolean']['output']>;
  isActive: Scalars['Boolean']['output'];
  /** Date the next subsciption payment is scheduled for. */
  nextPaymentDate: Scalars['Date']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Decimal']['output'];
  startDatetime: Scalars['DateTime']['output'];
  trialEndDate?: Maybe<Scalars['Date']['output']>;
  trialPrice?: Maybe<Scalars['Decimal']['output']>;
  unitType: UnitTypeOptions;
};

export type FleetSubscriptionTypeConnection = {
  __typename?: 'FleetSubscriptionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FleetSubscriptionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FleetSubscriptionType` and its cursor. */
export type FleetSubscriptionTypeEdge = {
  __typename?: 'FleetSubscriptionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FleetSubscriptionType>;
};

export type FleetSubscriptionTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: FleetSubscriptionTypeOrderingFields;
};

/** An enumeration. */
export enum FleetSubscriptionTypeOrderingFields {
  CreatedAt = 'CREATED_AT',
  Pk = 'PK',
  StartDatetime = 'START_DATETIME'
}

export type FleetSummaryStatsType = {
  __typename?: 'FleetSummaryStatsType';
  averageSessionLength?: Maybe<Scalars['Decimal']['output']>;
  chargerSpeedSplit?: Maybe<Array<Maybe<ChargerUsageSplitType>>>;
  combinedCpoSplit?: Maybe<Array<Maybe<CpoUsageSplitType>>>;
  cpoSplitPerDriver?: Maybe<Array<Maybe<DriverCpoUsageSplitType>>>;
  totalCo2Saved?: Maybe<Scalars['Decimal']['output']>;
  totalCost?: Maybe<Scalars['Decimal']['output']>;
};

export type FleetVehicleType = Node & {
  __typename?: 'FleetVehicleType';
  drivers: DriverTypeConnection;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  regNumber: Scalars['String']['output'];
  vehicle: VehicleType;
};


export type FleetVehicleTypeDriversArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type FleetVehicleTypeConnection = {
  __typename?: 'FleetVehicleTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FleetVehicleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `FleetVehicleType` and its cursor. */
export type FleetVehicleTypeEdge = {
  __typename?: 'FleetVehicleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FleetVehicleType>;
};

export type FleetVehicleTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: FleetVehicleTypeOrderingFields;
};

/** An enumeration. */
export enum FleetVehicleTypeOrderingFields {
  Pk = 'PK'
}

export type FleetsBillingAccountType = {
  __typename?: 'FleetsBillingAccountType';
  billingDetails?: Maybe<BillingDetailsType>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethodType>;
  paymentMethodTemp?: Maybe<PaymentMethodType>;
};

export type FleetsBulkCardOrder = {
  __typename?: 'FleetsBulkCardOrder';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type FleetsCardOrderType = Node & {
  __typename?: 'FleetsCardOrderType';
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  orderedBy?: Maybe<AdminType>;
  pk?: Maybe<Scalars['Int']['output']>;
  status: EjnEjnCardOrderStatusChoices;
};

export type FleetsCardOrderTypeConnection = {
  __typename?: 'FleetsCardOrderTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FleetsCardOrderTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `FleetsCardOrderType` and its cursor. */
export type FleetsCardOrderTypeEdge = {
  __typename?: 'FleetsCardOrderTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FleetsCardOrderType>;
};

export type FleetsCardOrderTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: FleetsCardOrderTypeOrderingFields;
};

/** An enumeration. */
export enum FleetsCardOrderTypeOrderingFields {
  Pk = 'PK',
  Status = 'STATUS'
}

export type FleetsCurrentBalanceType = {
  __typename?: 'FleetsCurrentBalanceType';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
};

export type FleetsDriversCardType = Node & {
  __typename?: 'FleetsDriversCardType';
  createdAt: Scalars['DateTime']['output'];
  design: CardDesignOptions;
  driver?: Maybe<DriverType>;
  hexCode: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FleetsDriversCardTypeConnection = {
  __typename?: 'FleetsDriversCardTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FleetsDriversCardTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `FleetsDriversCardType` and its cursor. */
export type FleetsDriversCardTypeEdge = {
  __typename?: 'FleetsDriversCardTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FleetsDriversCardType>;
};

export type FleetsDriversCardTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: FleetsDriversCardTypeOrderingFields;
};

/** An enumeration. */
export enum FleetsDriversCardTypeOrderingFields {
  Pk = 'PK',
  Status = 'STATUS'
}

export type FleetsEditStripeDirectDebitBillingDetails = {
  __typename?: 'FleetsEditStripeDirectDebitBillingDetails';
  success: Scalars['Boolean']['output'];
};

export type FleetsGetPaymentInstructionSecret = {
  __typename?: 'FleetsGetPaymentInstructionSecret';
  secretKey: Scalars['String']['output'];
};

/**
 * Custom class for allowing log in only for users who have
 * association with a Business Entity
 */
export type FleetsObtainJsonWebToken = {
  __typename?: 'FleetsObtainJSONWebToken';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type FleetsReferralSummaryType = {
  __typename?: 'FleetsReferralSummaryType';
  currency: CurrencyType;
  totalCompletedReferrals: Scalars['Int']['output'];
  totalReferrals: Scalars['Int']['output'];
  totalRewarded: Scalars['Decimal']['output'];
};

export type FleetsStartDirectDebitCheckoutSession = {
  __typename?: 'FleetsStartDirectDebitCheckoutSession';
  checkoutUrl: Scalars['String']['output'];
};

export type FleetsStorePaymentInstruction = {
  __typename?: 'FleetsStorePaymentInstruction';
  success: Scalars['Boolean']['output'];
};

export type FleetsStorePaymentInstructionFromCheckout = {
  __typename?: 'FleetsStorePaymentInstructionFromCheckout';
  success: Scalars['Boolean']['output'];
};

export type FleetsTagType = Node & {
  __typename?: 'FleetsTagType';
  colour: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
};

export type FleetsTagTypeConnection = {
  __typename?: 'FleetsTagTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FleetsTagTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `FleetsTagType` and its cursor. */
export type FleetsTagTypeEdge = {
  __typename?: 'FleetsTagTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FleetsTagType>;
};

export type FleetsTagTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: FleetsTagTypeOrderingFields;
};

/** An enumeration. */
export enum FleetsTagTypeOrderingFields {
  Name = 'NAME',
  Pk = 'PK'
}

export type ForceStopChargingSession = {
  __typename?: 'ForceStopChargingSession';
  success: Scalars['Boolean']['output'];
};

export type GenerateKrakenOAuthUri = {
  __typename?: 'GenerateKrakenOAuthURI';
  codeChallenge: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

/** Generates a short lived auth token for quick authentication. */
export type GenerateShortLivedAuthToken = {
  __typename?: 'GenerateShortLivedAuthToken';
  expires: Scalars['DateTime']['output'];
  shortLivedToken: Scalars['String']['output'];
};

export type GenericConnectorConnectionTypeConnection = {
  __typename?: 'GenericConnectorConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericConnectorConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `GenericConnectorConnectionType` and its cursor. */
export type GenericConnectorConnectionTypeEdge = {
  __typename?: 'GenericConnectorConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericConnectorInterface>;
};

export type GenericConnectorInterface = {
  isChargingFree?: Maybe<Scalars['Boolean']['output']>;
  kilowatts?: Maybe<Scalars['Int']['output']>;
  pk: Scalars['Int']['output'];
  priceComponents?: Maybe<Array<Maybe<PriceComponent>>>;
  pricing?: Maybe<PricingInterface>;
  speed?: Maybe<Speeds>;
  standard?: Maybe<GenericConnectorStandardInterface>;
};

export type GenericConnectorStandardInterface = {
  humanName?: Maybe<Scalars['String']['output']>;
  imageDark?: Maybe<Scalars['String']['output']>;
  imageLight?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
};

export type GenericEvseConnectionTypeConnection = {
  __typename?: 'GenericEVSEConnectionTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericEvseConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `GenericEVSEConnectionType` and its cursor. */
export type GenericEvseConnectionTypeEdge = {
  __typename?: 'GenericEVSEConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericEvseInterface>;
};

export type GenericEvseInterface = {
  connectors?: Maybe<GenericConnectorConnectionTypeConnection>;
  lastSessionStartedAt?: Maybe<Scalars['DateTime']['output']>;
  physicalReference?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  status?: Maybe<Status>;
  supportsInAppCharging: Scalars['Boolean']['output'];
};


export type GenericEvseInterfaceConnectorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  socketGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  speeds?: InputMaybe<Array<InputMaybe<Speeds>>>;
};

export type GenericLocationInterface = {
  address?: Maybe<Scalars['String']['output']>;
  alerts?: Maybe<Array<Maybe<LocationAlertType>>>;
  capabilities?: Maybe<Array<Maybe<Capability>>>;
  chargingLocationPk: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  coordinates: Scalars['PointJSON']['output'];
  country?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  evses?: Maybe<GenericEvseConnectionTypeConnection>;
  facilities: Array<Maybe<Facility>>;
  gridState?: Maybe<GridState>;
  isEjnLocation: Scalars['Boolean']['output'];
  isGreenEnergy?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<GenericOpeningTimes>;
  operator?: Maybe<GenericOperatorInterface>;
  pk: Scalars['Int']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};


export type GenericLocationInterfaceEvsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  connectorSocketGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  connectorSpeeds?: InputMaybe<Array<InputMaybe<Speeds>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Status>>>;
};

export type GenericOpeningTimes = {
  exceptionalClosings?: Maybe<Array<Maybe<GenericPeriod>>>;
  exceptionalOpenings?: Maybe<Array<Maybe<GenericPeriod>>>;
  regularHours?: Maybe<Array<Maybe<GenericRegularHours>>>;
  twentyFourSeven?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * A note on has_partnered_locations:
 * We can't guarantee the source operator or location is actually partnered,
 * this is just to say we have some EJN operators for this display operator.
 * The intended use-case for this flag is to determine whether to display the
 * partnered network banner and does not provide any guarantees the
 * underlying source data is consistent, including capabilities and tariffs.
 * This is a workaround until the location matching logic is improved, or
 * we are able to supply Iternio with more up-to-date data - via eco-movement
 * or preferably a direct connection so the returned location ID is
 * guaranteed to be for a partnered location.
 */
export type GenericOperatorInterface = {
  hasPartneredLocations?: Maybe<Scalars['Boolean']['output']>;
  iacConfigOptions?: Maybe<BaseIacOptions>;
  isGreenEnergy?: Maybe<Scalars['Boolean']['output']>;
  logoDark?: Maybe<Scalars['String']['output']>;
  logoLight?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportPhoneNumber?: Maybe<Scalars['String']['output']>;
  supportWebsite?: Maybe<Scalars['String']['output']>;
};

export type GenericPaymentMethodDetailsType = PaymentMethodDetailsType & {
  __typename?: 'GenericPaymentMethodDetailsType';
  identifier?: Maybe<Scalars['String']['output']>;
  stripePaymentMethodType: Scalars['String']['output'];
};

export type GenericPeriod = {
  periodBegin?: Maybe<Scalars['String']['output']>;
  periodEnd?: Maybe<Scalars['String']['output']>;
};

export type GenericRegularHours = {
  periodBegin?: Maybe<Scalars['String']['output']>;
  periodEnd?: Maybe<Scalars['String']['output']>;
  weekday?: Maybe<Weekdays>;
};

/**
 * Input for GeoPoints specified by lon/lat.
 *
 * NOTE: For some reason graphene needs a different type for Input
 * so this duplicates the PointDict.
 * This should be used in favour of the PointJSON which doesn't specify the
 * format of the input.
 */
export type GeoPointInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type GetEmbeddedSecretForNewPaymentInstruction = {
  __typename?: 'GetEmbeddedSecretForNewPaymentInstruction';
  secretKey?: Maybe<Scalars['String']['output']>;
  setupIntentId?: Maybe<Scalars['String']['output']>;
};

export type GetSecretForStripePaymentRetry = {
  __typename?: 'GetSecretForStripePaymentRetry';
  paymentSecret?: Maybe<SecretDataType>;
};

/**
 * This is a mutation to get the public stripe key depending on the state
 * of the user's financial migration.
 */
export type GetStripePublicKey = {
  __typename?: 'GetStripePublicKey';
  key: Scalars['String']['output'];
};

export enum GridIntensity {
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  VeryHigh = 'VERY_HIGH',
  VeryLow = 'VERY_LOW'
}

export type GridState = {
  __typename?: 'GridState';
  carbonPerKwh: Scalars['Int']['output'];
  intensity: GridIntensity;
};

/** An enumeration. */
export enum InvoiceStatus {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type KrakenOAuthCallback = {
  __typename?: 'KrakenOAuthCallback';
  refreshToken: Scalars['String']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type LocationAlertType = Node & {
  __typename?: 'LocationAlertType';
  content: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  type: LocationsLocationAlertTypeChoices;
  typeDetail?: Maybe<LocationsLocationAlertTypeDetailChoices>;
};

/** An enumeration. */
export enum LocationsLocationAlertTypeChoices {
  /** ALERT */
  Alert = 'ALERT',
  /** DISCOUNT */
  Discount = 'DISCOUNT',
  /** REPORT */
  Report = 'REPORT'
}

/** An enumeration. */
export enum LocationsLocationAlertTypeDetailChoices {
  /** ALERT_TECHNICAL_ISSUE */
  AlertTechnicalIssue = 'ALERT_TECHNICAL_ISSUE',
  /** DISCOUNT_ELECTROVERSE */
  DiscountElectroverse = 'DISCOUNT_ELECTROVERSE',
  /** DISCOUNT_OCTOPUS */
  DiscountOctopus = 'DISCOUNT_OCTOPUS',
  /** DISCOUNT_OTHER */
  DiscountOther = 'DISCOUNT_OTHER',
  /** DISCOUNT_PARTNER */
  DiscountPartner = 'DISCOUNT_PARTNER',
  /** INFORMATION_DISCOUNTS */
  InformationDiscounts = 'INFORMATION_DISCOUNTS',
  /** INFORMATION_PARKING */
  InformationParking = 'INFORMATION_PARKING',
  /** INFORMATION_VARIABLE_PRICING */
  InformationVariablePricing = 'INFORMATION_VARIABLE_PRICING',
  /** USER_RESTRICTION */
  UserRestriction = 'USER_RESTRICTION',
  /** USER_RESTRICTION_PLANE */
  UserRestrictionPlane = 'USER_RESTRICTION_PLANE',
  /** USER_RESTRICTION_TAXI */
  UserRestrictionTaxi = 'USER_RESTRICTION_TAXI',
  /** USER_RESTRICTION_VEHICLE_BRAND */
  UserRestrictionVehicleBrand = 'USER_RESTRICTION_VEHICLE_BRAND'
}

/**
 * Mutation which log the user in if they choose to not use the magic link
 * auth process.
 */
export type LogIn = {
  __typename?: 'LogIn';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/**
 * Dev only mock endpoint to enable simultaneous mobile build.
 * This mutation will need to:
 *
 *     - Raise if payment method has 'valid_to' date set
 *     - Call domain function to set as preferred
 */
export type MakePaymentMethodPreferred = {
  __typename?: 'MakePaymentMethodPreferred';
  success: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum MapStyleOptions {
  Blueprint = 'BLUEPRINT',
  Default = 'DEFAULT',
  MonochromeDark = 'MONOCHROME_DARK',
  Retro = 'RETRO',
  Satellite = 'SATELLITE',
  Vintage = 'VINTAGE'
}

export type MerchantType = {
  __typename?: 'MerchantType';
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  mcc: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelPaymentMethod?: Maybe<CancelPaymentMethod>;
  /**
   * Mutation which lets the user change their password - only works for
   * user chosen passwords, not those set by magic link.
   */
  changePassword?: Maybe<ChangePassword>;
  dismissChargingSession?: Maybe<DismissChargingSession>;
  fleetsAddDriverToGroups?: Maybe<AddDriverToGroups>;
  fleetsAddDriverVehicle?: Maybe<AddDriverVehicle>;
  fleetsAddFleetToCampaign?: Maybe<AddFleetToCampaign>;
  /** Mutation for adding tags. */
  fleetsAddTag?: Maybe<AddTag>;
  fleetsAddVehicle?: Maybe<AddVehicle>;
  /**
   * Custom class for allowing log in only for users who have
   * association with a Business Entity
   */
  fleetsAdminLogIn?: Maybe<FleetsObtainJsonWebToken>;
  /** Mutation for fleets admins to request a reset password email */
  fleetsAdminSendResetPasswordEmail?: Maybe<AdminSendResetPasswordEmail>;
  /**
   * Mutation for sending admin's sign up email.
   * It allows admins to resend the email
   * if the admin hasn't been activated yet.
   */
  fleetsAdminSendSignupEmail?: Maybe<AdminSendSignupEmail>;
  /**
   * Mutation for admin's first time sign up.
   * It allows admins to set their own passwords.
   */
  fleetsAdminSetPassword?: Maybe<AdminSetPassword>;
  /** Mutation for assigning a card to a driver. */
  fleetsAssignCardToDriver?: Maybe<DriverAssignCard>;
  fleetsAssignVehicle?: Maybe<AssignVehicle>;
  fleetsBulkCardOrder?: Maybe<FleetsBulkCardOrder>;
  fleetsBulkCreateDrivers?: Maybe<BulkCreateDrivers>;
  fleetsCompleteFleetSignup?: Maybe<CompleteFleetSignUp>;
  /** Creates a new admin for an existing fleet. */
  fleetsCreateAdmin?: Maybe<CreateAdmin>;
  fleetsCreateBusinessEntity?: Maybe<CreateBusinessEntity>;
  /** Creates a fleet driver and all the associated models. */
  fleetsCreateDriver?: Maybe<CreateDriver>;
  fleetsCreateDriverGroup?: Maybe<CreateDriverGroup>;
  /** Mutation for deleting tags. */
  fleetsDeleteTag?: Maybe<DeleteTag>;
  /**
   * Mutation for driver's first time sign up.
   * It allows drivers to set their own passwords.
   */
  fleetsDriverSetPassword?: Maybe<DriverSetPassword>;
  fleetsEditAdminActiveStatus?: Maybe<EditAdminActiveStatus>;
  fleetsEditBusinessEntity?: Maybe<EditBusinessEntity>;
  fleetsEditBusinessEntityName?: Maybe<EditBusinessEntityDisplayName>;
  fleetsEditDriver?: Maybe<EditDriver>;
  fleetsEditStripeDirectDebitBillingDetails?: Maybe<FleetsEditStripeDirectDebitBillingDetails>;
  /** Mutation for editing tags. */
  fleetsEditTag?: Maybe<EditTag>;
  /** Mutation for cancelling driver's card. */
  fleetsFreezeDriversCard?: Maybe<DriverFreezeCard>;
  fleetsGetPaymentInstructionSecret?: Maybe<FleetsGetPaymentInstructionSecret>;
  /**
   * Mutation for reassigning a card from one driver to another.
   * The target driver is specified in the mutation argument.
   */
  fleetsReassignCardToDriver?: Maybe<DriverReassignCard>;
  fleetsRedeemCreditCode?: Maybe<RedeemCreditCode>;
  fleetsRemoveDriverFromGroup?: Maybe<RemoveDriverFromGroup>;
  fleetsRemoveDriverVehicle?: Maybe<RemoveDriverVehicle>;
  fleetsRemoveVehicle?: Maybe<RemoveVehicle>;
  fleetsRetrieveCharityInfo?: Maybe<RetrieveCharityInfo>;
  fleetsRetrieveCompanyHouseInfo?: Maybe<RetrieveCompanyHouseInfo>;
  fleetsRetrieveFleetSignupIntentToken?: Maybe<RetrieveFleetSignUpIntentToken>;
  fleetsRetrieveVatRegistration?: Maybe<RetrieveVatRegistration>;
  /**
   * Mutation for resending verification email.
   * Allows creating admin to resend email to themselves
   * if they haven't yet verified.
   */
  fleetsSendBusinessEntityVerificationEmail?: Maybe<BusinessEntitySendVerificationEmail>;
  fleetsSendFleetSignupEmail?: Maybe<SendFleetSignUpEmail>;
  /** Mutation for a fleet admin to send a driver password reset email. */
  fleetsSendResetPasswordEmail?: Maybe<DriverSendPasswordResetEmail>;
  /**
   * Mutation for sending driver's sign up email.
   * It allows admins to resend the email
   * if the driver hasn't been activated yet.
   */
  fleetsSendSignupEmail?: Maybe<DriverSendSignupEmail>;
  fleetsStartDirectDebitCheckoutSession?: Maybe<FleetsStartDirectDebitCheckoutSession>;
  fleetsStorePaymentInstruction?: Maybe<FleetsStorePaymentInstruction>;
  fleetsStorePaymentInstructionFromCheckout?: Maybe<FleetsStorePaymentInstructionFromCheckout>;
  /** Mutation for tagging drivers. */
  fleetsTagDrivers?: Maybe<TagDrivers>;
  /** Mutation for removing tags from drivers. */
  fleetsUntagDrivers?: Maybe<UntagDrivers>;
  forceStopSession?: Maybe<ForceStopChargingSession>;
  generateKrakenOauthUri?: Maybe<GenerateKrakenOAuthUri>;
  /** Generates a short lived auth token for quick authentication. */
  generateShortLivedAuthToken?: Maybe<GenerateShortLivedAuthToken>;
  getEmbeddedSecretForNewPaymentInstruction?: Maybe<GetEmbeddedSecretForNewPaymentInstruction>;
  getSecretForStripePaymentRetry?: Maybe<GetSecretForStripePaymentRetry>;
  /**
   * This is a mutation to get the public stripe key depending on the state
   * of the user's financial migration.
   */
  getStripePublicKey?: Maybe<GetStripePublicKey>;
  krakenOauthCallback?: Maybe<KrakenOAuthCallback>;
  /**
   * Mutation which log the user in if they choose to not use the magic link
   * auth process.
   */
  logIn?: Maybe<LogIn>;
  /**
   * Dev only mock endpoint to enable simultaneous mobile build.
   * This mutation will need to:
   *
   *     - Raise if payment method has 'valid_to' date set
   *     - Call domain function to set as preferred
   */
  makePaymentMethodPreferred?: Maybe<MakePaymentMethodPreferred>;
  /**
   * N.B. We may not actually need this from a payment perspective as mobile
   * will know on completion of the payment Sheet whether payment has
   * succeeded or failed, and we will log all appropriate events
   * / actions through existing webhooks.
   *
   * However, payment sheet may allow users to add a new payment
   * method before retry, in which case we'll need to store and process
   * that payment method here.
   */
  processStripePaymentRetry?: Maybe<ProcessStripePaymentRetry>;
  /**
   * Authenticates the user using the short lived auth token.
   * It's best used when switching platform and keeping users
   * authentication details there.
   */
  quickAuth?: Maybe<QuickAuthenticate>;
  refreshToken?: Maybe<Refresh>;
  /**
   * A callback for the remote token authentication flow.
   * This triggers the event for the 2nd device to authenticate the user
   * who's called this mutation from another device.
   */
  remoteAuthCallback?: Maybe<RemoteAuthCallback>;
  /** Resets the password for the user and authenticates them. */
  resetPassword?: Maybe<ResetPassword>;
  retryPayment?: Maybe<RetryPayment>;
  revokeToken?: Maybe<Revoke>;
  /** Mutation which starts the magic link authentication process. */
  sendMagicLink?: Maybe<SendMagicLink>;
  sendReceiptEmail?: Maybe<SendReceiptEmail>;
  /**
   * Mutation which lets the user set their own password in the sign up process.
   * Initially all users signed up via the magic link flow will have unusable
   * password so this will allow them to set a new password. They're able to use
   * this only once!
   */
  setPassword?: Maybe<SetPassword>;
  /**
   * Mutation which signs up the user in the magic link auth process.
   * Note that because we're not using passwords at this stage we can't just
   * use extend from `graphql_jwt.ObtainJSONWebToken` class which would have
   * been much simpler. Without that we need to authenticate the user "manually"
   */
  signUp?: Maybe<SignUp>;
  startChargingSession?: Maybe<StartChargingSession>;
  stopChargingSession?: Maybe<StopChargingSession>;
  storeStripePaymentMethodFromSetupIntent?: Maybe<StoreStripePaymentMethodFromSetupIntent>;
  /**
   * Authenticates an identity token from a third party provider
   * (eg Apple, Google) and signs the user up or in.
   */
  thirdPartyAuthentication?: Maybe<ThirdPartyAuthentication>;
  unlockConnector?: Maybe<UnlockConnector>;
  verifyToken?: Maybe<Verify>;
};


export type MutationCancelPaymentMethodArgs = {
  paymentMethodId: Scalars['Int']['input'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationDismissChargingSessionArgs = {
  session: Scalars['Int']['input'];
};


export type MutationFleetsAddDriverToGroupsArgs = {
  driver: Scalars['Int']['input'];
  driverGroups: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationFleetsAddDriverVehicleArgs = {
  driver: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  reg: Scalars['String']['input'];
  vehicle: Scalars['Int']['input'];
};


export type MutationFleetsAddFleetToCampaignArgs = {
  code: Scalars['String']['input'];
};


export type MutationFleetsAddTagArgs = {
  colour: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationFleetsAddVehicleArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  reg: Scalars['String']['input'];
  vehicle: Scalars['Int']['input'];
};


export type MutationFleetsAdminLogInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationFleetsAdminSendResetPasswordEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationFleetsAdminSendSignupEmailArgs = {
  pk: Scalars['Int']['input'];
};


export type MutationFleetsAdminSetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  singleTimeToken: Scalars['String']['input'];
};


export type MutationFleetsAssignCardToDriverArgs = {
  driver: Scalars['Int']['input'];
  hexCode: Scalars['String']['input'];
};


export type MutationFleetsAssignVehicleArgs = {
  driver: Scalars['Int']['input'];
  fleetVehicle: Scalars['Int']['input'];
};


export type MutationFleetsBulkCardOrderArgs = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  address4?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationFleetsBulkCreateDriversArgs = {
  file?: InputMaybe<Scalars['FileUpload']['input']>;
};


export type MutationFleetsCompleteFleetSignupArgs = {
  charityNumber?: InputMaybe<Scalars['String']['input']>;
  companyHouseNumber?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  companyType?: InputMaybe<Scalars['String']['input']>;
  driverEstimate?: InputMaybe<Scalars['Int']['input']>;
  firstName: Scalars['String']['input'];
  homeChargingEstimate?: InputMaybe<Scalars['Decimal']['input']>;
  howHeard?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  publicChargingEstimate?: InputMaybe<Scalars['Decimal']['input']>;
  sector?: InputMaybe<Scalars['String']['input']>;
  signupToken: Scalars['String']['input'];
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  workplaceChargingEstimate?: InputMaybe<Scalars['Decimal']['input']>;
};


export type MutationFleetsCreateAdminArgs = {
  email: Scalars['String']['input'];
  hasBillingPermission?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationFleetsCreateBusinessEntityArgs = {
  adminEmail: Scalars['String']['input'];
  adminFamilyName: Scalars['String']['input'];
  adminFirstName: Scalars['String']['input'];
  adminPassword: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
};


export type MutationFleetsCreateDriverArgs = {
  driverGroups?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  drivingLicence?: InputMaybe<Scalars['String']['input']>;
  ejnCardDeliveryDetails?: InputMaybe<EjnCardDeliveryDetailsInput>;
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
  preferredNameOnCard?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationFleetsCreateDriverGroupArgs = {
  name: Scalars['String']['input'];
};


export type MutationFleetsDeleteTagArgs = {
  pk: Scalars['Int']['input'];
};


export type MutationFleetsDriverSetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  singleTimeToken: Scalars['String']['input'];
};


export type MutationFleetsEditAdminActiveStatusArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  pk: Scalars['Int']['input'];
};


export type MutationFleetsEditBusinessEntityArgs = {
  deliveryAddress: EjnCardDeliveryDetailsInput;
};


export type MutationFleetsEditBusinessEntityNameArgs = {
  displayName: Scalars['String']['input'];
};


export type MutationFleetsEditDriverArgs = {
  deliveryAddress?: InputMaybe<EjnCardDeliveryDetailsInput>;
  drivingLicence?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  pk: Scalars['Int']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationFleetsEditStripeDirectDebitBillingDetailsArgs = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};


export type MutationFleetsEditTagArgs = {
  colour?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pk: Scalars['Int']['input'];
};


export type MutationFleetsFreezeDriversCardArgs = {
  card: Scalars['Int']['input'];
  driver: Scalars['Int']['input'];
};


export type MutationFleetsGetPaymentInstructionSecretArgs = {
  instructionType: Scalars['String']['input'];
};


export type MutationFleetsReassignCardToDriverArgs = {
  card: Scalars['Int']['input'];
  newDriver: Scalars['Int']['input'];
  oldDriver: Scalars['Int']['input'];
};


export type MutationFleetsRedeemCreditCodeArgs = {
  code: Scalars['String']['input'];
};


export type MutationFleetsRemoveDriverFromGroupArgs = {
  driver: Scalars['Int']['input'];
  driverGroup: Scalars['Int']['input'];
};


export type MutationFleetsRemoveDriverVehicleArgs = {
  driver: Scalars['Int']['input'];
  fleetVehicle: Scalars['Int']['input'];
};


export type MutationFleetsRemoveVehicleArgs = {
  fleetVehicle: Scalars['Int']['input'];
};


export type MutationFleetsRetrieveCharityInfoArgs = {
  charityNumber: Scalars['String']['input'];
  signupToken: Scalars['String']['input'];
};


export type MutationFleetsRetrieveCompanyHouseInfoArgs = {
  companyHouseNumber: Scalars['String']['input'];
  signupToken: Scalars['String']['input'];
};


export type MutationFleetsRetrieveFleetSignupIntentTokenArgs = {
  signupToken: Scalars['String']['input'];
};


export type MutationFleetsRetrieveVatRegistrationArgs = {
  signupToken: Scalars['String']['input'];
  vatNumber: Scalars['String']['input'];
};


export type MutationFleetsSendBusinessEntityVerificationEmailArgs = {
  pk: Scalars['Int']['input'];
};


export type MutationFleetsSendFleetSignupEmailArgs = {
  campaignCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailType']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationFleetsSendResetPasswordEmailArgs = {
  pk: Scalars['Int']['input'];
};


export type MutationFleetsSendSignupEmailArgs = {
  pk: Scalars['Int']['input'];
};


export type MutationFleetsStorePaymentInstructionArgs = {
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  vendorReference: Scalars['String']['input'];
};


export type MutationFleetsStorePaymentInstructionFromCheckoutArgs = {
  sessionId: Scalars['String']['input'];
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationFleetsTagDriversArgs = {
  drivers?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tags: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationFleetsUntagDriversArgs = {
  drivers?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tags: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationForceStopSessionArgs = {
  session: Scalars['Int']['input'];
  sessionType: ChargingSessionTypeEnum;
};


export type MutationGenerateKrakenOauthUriArgs = {
  countryCode: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetEmbeddedSecretForNewPaymentInstructionArgs = {
  instructionType?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetSecretForStripePaymentRetryArgs = {
  paymentId: Scalars['Int']['input'];
};


export type MutationKrakenOauthCallbackArgs = {
  campaignCode?: InputMaybe<Scalars['String']['input']>;
  challenge: Scalars['String']['input'];
  code: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
  verificationToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLogInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMakePaymentMethodPreferredArgs = {
  paymentMethodId: Scalars['Int']['input'];
};


export type MutationProcessStripePaymentRetryArgs = {
  paymentIntentId: Scalars['String']['input'];
};


export type MutationQuickAuthArgs = {
  shortLivedToken: Scalars['String']['input'];
  user: Scalars['Int']['input'];
};


export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoteAuthCallbackArgs = {
  remoteToken: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRetryPaymentArgs = {
  paymentId: Scalars['Int']['input'];
};


export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendMagicLinkArgs = {
  campaignCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendReceiptEmailArgs = {
  receiptId: Scalars['Int']['input'];
};


export type MutationSetPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationSignUpArgs = {
  campaignCode?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
  verificationToken: Scalars['String']['input'];
};


export type MutationStartChargingSessionArgs = {
  connector?: InputMaybe<Scalars['Int']['input']>;
  coordinates?: InputMaybe<GeoPointInput>;
  evse: Scalars['Int']['input'];
};


export type MutationStopChargingSessionArgs = {
  session: Scalars['Int']['input'];
};


export type MutationStoreStripePaymentMethodFromSetupIntentArgs = {
  setAsPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  setupIntentId: Scalars['String']['input'];
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationThirdPartyAuthenticationArgs = {
  campaignCode?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
  providerName: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnlockConnectorArgs = {
  session: Scalars['Int']['input'];
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

/** TODO: collate this with the EJNOperatorType */
export type OperatorType = Node & {
  __typename?: 'OperatorType';
  capabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  logoDark?: Maybe<Scalars['String']['output']>;
  logoLight?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partyId?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportPhoneNumber?: Maybe<Scalars['String']['output']>;
  supportWebsite?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum OrderingDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaymentCardType = PaymentMethodDetailsType & {
  __typename?: 'PaymentCardType';
  expiryMonth?: Maybe<Scalars['Int']['output']>;
  expiryYear?: Maybe<Scalars['Int']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  paymentNetwork?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodDetailsType = {
  identifier?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  isActivated?: Maybe<Scalars['Boolean']['output']>;
  isPreferred?: Maybe<Scalars['Boolean']['output']>;
  paymentMethodDetails?: Maybe<PaymentMethodDetailsType>;
  paymentMethodType?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  provider?: Maybe<Scalars['String']['output']>;
};

export type PointDict = {
  __typename?: 'PointDict';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type PriceComponent = {
  formattedValue?: Maybe<Scalars['String']['output']>;
};

export type PricingInterface = {
  discount?: Maybe<DiscountInterface>;
};

export type PricingType = PricingInterface & {
  __typename?: 'PricingType';
  discount?: Maybe<DiscountInterface>;
};

/**
 * N.B. We may not actually need this from a payment perspective as mobile
 * will know on completion of the payment Sheet whether payment has
 * succeeded or failed, and we will log all appropriate events
 * / actions through existing webhooks.
 *
 * However, payment sheet may allow users to add a new payment
 * method before retry, in which case we'll need to store and process
 * that payment method here.
 */
export type ProcessStripePaymentRetry = {
  __typename?: 'ProcessStripePaymentRetry';
  success: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  basicVehicleLookup?: Maybe<BasicVehicleLookupResultType>;
  countries?: Maybe<CountryTypeConnection>;
  extendedVehicleLookup?: Maybe<ExtendedVehicleLookupResultType>;
  fleetsAdmin?: Maybe<AdminType>;
  fleetsAdmins?: Maybe<AdminTypeConnection>;
  fleetsBusinessEntity?: Maybe<BusinessEntityType>;
  fleetsBusinessEntityExpensesAggregate?: Maybe<BusinessEntityExpensesAggregateConnectionTypeConnection>;
  fleetsBusinessEntityReceiptsAggregate?: Maybe<BusinessEntityReceiptsAggregateConnectionTypeConnection>;
  fleetsCampaigns?: Maybe<CampaignTypeConnection>;
  fleetsCardOrders?: Maybe<FleetsCardOrderTypeConnection>;
  fleetsChargeRecords?: Maybe<FleetChargeRecordTypeConnection>;
  fleetsCreditHistory?: Maybe<Array<Maybe<FleetCreditType>>>;
  fleetsCurrentAdmin?: Maybe<AdminType>;
  fleetsCurrentAdminAccounts?: Maybe<Array<Maybe<AccountType>>>;
  fleetsCurrentBalance?: Maybe<FleetsCurrentBalanceType>;
  fleetsDriver?: Maybe<DriverType>;
  fleetsDriverGroups?: Maybe<DriverGroupTypeConnection>;
  fleetsDriverGroupsReceiptsAggregate?: Maybe<DriverGroupsReceiptsAggregateConnectionTypeConnection>;
  fleetsDrivers?: Maybe<DriverTypeConnection>;
  fleetsDriversExpenses?: Maybe<DriverExpensesConnectionTypeConnection>;
  fleetsDriversExpensesAggregate?: Maybe<DriversExpensesAggregateConnectionTypeConnection>;
  fleetsDriversReceipts?: Maybe<DriverReceiptsConnectionTypeConnection>;
  fleetsDriversReceiptsAggregate?: Maybe<DriversReceiptsAggregateConnectionTypeConnection>;
  fleetsDriversTokens?: Maybe<DriverTokenTypeConnection>;
  fleetsInvoices?: Maybe<FleetInvoiceTypeConnection>;
  fleetsReferralSummary?: Maybe<FleetsReferralSummaryType>;
  fleetsRfidCards?: Maybe<FleetsDriversCardTypeConnection>;
  fleetsSubscriptions?: Maybe<FleetSubscriptionTypeConnection>;
  fleetsSummaryStats?: Maybe<FleetSummaryStatsType>;
  fleetsTags?: Maybe<FleetsTagTypeConnection>;
  fleetsVehicles?: Maybe<FleetVehicleTypeConnection>;
  userVehicles?: Maybe<UserVehicleTypeConnection>;
  vehicleMakes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleModelVersions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleModels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicles?: Maybe<VehicleTypeConnection>;
};


export type QueryBasicVehicleLookupArgs = {
  regNumber: Scalars['String']['input'];
};


export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha2_Iexact?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
  alpha3_Iexact?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isValidDeliveryLocation?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Iexact?: InputMaybe<Scalars['String']['input']>;
  numeric?: InputMaybe<Scalars['String']['input']>;
  officialName?: InputMaybe<Scalars['String']['input']>;
  officialName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExtendedVehicleLookupArgs = {
  regNumber: Scalars['String']['input'];
  useHeuristics?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFleetsAdminArgs = {
  pk?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFleetsAdminsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<AdminTypeOrdering>>>;
};


export type QueryFleetsBusinessEntityExpensesAggregateArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  driverGroup?: InputMaybe<Scalars['Int']['input']>;
  endDate: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<BusinessEntityExpensesAggregateConnectionTypeOrdering>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryFleetsBusinessEntityReceiptsAggregateArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  driverGroup?: InputMaybe<Scalars['Int']['input']>;
  endDate: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<BusinessEntityReceiptsAggregateConnectionTypeOrdering>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryFleetsCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFleetsCardOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<FleetsCardOrderTypeOrdering>>>;
  status?: InputMaybe<EjnEjnCardOrderStatusChoices>;
};


export type QueryFleetsChargeRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<FleetChargeRecordTypeOrdering>>>;
};


export type QueryFleetsDriverArgs = {
  pk?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFleetsDriverGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFleetsDriverGroupsReceiptsAggregateArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  driverGroup?: InputMaybe<Scalars['Int']['input']>;
  endDate: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverGroupsReceiptsAggregateConnectionTypeOrdering>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryFleetsDriversArgs = {
  account_IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  account_User_Email?: InputMaybe<Scalars['String']['input']>;
  account_User_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  keywordSearch?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverTypeOrdering>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFleetsDriversExpensesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  drivers: Array<InputMaybe<Scalars['Int']['input']>>;
  endDate: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverExpensesConnectionTypeOrdering>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryFleetsDriversExpensesAggregateArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  driverGroup?: InputMaybe<Scalars['Int']['input']>;
  endDate: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriversExpensesAggregateConnectionTypeOrdering>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryFleetsDriversReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  drivers: Array<InputMaybe<Scalars['Int']['input']>>;
  endDate: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverReceiptsConnectionTypeOrdering>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryFleetsDriversReceiptsAggregateArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  driverGroup?: InputMaybe<Scalars['Int']['input']>;
  endDate: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriversReceiptsAggregateConnectionTypeOrdering>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryFleetsDriversTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keywordSearch?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverTokenTypeOrdering>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EjnEjnTokenStatusChoices>;
  status_In?: InputMaybe<Array<InputMaybe<EjnEjnTokenStatusChoices>>>;
};


export type QueryFleetsInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<FleetInvoiceTypeOrdering>>>;
};


export type QueryFleetsRfidCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<FleetsDriversCardTypeOrdering>>>;
};


export type QueryFleetsSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<FleetSubscriptionTypeOrdering>>>;
};


export type QueryFleetsSummaryStatsArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};


export type QueryFleetsTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<FleetsTagTypeOrdering>>>;
};


export type QueryFleetsVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  driver?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Iexact?: InputMaybe<Scalars['String']['input']>;
  octoEvDb?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<FleetVehicleTypeOrdering>>>;
  regNumber?: InputMaybe<Scalars['String']['input']>;
  regNumber_Iexact?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVehicleModelVersionsArgs = {
  vehicleMake: Scalars['String']['input'];
  vehicleModel: Scalars['String']['input'];
};


export type QueryVehicleModelsArgs = {
  vehicleMake: Scalars['String']['input'];
};


export type QueryVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  octoEvDbId?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<VehicleTypeOrdering>>>;
  vehicleMake?: InputMaybe<Scalars['String']['input']>;
  vehicleMake_Iexact?: InputMaybe<Scalars['String']['input']>;
  vehicleModel?: InputMaybe<Scalars['String']['input']>;
  vehicleModelVersion?: InputMaybe<Scalars['String']['input']>;
  vehicleModelVersion_Iexact?: InputMaybe<Scalars['String']['input']>;
  vehicleModel_Iexact?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Authenticates the user using the short lived auth token.
 * It's best used when switching platform and keeping users
 * authentication details there.
 */
export type QuickAuthenticate = {
  __typename?: 'QuickAuthenticate';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type RedeemCreditCode = {
  __typename?: 'RedeemCreditCode';
  success: Scalars['Boolean']['output'];
};

export type ReferralType = {
  __typename?: 'ReferralType';
  code: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  qrCode: Scalars['String']['output'];
  totalAmount: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/**
 * A callback for the remote token authentication flow.
 * This triggers the event for the 2nd device to authenticate the user
 * who's called this mutation from another device.
 */
export type RemoteAuthCallback = {
  __typename?: 'RemoteAuthCallback';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveDriverFromGroup = {
  __typename?: 'RemoveDriverFromGroup';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveDriverVehicle = {
  __typename?: 'RemoveDriverVehicle';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveVehicle = {
  __typename?: 'RemoveVehicle';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Resets the password for the user and authenticates them. */
export type ResetPassword = {
  __typename?: 'ResetPassword';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type RetrieveCharityInfo = {
  __typename?: 'RetrieveCharityInfo';
  charityInfo: CharityInformationType;
  success: Scalars['Boolean']['output'];
};

export type RetrieveCompanyHouseInfo = {
  __typename?: 'RetrieveCompanyHouseInfo';
  companyHouseInfo: CompanyHouseInformationType;
  success: Scalars['Boolean']['output'];
};

export type RetrieveFleetSignUpIntentToken = {
  __typename?: 'RetrieveFleetSignUpIntentToken';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RetrieveVatRegistration = {
  __typename?: 'RetrieveVatRegistration';
  success: Scalars['Boolean']['output'];
  vatRegistration: VatInformationType;
};

export type RetryPayment = {
  __typename?: 'RetryPayment';
  failureReason?: Maybe<StripeFailureReason>;
  success: Scalars['Boolean']['output'];
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int']['output'];
};

export type SecretDataType = {
  __typename?: 'SecretDataType';
  clientSecret?: Maybe<Scalars['String']['output']>;
  customerEphemeralKey?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type SendFleetSignUpEmail = {
  __typename?: 'SendFleetSignUpEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Mutation which starts the magic link authentication process. */
export type SendMagicLink = {
  __typename?: 'SendMagicLink';
  /** The signup type */
  signupType: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SendReceiptEmail = {
  __typename?: 'SendReceiptEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/**
 *
 *     List of possible statuses in a chronological order.
 *
 */
export enum SessionStatus {
  Invalid = 'INVALID',
  PotentialTimeout = 'POTENTIAL_TIMEOUT',
  ProcessingStartCommand = 'PROCESSING_START_COMMAND',
  ProcessingStopCommand = 'PROCESSING_STOP_COMMAND',
  ProcessingUnlockCommand = 'PROCESSING_UNLOCK_COMMAND',
  SessionCompleted = 'SESSION_COMPLETED',
  SessionDismissed = 'SESSION_DISMISSED',
  SessionInvalidatedByCpo = 'SESSION_INVALIDATED_BY_CPO',
  SessionInvalidatedByEjn = 'SESSION_INVALIDATED_BY_EJN',
  SessionManuallyCreatedByEjn = 'SESSION_MANUALLY_CREATED_BY_EJN',
  SessionOngoing = 'SESSION_ONGOING',
  StartCommandRejectedByBroker = 'START_COMMAND_REJECTED_BY_BROKER',
  StartCommandRejectedByCpo = 'START_COMMAND_REJECTED_BY_CPO',
  StopCommandRejectedByBroker = 'STOP_COMMAND_REJECTED_BY_BROKER',
  StopCommandRejectedByCpo = 'STOP_COMMAND_REJECTED_BY_CPO',
  UnlockCommandRejectedByBroker = 'UNLOCK_COMMAND_REJECTED_BY_BROKER',
  UnlockCommandRejectedByCpo = 'UNLOCK_COMMAND_REJECTED_BY_CPO',
  WaitingForCpoToProcessStartCommand = 'WAITING_FOR_CPO_TO_PROCESS_START_COMMAND',
  WaitingForCpoToProcessStopCommand = 'WAITING_FOR_CPO_TO_PROCESS_STOP_COMMAND',
  WaitingForCpoToProcessUnlockCommand = 'WAITING_FOR_CPO_TO_PROCESS_UNLOCK_COMMAND',
  WaitingForSessionToStart = 'WAITING_FOR_SESSION_TO_START',
  WaitingForSessionToStop = 'WAITING_FOR_SESSION_TO_STOP',
  WaitingForSessionToUnlock = 'WAITING_FOR_SESSION_TO_UNLOCK'
}

/**
 * Mutation which lets the user set their own password in the sign up process.
 * Initially all users signed up via the magic link flow will have unusable
 * password so this will allow them to set a new password. They're able to use
 * this only once!
 */
export type SetPassword = {
  __typename?: 'SetPassword';
  success: Scalars['Boolean']['output'];
};

/**
 * Mutation which signs up the user in the magic link auth process.
 * Note that because we're not using passwords at this stage we can't just
 * use extend from `graphql_jwt.ObtainJSONWebToken` class which would have
 * been much simpler. Without that we need to authenticate the user "manually"
 */
export type SignUp = {
  __typename?: 'SignUp';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** An enumeration. */
export enum Speeds {
  Fast = 'FAST',
  Rapid = 'RAPID',
  Slow = 'SLOW',
  Ultra = 'ULTRA'
}

export type StartChargingSession = {
  __typename?: 'StartChargingSession';
  session?: Maybe<ChargingSessionType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum Status {
  Available = 'AVAILABLE',
  Blocked = 'BLOCKED',
  Charging = 'CHARGING',
  Inoperative = 'INOPERATIVE',
  Outoforder = 'OUTOFORDER',
  Planned = 'PLANNED',
  Removed = 'REMOVED',
  Reserved = 'RESERVED',
  Unknown = 'UNKNOWN'
}

export type StopChargingSession = {
  __typename?: 'StopChargingSession';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type StoreStripePaymentMethodFromSetupIntent = {
  __typename?: 'StoreStripePaymentMethodFromSetupIntent';
  paymentMethod?: Maybe<PaymentMethodType>;
  stripeCard?: Maybe<PaymentCardType>;
};

/**
 *
 *     Enum holding all rejection reasons.
 *
 */
export enum StripeFailureReason {
  AmountTooSmall = 'AMOUNT_TOO_SMALL',
  CardDeclined = 'CARD_DECLINED',
  CardExpired = 'CARD_EXPIRED',
  CardNotSupported = 'CARD_NOT_SUPPORTED',
  CardVelocityExceeded = 'CARD_VELOCITY_EXCEEDED',
  CurrencyNotSupported = 'CURRENCY_NOT_SUPPORTED',
  DeclineRateLimitExceeded = 'DECLINE_RATE_LIMIT_EXCEEDED',
  DoNotHonor = 'DO_NOT_HONOR',
  Fraudulent = 'FRAUDULENT',
  IncorrectNumber = 'INCORRECT_NUMBER',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InvalidAccount = 'INVALID_ACCOUNT',
  InvalidRequest = 'INVALID_REQUEST',
  LostCard = 'LOST_CARD',
  MerchantBlacklist = 'MERCHANT_BLACKLIST',
  PaymentDeclined = 'PAYMENT_DECLINED',
  PickupCard = 'PICKUP_CARD',
  RestrictedCard = 'RESTRICTED_CARD',
  RevocationOfAllAuthorizations = 'REVOCATION_OF_ALL_AUTHORIZATIONS',
  RevocationOfAuthorization = 'REVOCATION_OF_AUTHORIZATION',
  SecurityViolation = 'SECURITY_VIOLATION',
  ServiceNotAllowed = 'SERVICE_NOT_ALLOWED',
  StolenCard = 'STOLEN_CARD',
  StopPaymentOrder = 'STOP_PAYMENT_ORDER',
  TransactionNotAllowed = 'TRANSACTION_NOT_ALLOWED',
  TryAgainLater = 'TRY_AGAIN_LATER'
}

/**
 *
 *     Subscription cost type.
 *
 */
export enum SubscriptionCostType {
  Free = 'FREE',
  FullPrice = 'FULL_PRICE',
  TrialPrice = 'TRIAL_PRICE'
}

/** Mutation for tagging drivers. */
export type TagDrivers = {
  __typename?: 'TagDrivers';
  success: Scalars['Boolean']['output'];
};

/**
 * Authenticates an identity token from a third party provider
 * (eg Apple, Google) and signs the user up or in.
 */
export type ThirdPartyAuthentication = {
  __typename?: 'ThirdPartyAuthentication';
  login?: Maybe<ThirdPartyLoginResponse>;
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  signup?: Maybe<ThirdPartySignUpResponse>;
  token: Scalars['String']['output'];
};

/** Object to return data for a third party login response */
export type ThirdPartyLoginResponse = {
  __typename?: 'ThirdPartyLoginResponse';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** Object to contain data for either a signup response */
export type ThirdPartySignUpResponse = {
  __typename?: 'ThirdPartySignUpResponse';
  campaignCode?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

/** An enumeration. */
export enum UnitTypeOptions {
  Driver = 'DRIVER'
}

export type UnlockConnector = {
  __typename?: 'UnlockConnector';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Mutation for removing tags from drivers. */
export type UntagDrivers = {
  __typename?: 'UntagDrivers';
  success: Scalars['Boolean']['output'];
};

export type UserType = {
  __typename?: 'UserType';
  accounts?: Maybe<Array<Maybe<AccountType>>>;
  activeAccount?: Maybe<AccountType>;
  canSetPassword: Scalars['Boolean']['output'];
  countryCode: Scalars['String']['output'];
  currentBalance: CurrentBalance;
  customisationOptions?: Maybe<CustomisationOptions>;
  email: Scalars['String']['output'];
  financialInfo: FinancialInfo;
  firstName: Scalars['String']['output'];
  hasAcceptedTermsAndConditions: Scalars['Boolean']['output'];
  /** @deprecated Use payment methods information. */
  hasConnectedKrakenAccount: Scalars['Boolean']['output'];
  hasElectricJuiceCard: Scalars['Boolean']['output'];
  hasEvs: Scalars['Boolean']['output'];
  hasSetPassword: Scalars['Boolean']['output'];
  /** Returns True if a user has a valid payment method. */
  hasValidPaymentInstruction: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isFleetDriver: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  preferredName: Scalars['String']['output'];
  referralInfo?: Maybe<ReferralType>;
  showFleetPlatformLink?: Maybe<Scalars['Boolean']['output']>;
};

export type UserVehicleType = Node & {
  __typename?: 'UserVehicleType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  regNumber: Scalars['String']['output'];
  vehicle: VehicleType;
};

export type UserVehicleTypeConnection = {
  __typename?: 'UserVehicleTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserVehicleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserVehicleType` and its cursor. */
export type UserVehicleTypeEdge = {
  __typename?: 'UserVehicleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserVehicleType>;
};

export type VatInformationType = {
  __typename?: 'VatInformationType';
  name: Scalars['String']['output'];
  vatNumber: Scalars['String']['output'];
};

export type VehicleType = Node & {
  __typename?: 'VehicleType';
  availabilityDateFrom?: Maybe<Scalars['String']['output']>;
  availabilityDateTo?: Maybe<Scalars['String']['output']>;
  batteryCapacityFull: Scalars['Float']['output'];
  batteryCapacityUseable: Scalars['Float']['output'];
  chargePlug: Scalars['String']['output'];
  chargePlugLocation?: Maybe<Scalars['String']['output']>;
  chargeStandardChargeSpeed?: Maybe<Scalars['Int']['output']>;
  chargeStandardChargeTime?: Maybe<Scalars['Int']['output']>;
  chargeStandardPhase?: Maybe<Scalars['Int']['output']>;
  chargeStandardPower: Scalars['Float']['output'];
  efficiencyReal: Scalars['Float']['output'];
  fastchargeChargeSpeed?: Maybe<Scalars['Int']['output']>;
  fastchargeChargeTime?: Maybe<Scalars['Int']['output']>;
  fastchargeOptional?: Maybe<Scalars['Boolean']['output']>;
  fastchargePlug?: Maybe<Scalars['String']['output']>;
  fastchargePlugLocation?: Maybe<Scalars['String']['output']>;
  fastchargePowerAvg?: Maybe<Scalars['Int']['output']>;
  fastchargePowerMax?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isHybrid: Scalars['Boolean']['output'];
  octoEvDbId: Scalars['Int']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  rangeReal: Scalars['Int']['output'];
  rangeRealMiles?: Maybe<Scalars['Int']['output']>;
  vehicleMake: Scalars['String']['output'];
  vehicleModel: Scalars['String']['output'];
  vehicleModelVersion?: Maybe<Scalars['String']['output']>;
};

export type VehicleTypeConnection = {
  __typename?: 'VehicleTypeConnection';
  /** Number of nodes in the edge. */
  edgeCount: Scalars['Int']['output'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `VehicleType` and its cursor. */
export type VehicleTypeEdge = {
  __typename?: 'VehicleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleType>;
};

export type VehicleTypeOrdering = {
  direction: OrderingDirectionEnum;
  orderBy: VehicleTypeOrderingFields;
};

/** An enumeration. */
export enum VehicleTypeOrderingFields {
  Pk = 'PK'
}

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar']['output'];
};

/** An enumeration. */
export enum Weekdays {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type CountriesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type CountriesQuery = { __typename?: 'Query', countries?: { __typename?: 'CountryTypeConnection', edges: Array<{ __typename?: 'CountryTypeEdge', node?: { __typename?: 'CountryType', alpha2: string, alpha3: string, id: string, isSignupSupported: boolean, isValidDeliveryLocation: boolean, name: string, numeric: string, officialName: string, pk?: number | null } | null } | null> } | null };

export type ExtendedVehicleLookupQueryVariables = Exact<{
  regNumber: Scalars['String']['input'];
  useHeuristics?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExtendedVehicleLookupQuery = { __typename?: 'Query', extendedVehicleLookup?: { __typename?: 'ExtendedVehicleLookupResultType', isElectric?: boolean | null, model?: string | null, make?: string | null, confidence?: string | null, options?: Array<{ __typename?: 'VehicleType', id: string, pk?: number | null, availabilityDateFrom?: string | null, availabilityDateTo?: string | null, vehicleMake: string, vehicleModelVersion?: string | null, vehicleModel: string, octoEvDbId: number, rangeReal: number, batteryCapacityFull: number, batteryCapacityUseable: number, chargePlug: string, fastchargePlug?: string | null } | null> | null } | null };

export type FleetsAddTagMutationVariables = Exact<{
  colour: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type FleetsAddTagMutation = { __typename?: 'Mutation', fleetsAddTag?: { __typename?: 'AddTag', success: boolean } | null };

export type FleetsAddVehicleMutationVariables = Exact<{
  vehicle: Scalars['Int']['input'];
  reg: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type FleetsAddVehicleMutation = { __typename?: 'Mutation', fleetsAddVehicle?: { __typename?: 'AddVehicle', success?: boolean | null } | null };

export type FleetsAdminLogInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type FleetsAdminLogInMutation = { __typename?: 'Mutation', fleetsAdminLogIn?: { __typename?: 'FleetsObtainJSONWebToken', token: string, refreshToken: string, refreshExpiresIn: number } | null };

export type FleetsAdminSendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type FleetsAdminSendResetPasswordEmailMutation = { __typename?: 'Mutation', fleetsAdminSendResetPasswordEmail?: { __typename?: 'AdminSendResetPasswordEmail', success?: boolean | null } | null };

export type FleetsAdminSetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  singleTimeToken: Scalars['String']['input'];
}>;


export type FleetsAdminSetPasswordMutation = { __typename?: 'Mutation', fleetsAdminSetPassword?: { __typename?: 'AdminSetPassword', success?: boolean | null } | null };

export type FleetsCurrentAdminUserQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsCurrentAdminUserQuery = { __typename?: 'Query', fleetsCurrentAdmin?: { __typename?: 'AdminType', isPrimaryAdmin: boolean, isSuperuser: boolean, hasSignedUp?: boolean | null, user?: { __typename?: 'UserType', id: string, email: string, firstName: string, lastName: string } | null } | null };

export type FleetsAdminUsersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  first: Scalars['Int']['input'];
  ordering?: InputMaybe<Array<InputMaybe<AdminTypeOrdering>> | InputMaybe<AdminTypeOrdering>>;
}>;


export type FleetsAdminUsersQuery = { __typename?: 'Query', fleetsCurrentAdmin?: { __typename?: 'AdminType', isPrimaryAdmin: boolean, isSuperuser: boolean, hasSignedUp?: boolean | null, user?: { __typename?: 'UserType', id: string, email: string, firstName: string, lastName: string } | null } | null, fleetsAdmins?: { __typename?: 'AdminTypeConnection', totalCount: number, edgeCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'AdminTypeEdge', node?: { __typename?: 'AdminType', id: string, pk?: number | null, createdAt: any, isSuperuser: boolean, hasSignedUp?: boolean | null, hasBillingPermission: boolean, hasActiveAccount?: boolean | null, user?: { __typename?: 'UserType', id: string, email: string, isActive: boolean } | null } | null } | null> } | null };

export type FleetsAssignCardToDriverMutationVariables = Exact<{
  hexCode: Scalars['String']['input'];
  driver: Scalars['Int']['input'];
}>;


export type FleetsAssignCardToDriverMutation = { __typename?: 'Mutation', fleetsAssignCardToDriver?: { __typename?: 'DriverAssignCard', success?: boolean | null } | null };

export type FleetsAssignVehicleMutationVariables = Exact<{
  driver: Scalars['Int']['input'];
  fleetVehicle: Scalars['Int']['input'];
}>;


export type FleetsAssignVehicleMutation = { __typename?: 'Mutation', fleetsAssignVehicle?: { __typename?: 'AssignVehicle', success?: boolean | null } | null };

export type FleetsBulkCardOrderMutationVariables = Exact<{
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  address4?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
}>;


export type FleetsBulkCardOrderMutation = { __typename?: 'Mutation', fleetsBulkCardOrder?: { __typename?: 'FleetsBulkCardOrder', success?: boolean | null } | null };

export type FleetsBulkCreateDriversMutationVariables = Exact<{
  file: Scalars['FileUpload']['input'];
}>;


export type FleetsBulkCreateDriversMutation = { __typename?: 'Mutation', fleetsBulkCreateDrivers?: { __typename?: 'BulkCreateDrivers', success: boolean } | null };

export type FleetsBusinessEntityQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsBusinessEntityQuery = { __typename?: 'Query', fleetsBusinessEntity?: { __typename?: 'BusinessEntityType', pk?: number | null, name: string, registeredAt: any, countryCode: string, isVerified: boolean, deliveryAddressLine1: string, deliveryAddressLine2: string, deliveryAddressLine3: string, deliveryAddressLine4: string, deliveryPostcode: string, deliveryCountry: string, logo?: string | null, companyHouseNumber?: string | null, vatNumber?: string | null, charityNumber?: string | null, companyType?: FleetSignUpCompanyType | null, hasPaymentMethod?: boolean | null, hasCharged?: boolean | null, referralCode?: string | null, billingAccount?: { __typename?: 'FleetsBillingAccountType', email?: string | null, name?: string | null, billingDetails?: { __typename?: 'BillingDetailsType', billingAddressLine1?: string | null, billingAddressLine2?: string | null, concatenatedAddressLines345?: string | null, billingAddressPostcode?: string | null, billingCountryCode?: string | null } | null, paymentMethod?: { __typename?: 'PaymentMethodType', pk: number, provider?: string | null, paymentMethodType?: string | null, isPreferred?: boolean | null, paymentMethodDetails?: { __typename?: 'DirectDebitType', identifier?: string | null, sortCode?: string | null, accountHolder?: string | null } | { __typename?: 'GenericPaymentMethodDetailsType', identifier?: string | null, stripePaymentMethodType: string } | { __typename?: 'PaymentCardType', identifier?: string | null, paymentNetwork?: string | null, expiryYear?: number | null, expiryMonth?: number | null } | null } | null } | null } | null };

export type FleetsBusinessEntityReceiptsAggregateQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;


export type FleetsBusinessEntityReceiptsAggregateQuery = { __typename?: 'Query', fleetsBusinessEntityReceiptsAggregate?: { __typename?: 'BusinessEntityReceiptsAggregateConnectionTypeConnection', edges: Array<{ __typename?: 'BusinessEntityReceiptsAggregateConnectionTypeEdge', node?: { __typename?: 'BusinessEntityReceiptsAggregateType', totalCost?: any | null, totalConsumption?: any | null, numberOfReceipts?: number | null } | null } | null> } | null };

export type FleetsCardOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsCardOrdersQuery = { __typename?: 'Query', fleetsCardOrders?: { __typename?: 'FleetsCardOrderTypeConnection', edges: Array<{ __typename?: 'FleetsCardOrderTypeEdge', node?: { __typename?: 'FleetsCardOrderType', id: string, pk?: number | null, status: EjnEjnCardOrderStatusChoices } | null } | null> } | null };

export type FleetsChargeRecordsQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsChargeRecordsQuery = { __typename?: 'Query', fleetsChargeRecords?: { __typename?: 'FleetChargeRecordTypeConnection', edges: Array<{ __typename?: 'FleetChargeRecordTypeEdge', cursor: string, node?: { __typename?: 'FleetChargeRecordType', pk?: number | null, id: string, totalCost?: any | null, totalConsumption?: any | null, numberOfReceipts?: number | null, startDate?: any | null, endDate?: any | null, downloadLink?: string | null } | null } | null> } | null };

export type FleetsCompleteFleetSignupMutationVariables = Exact<{
  companyHouseNumber: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  signupToken: Scalars['String']['input'];
  companyType?: InputMaybe<Scalars['String']['input']>;
  charityNumber?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  driverEstimate?: InputMaybe<Scalars['Int']['input']>;
  homeChargingEstimate?: InputMaybe<Scalars['Decimal']['input']>;
  howHeard?: InputMaybe<Scalars['String']['input']>;
  publicChargingEstimate?: InputMaybe<Scalars['Decimal']['input']>;
  sector?: InputMaybe<Scalars['String']['input']>;
  workplaceChargingEstimate?: InputMaybe<Scalars['Decimal']['input']>;
}>;


export type FleetsCompleteFleetSignupMutation = { __typename?: 'Mutation', fleetsCompleteFleetSignup?: { __typename?: 'CompleteFleetSignUp', payload?: string | null, refreshExpiresIn?: number | null, success: boolean, token?: string | null, refreshToken?: string | null } | null };

export type FleetsCreateAdminMutationVariables = Exact<{
  email: Scalars['String']['input'];
  isSuperuser: Scalars['Boolean']['input'];
}>;


export type FleetsCreateAdminMutation = { __typename?: 'Mutation', fleetsCreateAdmin?: { __typename?: 'CreateAdmin', success?: boolean | null } | null };

export type FleetsCreateBusinessEntityMutationVariables = Exact<{
  adminEmail: Scalars['String']['input'];
  adminFamilyName: Scalars['String']['input'];
  adminFirstName: Scalars['String']['input'];
  adminPassword: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
}>;


export type FleetsCreateBusinessEntityMutation = { __typename?: 'Mutation', fleetsCreateBusinessEntity?: { __typename?: 'CreateBusinessEntity', success?: boolean | null } | null };

export type FleetsCreateDriverMutationVariables = Exact<{
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
  preferredNameOnCard?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type FleetsCreateDriverMutation = { __typename?: 'Mutation', fleetsCreateDriver?: { __typename?: 'CreateDriver', success: boolean } | null };

export type FleetsCreditHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsCreditHistoryQuery = { __typename?: 'Query', fleetsCreditHistory?: Array<{ __typename?: 'FleetCreditType', amount: any, currency: string, createdAt: any } | null> | null };

export type FleetsCurrentAdminAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsCurrentAdminAccountsQuery = { __typename?: 'Query', fleetsCurrentAdminAccounts?: Array<{ __typename?: 'AccountType', id: number, entityName: string, entityDomain: string, isDefault: boolean, isFleetDriver?: boolean | null, isFleetAdmin?: boolean | null } | null> | null };

export type FleetsCurrentBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsCurrentBalanceQuery = { __typename?: 'Query', fleetsCurrentBalance?: { __typename?: 'FleetsCurrentBalanceType', amount: any, currency: string } | null };

export type FleetsDeactivateDriversCardMutationVariables = Exact<{
  card: Scalars['Int']['input'];
  driver: Scalars['Int']['input'];
}>;


export type FleetsDeactivateDriversCardMutation = { __typename?: 'Mutation', fleetsFreezeDriversCard?: { __typename?: 'DriverFreezeCard', success?: boolean | null } | null };

export type FleetsDeleteTagMutationVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;


export type FleetsDeleteTagMutation = { __typename?: 'Mutation', fleetsDeleteTag?: { __typename?: 'DeleteTag', success: boolean } | null };

export type FleetsDriverQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;


export type FleetsDriverQuery = { __typename?: 'Query', fleetsDriver?: { __typename?: 'DriverType', pk?: number | null, createdAt: any, mobileNumber?: string | null, hasSignedUp?: boolean | null, tags?: Array<{ __typename?: 'FleetsTagType', pk?: number | null, id: string, name: string, colour: string } | null> | null, user?: { __typename?: 'UserType', hasElectricJuiceCard: boolean, hasAcceptedTermsAndConditions: boolean, email: string, lastName: string, firstName: string, preferredName: string } | null, businessEntity: { __typename?: 'BusinessEntityType', pk?: number | null, name: string } } | null };

export type FleetsDriversReceiptsQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  drivers: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  first: Scalars['Int']['input'];
  ordering?: InputMaybe<Array<InputMaybe<DriverReceiptsConnectionTypeOrdering>> | InputMaybe<DriverReceiptsConnectionTypeOrdering>>;
}>;


export type FleetsDriversReceiptsQuery = { __typename?: 'Query', fleetsDriversReceipts?: { __typename?: 'DriverReceiptsConnectionTypeConnection', totalCount: number, edges: Array<{ __typename?: 'DriverReceiptsConnectionTypeEdge', node?: { __typename?: 'DriverReceiptType', pk: number, netAmount: any, grossAmount: any, additionalFees: any, currency: string, kwhUsed: any, createdAt: any, periodEnd: any, periodStart: any, duration: number, chargingLocationId?: string | null, status?: DriversReceiptStatusOptions | null, invoiceNumber?: string | null, operator?: { __typename?: 'OperatorType', id: string, name: string, logoDark?: string | null, pk?: number | null } | null, driver?: { __typename?: 'DriverType', id: string, pk?: number | null, user?: { __typename?: 'UserType', firstName: string, lastName: string } | null } | null, location?: { __typename?: 'FleetReceiptLocationType', coordinates?: any | null, address?: string | null, pk?: number | null } | null } | null } | null> } | null };

export type FleetsDriverSetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  singleTimeToken: Scalars['String']['input'];
}>;


export type FleetsDriverSetPasswordMutation = { __typename?: 'Mutation', fleetsDriverSetPassword?: { __typename?: 'DriverSetPassword', success?: boolean | null } | null };

export type FleetsDriversQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  ordering?: InputMaybe<Array<InputMaybe<DriverTypeOrdering>> | InputMaybe<DriverTypeOrdering>>;
  search?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type FleetsDriversQuery = { __typename?: 'Query', fleetsDrivers?: { __typename?: 'DriverTypeConnection', totalCount: number, edgeCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'DriverTypeEdge', node?: { __typename?: 'DriverType', id: string, pk?: number | null, createdAt: any, mobileNumber?: string | null, hasSignedUp?: boolean | null, isActive: boolean, ejnCard?: { __typename?: 'EJNCard', status: EjnEjnTokenStatusChoices, number: string, nameOnCard?: string | null, id: string, pk?: number | null } | null, tags?: Array<{ __typename?: 'FleetsTagType', pk?: number | null, id: string, name: string, colour: string } | null> | null, user?: { __typename?: 'UserType', id: string, hasElectricJuiceCard: boolean, hasAcceptedTermsAndConditions: boolean, email: string, lastName: string, firstName: string, preferredName: string, isActive: boolean } | null, businessEntity: { __typename?: 'BusinessEntityType', pk?: number | null, name: string } } | null } | null> } | null };

export type FleetsDriversReceiptsAggregateQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  first: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  ordering?: InputMaybe<Array<InputMaybe<DriversReceiptsAggregateConnectionTypeOrdering>> | InputMaybe<DriversReceiptsAggregateConnectionTypeOrdering>>;
}>;


export type FleetsDriversReceiptsAggregateQuery = { __typename?: 'Query', fleetsDriversReceiptsAggregate?: { __typename?: 'DriversReceiptsAggregateConnectionTypeConnection', totalCount: number, edgeCount: number, edges: Array<{ __typename?: 'DriversReceiptsAggregateConnectionTypeEdge', node?: { __typename?: 'DriversReceiptsAggregateType', totalConsumption?: any | null, totalCost?: any | null, numberOfReceipts?: number | null, driver?: { __typename?: 'DriverType', pk?: number | null, user?: { __typename?: 'UserType', firstName: string, lastName: string } | null } | null } | null } | null> } | null };

export type FleetsDriversTokensQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<EjnEjnTokenStatusChoices>;
  search?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverTokenTypeOrdering>> | InputMaybe<DriverTokenTypeOrdering>>;
}>;


export type FleetsDriversTokensQuery = { __typename?: 'Query', fleetsDriversTokens?: { __typename?: 'DriverTokenTypeConnection', edgeCount: number, totalCount: number, edges: Array<{ __typename?: 'DriverTokenTypeEdge', cursor: string, node?: { __typename?: 'DriverTokenType', createdAt: any, id: string, number: string, pk?: number | null, status: EjnEjnTokenStatusChoices, driver?: { __typename?: 'DriverType', pk?: number | null, user?: { __typename?: 'UserType', firstName: string, lastName: string } | null } | null } | null } | null>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null, activeTokens?: { __typename?: 'DriverTokenTypeConnection', totalCount: number } | null, pendingTokens?: { __typename?: 'DriverTokenTypeConnection', totalCount: number } | null, unassignedTokens?: { __typename?: 'DriverTokenTypeConnection', totalCount: number } | null };

export type FleetsEditAdminActiveStatusMutationVariables = Exact<{
  isActive: Scalars['Boolean']['input'];
  pk: Scalars['Int']['input'];
}>;


export type FleetsEditAdminActiveStatusMutation = { __typename?: 'Mutation', fleetsEditAdminActiveStatus?: { __typename?: 'EditAdminActiveStatus', success?: boolean | null } | null };

export type FleetsEditBusinessEntityMutationVariables = Exact<{
  deliveryAddress: EjnCardDeliveryDetailsInput;
}>;


export type FleetsEditBusinessEntityMutation = { __typename?: 'Mutation', fleetsEditBusinessEntity?: { __typename?: 'EditBusinessEntity', success?: boolean | null } | null };

export type FleetsEditDriverMutationVariables = Exact<{
  deliveryAddress?: InputMaybe<EjnCardDeliveryDetailsInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  pk: Scalars['Int']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type FleetsEditDriverMutation = { __typename?: 'Mutation', fleetsEditDriver?: { __typename?: 'EditDriver', success?: boolean | null } | null };

export type FleetsEditStripeDirectDebitBillingDetailsMutationVariables = Exact<{
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
}>;


export type FleetsEditStripeDirectDebitBillingDetailsMutation = { __typename?: 'Mutation', fleetsEditStripeDirectDebitBillingDetails?: { __typename?: 'FleetsEditStripeDirectDebitBillingDetails', success: boolean } | null };

export type FleetsEditTagMutationVariables = Exact<{
  name: Scalars['String']['input'];
  colour: Scalars['String']['input'];
  pk: Scalars['Int']['input'];
}>;


export type FleetsEditTagMutation = { __typename?: 'Mutation', fleetsEditTag?: { __typename?: 'EditTag', success: boolean } | null };

export type FleetsGetPaymentInstructionSecretMutationVariables = Exact<{
  instructionType?: Scalars['String']['input'];
}>;


export type FleetsGetPaymentInstructionSecretMutation = { __typename?: 'Mutation', fleetsGetPaymentInstructionSecret?: { __typename?: 'FleetsGetPaymentInstructionSecret', secretKey: string } | null };

export type FleetsInvoicesQueryVariables = Exact<{
  ordering?: InputMaybe<Array<InputMaybe<FleetInvoiceTypeOrdering>> | InputMaybe<FleetInvoiceTypeOrdering>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type FleetsInvoicesQuery = { __typename?: 'Query', fleetsInvoices?: { __typename?: 'FleetInvoiceTypeConnection', edges: Array<{ __typename?: 'FleetInvoiceTypeEdge', node?: { __typename?: 'FleetInvoiceType', createdAt: any, invoiceNumber?: string | null, subscriptionTotal?: any | null, chargesTotal?: any | null, id: string, pk?: number | null, downloadLink?: string | null, chargeBreakdownDownloadLink?: string | null, totalGross?: any | null, totalNet?: any | null, totalVat?: any | null, totalCredit?: any | null, status?: InvoiceStatus | null } | null } | null> } | null };

export type FleetsReassignCardToDriverMutationVariables = Exact<{
  card: Scalars['Int']['input'];
  newDriver: Scalars['Int']['input'];
  oldDriver: Scalars['Int']['input'];
}>;


export type FleetsReassignCardToDriverMutation = { __typename?: 'Mutation', fleetsReassignCardToDriver?: { __typename?: 'DriverReassignCard', success?: boolean | null } | null };

export type FleetsRedeemCreditCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type FleetsRedeemCreditCodeMutation = { __typename?: 'Mutation', fleetsRedeemCreditCode?: { __typename?: 'RedeemCreditCode', success: boolean } | null };

export type FleetsReferralSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsReferralSummaryQuery = { __typename?: 'Query', fleetsReferralSummary?: { __typename?: 'FleetsReferralSummaryType', totalReferrals: number, totalCompletedReferrals: number, totalRewarded: any, currency: { __typename?: 'CurrencyType', name: string, namePlural: string, symbol: string, nativeSymbol: string, decimalDigits: number, minorUnitConversion: number, isoCode: string, id: string } } | null };

export type FleetsRemoveDriverVehicleMutationVariables = Exact<{
  driver: Scalars['Int']['input'];
  fleetVehicle: Scalars['Int']['input'];
}>;


export type FleetsRemoveDriverVehicleMutation = { __typename?: 'Mutation', fleetsRemoveDriverVehicle?: { __typename?: 'RemoveDriverVehicle', success?: boolean | null } | null };

export type FleetsRemoveVehicleMutationVariables = Exact<{
  fleetVehicle: Scalars['Int']['input'];
}>;


export type FleetsRemoveVehicleMutation = { __typename?: 'Mutation', fleetsRemoveVehicle?: { __typename?: 'RemoveVehicle', success?: boolean | null } | null };

export type FleetsRetrieveCharityInfoMutationVariables = Exact<{
  charityNumber: Scalars['String']['input'];
  signupToken: Scalars['String']['input'];
}>;


export type FleetsRetrieveCharityInfoMutation = { __typename?: 'Mutation', fleetsRetrieveCharityInfo?: { __typename?: 'RetrieveCharityInfo', success: boolean, charityInfo: { __typename?: 'CharityInformationType', charityName: string, charityNumber: string, address: { __typename?: 'CharityAddressType', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, addressLine4?: string | null, addressLine5?: string | null, postalCode?: string | null } } } | null };

export type FleetsRetrieveCompanyHouseInfoMutationVariables = Exact<{
  companyHouseNumber: Scalars['String']['input'];
  signupToken: Scalars['String']['input'];
}>;


export type FleetsRetrieveCompanyHouseInfoMutation = { __typename?: 'Mutation', fleetsRetrieveCompanyHouseInfo?: { __typename?: 'RetrieveCompanyHouseInfo', success: boolean, companyHouseInfo: { __typename?: 'CompanyHouseInformationType', companyName: string, companyNumber: string, registeredOfficeAddress: { __typename?: 'CompanyHouseOfficeAddressType', postalCode: string, locality: string, addressLine1: string, addressLine2: string } } } | null };

export type FleetsRetrieveVatRegistrationMutationVariables = Exact<{
  vatNumber: Scalars['String']['input'];
  signupToken: Scalars['String']['input'];
}>;


export type FleetsRetrieveVatRegistrationMutation = { __typename?: 'Mutation', fleetsRetrieveVatRegistration?: { __typename?: 'RetrieveVatRegistration', success: boolean, vatRegistration: { __typename?: 'VatInformationType', vatNumber: string, name: string } } | null };

export type FleetsSendDriverSignupEmailMutationVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;


export type FleetsSendDriverSignupEmailMutation = { __typename?: 'Mutation', fleetsSendSignupEmail?: { __typename?: 'DriverSendSignupEmail', success?: boolean | null } | null };

export type FleetsSendFleetsSignupEmailMutationVariables = Exact<{
  email: Scalars['EmailType']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
  campaignCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type FleetsSendFleetsSignupEmailMutation = { __typename?: 'Mutation', fleetsSendFleetSignupEmail?: { __typename?: 'SendFleetSignUpEmail', success?: boolean | null } | null };

export type FleetsSendResetPasswordEmailMutationVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;


export type FleetsSendResetPasswordEmailMutation = { __typename?: 'Mutation', fleetsSendResetPasswordEmail?: { __typename?: 'DriverSendPasswordResetEmail', success?: boolean | null } | null };

export type FleetsStartDirectDebitCheckoutSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type FleetsStartDirectDebitCheckoutSessionMutation = { __typename?: 'Mutation', fleetsStartDirectDebitCheckoutSession?: { __typename?: 'FleetsStartDirectDebitCheckoutSession', checkoutUrl: string } | null };

export type FleetsStorePaymentInstructionFromCheckoutMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  validFrom: Scalars['DateTime']['input'];
}>;


export type FleetsStorePaymentInstructionFromCheckoutMutation = { __typename?: 'Mutation', fleetsStorePaymentInstructionFromCheckout?: { __typename?: 'FleetsStorePaymentInstructionFromCheckout', success: boolean } | null };

export type FleetsStorePaymentInstructionMutationVariables = Exact<{
  validFrom: Scalars['DateTime']['input'];
  vendorReference: Scalars['String']['input'];
}>;


export type FleetsStorePaymentInstructionMutation = { __typename?: 'Mutation', fleetsStorePaymentInstruction?: { __typename?: 'FleetsStorePaymentInstruction', success: boolean } | null };

export type FleetsSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsSubscriptionsQuery = { __typename?: 'Query', fleetsSubscriptions?: { __typename?: 'FleetSubscriptionTypeConnection', edges: Array<{ __typename?: 'FleetSubscriptionTypeEdge', cursor: string, node?: { __typename?: 'FleetSubscriptionType', pk?: number | null, id: string, createdAt: any, isActive: boolean, unitType: UnitTypeOptions, price: any, currencyCode: string, freeCount: number, nextPaymentDate: any, trialEndDate?: any | null, estimatedCost?: Array<{ __typename?: 'EstimatedCostType', count?: number | null, price?: any | null, amount?: any | null, currencyCode?: string | null, type?: SubscriptionCostType | null } | null> | null } | null } | null> } | null };

export type FleetsSummaryStatsQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;


export type FleetsSummaryStatsQuery = { __typename?: 'Query', fleetsSummaryStats?: { __typename?: 'FleetSummaryStatsType', totalCost?: any | null, averageSessionLength?: any | null, totalCo2Saved?: any | null, combinedCpoSplit?: Array<{ __typename?: 'CpoUsageSplitType', percentage?: number | null, cpo?: { __typename?: 'CpoDetailsType', pk?: number | null, name?: string | null } | null } | null> | null, cpoSplitPerDriver?: Array<{ __typename?: 'DriverCpoUsageSplitType', driverId?: number | null, firstName?: string | null, lastName?: string | null, cpoSplit?: Array<{ __typename?: 'CpoUsageSplitType', percentage?: number | null, cpo?: { __typename?: 'CpoDetailsType', pk?: number | null, name?: string | null } | null } | null> | null } | null> | null, chargerSpeedSplit?: Array<{ __typename?: 'ChargerUsageSplitType', chargerSpeed?: string | null, percentage?: number | null } | null> | null } | null };

export type FleetsTagDriversMutationVariables = Exact<{
  drivers?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
  tags: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
}>;


export type FleetsTagDriversMutation = { __typename?: 'Mutation', fleetsTagDrivers?: { __typename?: 'TagDrivers', success: boolean } | null };

export type FleetsTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type FleetsTagsQuery = { __typename?: 'Query', fleetsTags?: { __typename?: 'FleetsTagTypeConnection', totalCount: number, edgeCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'FleetsTagTypeEdge', node?: { __typename?: 'FleetsTagType', id: string, pk?: number | null, name: string, colour: string } | null } | null> } | null };

export type FleetsUntagDriversMutationVariables = Exact<{
  drivers?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
  tags: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
}>;


export type FleetsUntagDriversMutation = { __typename?: 'Mutation', fleetsUntagDrivers?: { __typename?: 'UntagDrivers', success: boolean } | null };

export type FleetsVehiclesQueryVariables = Exact<{
  driver?: InputMaybe<Scalars['Int']['input']>;
  first: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type FleetsVehiclesQuery = { __typename?: 'Query', fleetsVehicles?: { __typename?: 'FleetVehicleTypeConnection', totalCount: number, edgeCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'FleetVehicleTypeEdge', cursor: string, node?: { __typename?: 'FleetVehicleType', id: string, pk?: number | null, name: string, regNumber: string, vehicle: { __typename?: 'VehicleType', pk?: number | null, id: string, availabilityDateFrom?: string | null, availabilityDateTo?: string | null, vehicleMake: string, vehicleModelVersion?: string | null, vehicleModel: string, octoEvDbId: number, rangeReal: number, batteryCapacityFull: number, batteryCapacityUseable: number, chargePlug: string, fastchargePlug?: string | null, isHybrid: boolean, chargeStandardPower: number, efficiencyReal: number }, drivers: { __typename?: 'DriverTypeConnection', edgeCount: number, totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'DriverTypeEdge', cursor: string, node?: { __typename?: 'DriverType', createdAt: any, id: string, drivingLicence?: string | null, businessEntity: { __typename?: 'BusinessEntityType', pk?: number | null, name: string, registeredAt: any, deliveryAddressLine1: string, deliveryAddressLine2: string, deliveryPostcode: string, deliveryAddressLine3: string, deliveryAddressLine4: string, deliveryCountry: string, isVerified: boolean } } | null } | null> } } | null } | null> } | null };

export type GetEmbeddedSecretForNewPaymentInstructionMutationVariables = Exact<{
  instructionType?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetEmbeddedSecretForNewPaymentInstructionMutation = { __typename?: 'Mutation', getEmbeddedSecretForNewPaymentInstruction?: { __typename?: 'GetEmbeddedSecretForNewPaymentInstruction', secretKey?: string | null } | null };

export type GetStripePublicKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type GetStripePublicKeyMutation = { __typename?: 'Mutation', getStripePublicKey?: { __typename?: 'GetStripePublicKey', key: string } | null };

export type QuickAuthMutationVariables = Exact<{
  shortLivedToken: Scalars['String']['input'];
  user: Scalars['Int']['input'];
}>;


export type QuickAuthMutation = { __typename?: 'Mutation', quickAuth?: { __typename?: 'QuickAuthenticate', token: string, refreshToken: string, refreshExpiresIn: number, payload: any } | null };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'Refresh', token: string, refreshToken: string, refreshExpiresIn: number } | null };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ResetPassword', payload: any, refreshExpiresIn: number, token: string, refreshToken: string } | null };

export type VehicleMakesQueryVariables = Exact<{ [key: string]: never; }>;


export type VehicleMakesQuery = { __typename?: 'Query', vehicleMakes?: Array<string | null> | null };

export type VehicleModelVersionsQueryVariables = Exact<{
  vehicleMake: Scalars['String']['input'];
  vehicleModel: Scalars['String']['input'];
}>;


export type VehicleModelVersionsQuery = { __typename?: 'Query', vehicleModelVersions?: Array<string | null> | null };

export type VehicleModelsQueryVariables = Exact<{
  vehicleMake: Scalars['String']['input'];
}>;


export type VehicleModelsQuery = { __typename?: 'Query', vehicleModels?: Array<string | null> | null };

export type VehiclesByMakeModelVersionQueryVariables = Exact<{
  vehicleMake: Scalars['String']['input'];
  vehicleModel: Scalars['String']['input'];
  vehicleModelVersion?: InputMaybe<Scalars['String']['input']>;
}>;


export type VehiclesByMakeModelVersionQuery = { __typename?: 'Query', vehicles?: { __typename?: 'VehicleTypeConnection', totalCount: number, edges: Array<{ __typename?: 'VehicleTypeEdge', node?: { __typename?: 'VehicleType', id: string, pk?: number | null, availabilityDateFrom?: string | null, availabilityDateTo?: string | null, vehicleMake: string, vehicleModelVersion?: string | null, vehicleModel: string, octoEvDbId: number, rangeReal: number, batteryCapacityFull: number, batteryCapacityUseable: number, chargePlug: string, fastchargePlug?: string | null, isHybrid: boolean, chargeStandardPower: number, efficiencyReal: number } | null } | null> } | null };

export type VerifyTokenMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type VerifyTokenMutation = { __typename?: 'Mutation', verifyToken?: { __typename?: 'Verify', payload: any } | null };


export const CountriesDocument = gql`
    query countries($name: String) {
  countries(name: $name) {
    edges {
      node {
        alpha2
        alpha3
        id
        isSignupSupported
        isValidDeliveryLocation
        name
        numeric
        officialName
        pk
      }
    }
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const ExtendedVehicleLookupDocument = gql`
    query extendedVehicleLookup($regNumber: String!, $useHeuristics: Boolean) {
  extendedVehicleLookup(regNumber: $regNumber, useHeuristics: $useHeuristics) {
    isElectric
    model
    make
    confidence
    options {
      id
      pk
      availabilityDateFrom
      availabilityDateTo
      vehicleMake
      vehicleModelVersion
      vehicleModel
      octoEvDbId
      rangeReal
      batteryCapacityFull
      batteryCapacityUseable
      chargePlug
      fastchargePlug
    }
  }
}
    `;

/**
 * __useExtendedVehicleLookupQuery__
 *
 * To run a query within a React component, call `useExtendedVehicleLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtendedVehicleLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtendedVehicleLookupQuery({
 *   variables: {
 *      regNumber: // value for 'regNumber'
 *      useHeuristics: // value for 'useHeuristics'
 *   },
 * });
 */
export function useExtendedVehicleLookupQuery(baseOptions: Apollo.QueryHookOptions<ExtendedVehicleLookupQuery, ExtendedVehicleLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExtendedVehicleLookupQuery, ExtendedVehicleLookupQueryVariables>(ExtendedVehicleLookupDocument, options);
      }
export function useExtendedVehicleLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExtendedVehicleLookupQuery, ExtendedVehicleLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExtendedVehicleLookupQuery, ExtendedVehicleLookupQueryVariables>(ExtendedVehicleLookupDocument, options);
        }
export type ExtendedVehicleLookupQueryHookResult = ReturnType<typeof useExtendedVehicleLookupQuery>;
export type ExtendedVehicleLookupLazyQueryHookResult = ReturnType<typeof useExtendedVehicleLookupLazyQuery>;
export type ExtendedVehicleLookupQueryResult = Apollo.QueryResult<ExtendedVehicleLookupQuery, ExtendedVehicleLookupQueryVariables>;
export const FleetsAddTagDocument = gql`
    mutation fleetsAddTag($colour: String!, $name: String!) {
  fleetsAddTag(colour: $colour, name: $name) {
    success
  }
}
    `;
export type FleetsAddTagMutationFn = Apollo.MutationFunction<FleetsAddTagMutation, FleetsAddTagMutationVariables>;

/**
 * __useFleetsAddTagMutation__
 *
 * To run a mutation, you first call `useFleetsAddTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsAddTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsAddTagMutation, { data, loading, error }] = useFleetsAddTagMutation({
 *   variables: {
 *      colour: // value for 'colour'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFleetsAddTagMutation(baseOptions?: Apollo.MutationHookOptions<FleetsAddTagMutation, FleetsAddTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsAddTagMutation, FleetsAddTagMutationVariables>(FleetsAddTagDocument, options);
      }
export type FleetsAddTagMutationHookResult = ReturnType<typeof useFleetsAddTagMutation>;
export type FleetsAddTagMutationResult = Apollo.MutationResult<FleetsAddTagMutation>;
export type FleetsAddTagMutationOptions = Apollo.BaseMutationOptions<FleetsAddTagMutation, FleetsAddTagMutationVariables>;
export const FleetsAddVehicleDocument = gql`
    mutation fleetsAddVehicle($vehicle: Int!, $reg: String!, $name: String!) {
  fleetsAddVehicle(vehicle: $vehicle, reg: $reg, name: $name) {
    success
  }
}
    `;
export type FleetsAddVehicleMutationFn = Apollo.MutationFunction<FleetsAddVehicleMutation, FleetsAddVehicleMutationVariables>;

/**
 * __useFleetsAddVehicleMutation__
 *
 * To run a mutation, you first call `useFleetsAddVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsAddVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsAddVehicleMutation, { data, loading, error }] = useFleetsAddVehicleMutation({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *      reg: // value for 'reg'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFleetsAddVehicleMutation(baseOptions?: Apollo.MutationHookOptions<FleetsAddVehicleMutation, FleetsAddVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsAddVehicleMutation, FleetsAddVehicleMutationVariables>(FleetsAddVehicleDocument, options);
      }
export type FleetsAddVehicleMutationHookResult = ReturnType<typeof useFleetsAddVehicleMutation>;
export type FleetsAddVehicleMutationResult = Apollo.MutationResult<FleetsAddVehicleMutation>;
export type FleetsAddVehicleMutationOptions = Apollo.BaseMutationOptions<FleetsAddVehicleMutation, FleetsAddVehicleMutationVariables>;
export const FleetsAdminLogInDocument = gql`
    mutation fleetsAdminLogIn($email: String!, $password: String!) {
  fleetsAdminLogIn(email: $email, password: $password) {
    token
    refreshToken
    refreshExpiresIn
  }
}
    `;
export type FleetsAdminLogInMutationFn = Apollo.MutationFunction<FleetsAdminLogInMutation, FleetsAdminLogInMutationVariables>;

/**
 * __useFleetsAdminLogInMutation__
 *
 * To run a mutation, you first call `useFleetsAdminLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsAdminLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsAdminLogInMutation, { data, loading, error }] = useFleetsAdminLogInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useFleetsAdminLogInMutation(baseOptions?: Apollo.MutationHookOptions<FleetsAdminLogInMutation, FleetsAdminLogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsAdminLogInMutation, FleetsAdminLogInMutationVariables>(FleetsAdminLogInDocument, options);
      }
export type FleetsAdminLogInMutationHookResult = ReturnType<typeof useFleetsAdminLogInMutation>;
export type FleetsAdminLogInMutationResult = Apollo.MutationResult<FleetsAdminLogInMutation>;
export type FleetsAdminLogInMutationOptions = Apollo.BaseMutationOptions<FleetsAdminLogInMutation, FleetsAdminLogInMutationVariables>;
export const FleetsAdminSendResetPasswordEmailDocument = gql`
    mutation fleetsAdminSendResetPasswordEmail($email: String!) {
  fleetsAdminSendResetPasswordEmail(email: $email) {
    success
  }
}
    `;
export type FleetsAdminSendResetPasswordEmailMutationFn = Apollo.MutationFunction<FleetsAdminSendResetPasswordEmailMutation, FleetsAdminSendResetPasswordEmailMutationVariables>;

/**
 * __useFleetsAdminSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useFleetsAdminSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsAdminSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsAdminSendResetPasswordEmailMutation, { data, loading, error }] = useFleetsAdminSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFleetsAdminSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<FleetsAdminSendResetPasswordEmailMutation, FleetsAdminSendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsAdminSendResetPasswordEmailMutation, FleetsAdminSendResetPasswordEmailMutationVariables>(FleetsAdminSendResetPasswordEmailDocument, options);
      }
export type FleetsAdminSendResetPasswordEmailMutationHookResult = ReturnType<typeof useFleetsAdminSendResetPasswordEmailMutation>;
export type FleetsAdminSendResetPasswordEmailMutationResult = Apollo.MutationResult<FleetsAdminSendResetPasswordEmailMutation>;
export type FleetsAdminSendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<FleetsAdminSendResetPasswordEmailMutation, FleetsAdminSendResetPasswordEmailMutationVariables>;
export const FleetsAdminSetPasswordDocument = gql`
    mutation fleetsAdminSetPassword($newPassword: String!, $singleTimeToken: String!) {
  fleetsAdminSetPassword(
    newPassword: $newPassword
    singleTimeToken: $singleTimeToken
  ) {
    success
  }
}
    `;
export type FleetsAdminSetPasswordMutationFn = Apollo.MutationFunction<FleetsAdminSetPasswordMutation, FleetsAdminSetPasswordMutationVariables>;

/**
 * __useFleetsAdminSetPasswordMutation__
 *
 * To run a mutation, you first call `useFleetsAdminSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsAdminSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsAdminSetPasswordMutation, { data, loading, error }] = useFleetsAdminSetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      singleTimeToken: // value for 'singleTimeToken'
 *   },
 * });
 */
export function useFleetsAdminSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<FleetsAdminSetPasswordMutation, FleetsAdminSetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsAdminSetPasswordMutation, FleetsAdminSetPasswordMutationVariables>(FleetsAdminSetPasswordDocument, options);
      }
export type FleetsAdminSetPasswordMutationHookResult = ReturnType<typeof useFleetsAdminSetPasswordMutation>;
export type FleetsAdminSetPasswordMutationResult = Apollo.MutationResult<FleetsAdminSetPasswordMutation>;
export type FleetsAdminSetPasswordMutationOptions = Apollo.BaseMutationOptions<FleetsAdminSetPasswordMutation, FleetsAdminSetPasswordMutationVariables>;
export const FleetsCurrentAdminUserDocument = gql`
    query fleetsCurrentAdminUser {
  fleetsCurrentAdmin {
    isPrimaryAdmin
    isSuperuser
    hasSignedUp
    user {
      id
      email
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useFleetsCurrentAdminUserQuery__
 *
 * To run a query within a React component, call `useFleetsCurrentAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsCurrentAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsCurrentAdminUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsCurrentAdminUserQuery(baseOptions?: Apollo.QueryHookOptions<FleetsCurrentAdminUserQuery, FleetsCurrentAdminUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsCurrentAdminUserQuery, FleetsCurrentAdminUserQueryVariables>(FleetsCurrentAdminUserDocument, options);
      }
export function useFleetsCurrentAdminUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsCurrentAdminUserQuery, FleetsCurrentAdminUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsCurrentAdminUserQuery, FleetsCurrentAdminUserQueryVariables>(FleetsCurrentAdminUserDocument, options);
        }
export type FleetsCurrentAdminUserQueryHookResult = ReturnType<typeof useFleetsCurrentAdminUserQuery>;
export type FleetsCurrentAdminUserLazyQueryHookResult = ReturnType<typeof useFleetsCurrentAdminUserLazyQuery>;
export type FleetsCurrentAdminUserQueryResult = Apollo.QueryResult<FleetsCurrentAdminUserQuery, FleetsCurrentAdminUserQueryVariables>;
export const FleetsAdminUsersDocument = gql`
    query fleetsAdminUsers($offset: Int, $first: Int!, $ordering: [AdminTypeOrdering]) {
  fleetsCurrentAdmin {
    isPrimaryAdmin
    isSuperuser
    hasSignedUp
    user {
      id
      email
      firstName
      lastName
    }
  }
  fleetsAdmins(offset: $offset, first: $first, ordering: $ordering) {
    totalCount
    edgeCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        pk
        createdAt
        user {
          id
          email
          isActive
        }
        isSuperuser
        hasSignedUp
        hasBillingPermission
        hasActiveAccount
      }
    }
  }
}
    `;

/**
 * __useFleetsAdminUsersQuery__
 *
 * To run a query within a React component, call `useFleetsAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsAdminUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useFleetsAdminUsersQuery(baseOptions: Apollo.QueryHookOptions<FleetsAdminUsersQuery, FleetsAdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsAdminUsersQuery, FleetsAdminUsersQueryVariables>(FleetsAdminUsersDocument, options);
      }
export function useFleetsAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsAdminUsersQuery, FleetsAdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsAdminUsersQuery, FleetsAdminUsersQueryVariables>(FleetsAdminUsersDocument, options);
        }
export type FleetsAdminUsersQueryHookResult = ReturnType<typeof useFleetsAdminUsersQuery>;
export type FleetsAdminUsersLazyQueryHookResult = ReturnType<typeof useFleetsAdminUsersLazyQuery>;
export type FleetsAdminUsersQueryResult = Apollo.QueryResult<FleetsAdminUsersQuery, FleetsAdminUsersQueryVariables>;
export const FleetsAssignCardToDriverDocument = gql`
    mutation fleetsAssignCardToDriver($hexCode: String!, $driver: Int!) {
  fleetsAssignCardToDriver(hexCode: $hexCode, driver: $driver) {
    success
  }
}
    `;
export type FleetsAssignCardToDriverMutationFn = Apollo.MutationFunction<FleetsAssignCardToDriverMutation, FleetsAssignCardToDriverMutationVariables>;

/**
 * __useFleetsAssignCardToDriverMutation__
 *
 * To run a mutation, you first call `useFleetsAssignCardToDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsAssignCardToDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsAssignCardToDriverMutation, { data, loading, error }] = useFleetsAssignCardToDriverMutation({
 *   variables: {
 *      hexCode: // value for 'hexCode'
 *      driver: // value for 'driver'
 *   },
 * });
 */
export function useFleetsAssignCardToDriverMutation(baseOptions?: Apollo.MutationHookOptions<FleetsAssignCardToDriverMutation, FleetsAssignCardToDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsAssignCardToDriverMutation, FleetsAssignCardToDriverMutationVariables>(FleetsAssignCardToDriverDocument, options);
      }
export type FleetsAssignCardToDriverMutationHookResult = ReturnType<typeof useFleetsAssignCardToDriverMutation>;
export type FleetsAssignCardToDriverMutationResult = Apollo.MutationResult<FleetsAssignCardToDriverMutation>;
export type FleetsAssignCardToDriverMutationOptions = Apollo.BaseMutationOptions<FleetsAssignCardToDriverMutation, FleetsAssignCardToDriverMutationVariables>;
export const FleetsAssignVehicleDocument = gql`
    mutation fleetsAssignVehicle($driver: Int!, $fleetVehicle: Int!) {
  fleetsAssignVehicle(driver: $driver, fleetVehicle: $fleetVehicle) {
    success
  }
}
    `;
export type FleetsAssignVehicleMutationFn = Apollo.MutationFunction<FleetsAssignVehicleMutation, FleetsAssignVehicleMutationVariables>;

/**
 * __useFleetsAssignVehicleMutation__
 *
 * To run a mutation, you first call `useFleetsAssignVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsAssignVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsAssignVehicleMutation, { data, loading, error }] = useFleetsAssignVehicleMutation({
 *   variables: {
 *      driver: // value for 'driver'
 *      fleetVehicle: // value for 'fleetVehicle'
 *   },
 * });
 */
export function useFleetsAssignVehicleMutation(baseOptions?: Apollo.MutationHookOptions<FleetsAssignVehicleMutation, FleetsAssignVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsAssignVehicleMutation, FleetsAssignVehicleMutationVariables>(FleetsAssignVehicleDocument, options);
      }
export type FleetsAssignVehicleMutationHookResult = ReturnType<typeof useFleetsAssignVehicleMutation>;
export type FleetsAssignVehicleMutationResult = Apollo.MutationResult<FleetsAssignVehicleMutation>;
export type FleetsAssignVehicleMutationOptions = Apollo.BaseMutationOptions<FleetsAssignVehicleMutation, FleetsAssignVehicleMutationVariables>;
export const FleetsBulkCardOrderDocument = gql`
    mutation fleetsBulkCardOrder($address1: String!, $address2: String = null, $address3: String = null, $address4: String = null, $country: String!, $postcode: String!, $quantity: Int!) {
  fleetsBulkCardOrder(
    address1: $address1
    address2: $address2
    address3: $address3
    address4: $address4
    country: $country
    postcode: $postcode
    quantity: $quantity
  ) {
    success
  }
}
    `;
export type FleetsBulkCardOrderMutationFn = Apollo.MutationFunction<FleetsBulkCardOrderMutation, FleetsBulkCardOrderMutationVariables>;

/**
 * __useFleetsBulkCardOrderMutation__
 *
 * To run a mutation, you first call `useFleetsBulkCardOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsBulkCardOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsBulkCardOrderMutation, { data, loading, error }] = useFleetsBulkCardOrderMutation({
 *   variables: {
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      address3: // value for 'address3'
 *      address4: // value for 'address4'
 *      country: // value for 'country'
 *      postcode: // value for 'postcode'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useFleetsBulkCardOrderMutation(baseOptions?: Apollo.MutationHookOptions<FleetsBulkCardOrderMutation, FleetsBulkCardOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsBulkCardOrderMutation, FleetsBulkCardOrderMutationVariables>(FleetsBulkCardOrderDocument, options);
      }
export type FleetsBulkCardOrderMutationHookResult = ReturnType<typeof useFleetsBulkCardOrderMutation>;
export type FleetsBulkCardOrderMutationResult = Apollo.MutationResult<FleetsBulkCardOrderMutation>;
export type FleetsBulkCardOrderMutationOptions = Apollo.BaseMutationOptions<FleetsBulkCardOrderMutation, FleetsBulkCardOrderMutationVariables>;
export const FleetsBulkCreateDriversDocument = gql`
    mutation fleetsBulkCreateDrivers($file: FileUpload!) {
  fleetsBulkCreateDrivers(file: $file) {
    success
  }
}
    `;
export type FleetsBulkCreateDriversMutationFn = Apollo.MutationFunction<FleetsBulkCreateDriversMutation, FleetsBulkCreateDriversMutationVariables>;

/**
 * __useFleetsBulkCreateDriversMutation__
 *
 * To run a mutation, you first call `useFleetsBulkCreateDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsBulkCreateDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsBulkCreateDriversMutation, { data, loading, error }] = useFleetsBulkCreateDriversMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useFleetsBulkCreateDriversMutation(baseOptions?: Apollo.MutationHookOptions<FleetsBulkCreateDriversMutation, FleetsBulkCreateDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsBulkCreateDriversMutation, FleetsBulkCreateDriversMutationVariables>(FleetsBulkCreateDriversDocument, options);
      }
export type FleetsBulkCreateDriversMutationHookResult = ReturnType<typeof useFleetsBulkCreateDriversMutation>;
export type FleetsBulkCreateDriversMutationResult = Apollo.MutationResult<FleetsBulkCreateDriversMutation>;
export type FleetsBulkCreateDriversMutationOptions = Apollo.BaseMutationOptions<FleetsBulkCreateDriversMutation, FleetsBulkCreateDriversMutationVariables>;
export const FleetsBusinessEntityDocument = gql`
    query fleetsBusinessEntity {
  fleetsBusinessEntity {
    pk
    name
    registeredAt
    countryCode
    isVerified
    deliveryAddressLine1
    deliveryAddressLine2
    deliveryAddressLine3
    deliveryAddressLine4
    deliveryPostcode
    deliveryCountry
    logo
    companyHouseNumber
    vatNumber
    charityNumber
    companyType
    hasPaymentMethod
    hasCharged
    referralCode
    billingAccount {
      email
      name
      billingDetails {
        billingAddressLine1
        billingAddressLine2
        concatenatedAddressLines345
        billingAddressPostcode
        billingCountryCode
      }
      paymentMethod {
        pk
        provider
        paymentMethodType
        isPreferred
        paymentMethodDetails {
          ... on DirectDebitType {
            identifier
            sortCode
            accountHolder
          }
          ... on PaymentCardType {
            identifier
            paymentNetwork
            expiryYear
            expiryMonth
          }
          ... on GenericPaymentMethodDetailsType {
            identifier
            stripePaymentMethodType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFleetsBusinessEntityQuery__
 *
 * To run a query within a React component, call `useFleetsBusinessEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsBusinessEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsBusinessEntityQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsBusinessEntityQuery(baseOptions?: Apollo.QueryHookOptions<FleetsBusinessEntityQuery, FleetsBusinessEntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsBusinessEntityQuery, FleetsBusinessEntityQueryVariables>(FleetsBusinessEntityDocument, options);
      }
export function useFleetsBusinessEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsBusinessEntityQuery, FleetsBusinessEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsBusinessEntityQuery, FleetsBusinessEntityQueryVariables>(FleetsBusinessEntityDocument, options);
        }
export type FleetsBusinessEntityQueryHookResult = ReturnType<typeof useFleetsBusinessEntityQuery>;
export type FleetsBusinessEntityLazyQueryHookResult = ReturnType<typeof useFleetsBusinessEntityLazyQuery>;
export type FleetsBusinessEntityQueryResult = Apollo.QueryResult<FleetsBusinessEntityQuery, FleetsBusinessEntityQueryVariables>;
export const FleetsBusinessEntityReceiptsAggregateDocument = gql`
    query fleetsBusinessEntityReceiptsAggregate($startDate: Date!, $endDate: Date!) {
  fleetsBusinessEntityReceiptsAggregate(startDate: $startDate, endDate: $endDate) {
    edges {
      node {
        totalCost
        totalConsumption
        numberOfReceipts
      }
    }
  }
}
    `;

/**
 * __useFleetsBusinessEntityReceiptsAggregateQuery__
 *
 * To run a query within a React component, call `useFleetsBusinessEntityReceiptsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsBusinessEntityReceiptsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsBusinessEntityReceiptsAggregateQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useFleetsBusinessEntityReceiptsAggregateQuery(baseOptions: Apollo.QueryHookOptions<FleetsBusinessEntityReceiptsAggregateQuery, FleetsBusinessEntityReceiptsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsBusinessEntityReceiptsAggregateQuery, FleetsBusinessEntityReceiptsAggregateQueryVariables>(FleetsBusinessEntityReceiptsAggregateDocument, options);
      }
export function useFleetsBusinessEntityReceiptsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsBusinessEntityReceiptsAggregateQuery, FleetsBusinessEntityReceiptsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsBusinessEntityReceiptsAggregateQuery, FleetsBusinessEntityReceiptsAggregateQueryVariables>(FleetsBusinessEntityReceiptsAggregateDocument, options);
        }
export type FleetsBusinessEntityReceiptsAggregateQueryHookResult = ReturnType<typeof useFleetsBusinessEntityReceiptsAggregateQuery>;
export type FleetsBusinessEntityReceiptsAggregateLazyQueryHookResult = ReturnType<typeof useFleetsBusinessEntityReceiptsAggregateLazyQuery>;
export type FleetsBusinessEntityReceiptsAggregateQueryResult = Apollo.QueryResult<FleetsBusinessEntityReceiptsAggregateQuery, FleetsBusinessEntityReceiptsAggregateQueryVariables>;
export const FleetsCardOrdersDocument = gql`
    query fleetsCardOrders {
  fleetsCardOrders {
    edges {
      node {
        id
        pk
        status
      }
    }
  }
}
    `;

/**
 * __useFleetsCardOrdersQuery__
 *
 * To run a query within a React component, call `useFleetsCardOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsCardOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsCardOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsCardOrdersQuery(baseOptions?: Apollo.QueryHookOptions<FleetsCardOrdersQuery, FleetsCardOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsCardOrdersQuery, FleetsCardOrdersQueryVariables>(FleetsCardOrdersDocument, options);
      }
export function useFleetsCardOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsCardOrdersQuery, FleetsCardOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsCardOrdersQuery, FleetsCardOrdersQueryVariables>(FleetsCardOrdersDocument, options);
        }
export type FleetsCardOrdersQueryHookResult = ReturnType<typeof useFleetsCardOrdersQuery>;
export type FleetsCardOrdersLazyQueryHookResult = ReturnType<typeof useFleetsCardOrdersLazyQuery>;
export type FleetsCardOrdersQueryResult = Apollo.QueryResult<FleetsCardOrdersQuery, FleetsCardOrdersQueryVariables>;
export const FleetsChargeRecordsDocument = gql`
    query fleetsChargeRecords {
  fleetsChargeRecords(ordering: {orderBy: PK, direction: ASC}) {
    edges {
      cursor
      node {
        pk
        id
        totalCost
        totalConsumption
        numberOfReceipts
        startDate
        endDate
        downloadLink
      }
    }
  }
}
    `;

/**
 * __useFleetsChargeRecordsQuery__
 *
 * To run a query within a React component, call `useFleetsChargeRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsChargeRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsChargeRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsChargeRecordsQuery(baseOptions?: Apollo.QueryHookOptions<FleetsChargeRecordsQuery, FleetsChargeRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsChargeRecordsQuery, FleetsChargeRecordsQueryVariables>(FleetsChargeRecordsDocument, options);
      }
export function useFleetsChargeRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsChargeRecordsQuery, FleetsChargeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsChargeRecordsQuery, FleetsChargeRecordsQueryVariables>(FleetsChargeRecordsDocument, options);
        }
export type FleetsChargeRecordsQueryHookResult = ReturnType<typeof useFleetsChargeRecordsQuery>;
export type FleetsChargeRecordsLazyQueryHookResult = ReturnType<typeof useFleetsChargeRecordsLazyQuery>;
export type FleetsChargeRecordsQueryResult = Apollo.QueryResult<FleetsChargeRecordsQuery, FleetsChargeRecordsQueryVariables>;
export const FleetsCompleteFleetSignupDocument = gql`
    mutation fleetsCompleteFleetSignup($companyHouseNumber: String!, $companyName: String!, $firstName: String!, $lastName: String!, $password: String = null, $signupToken: String!, $companyType: String = null, $charityNumber: String = null, $vatNumber: String = null, $website: String = null, $driverEstimate: Int = null, $homeChargingEstimate: Decimal = null, $howHeard: String = null, $publicChargingEstimate: Decimal = null, $sector: String = null, $workplaceChargingEstimate: Decimal = null) {
  fleetsCompleteFleetSignup(
    companyHouseNumber: $companyHouseNumber
    companyName: $companyName
    firstName: $firstName
    lastName: $lastName
    password: $password
    signupToken: $signupToken
    companyType: $companyType
    charityNumber: $charityNumber
    vatNumber: $vatNumber
    website: $website
    driverEstimate: $driverEstimate
    homeChargingEstimate: $homeChargingEstimate
    howHeard: $howHeard
    publicChargingEstimate: $publicChargingEstimate
    sector: $sector
    workplaceChargingEstimate: $workplaceChargingEstimate
  ) {
    payload
    refreshExpiresIn
    success
    token
    refreshToken
  }
}
    `;
export type FleetsCompleteFleetSignupMutationFn = Apollo.MutationFunction<FleetsCompleteFleetSignupMutation, FleetsCompleteFleetSignupMutationVariables>;

/**
 * __useFleetsCompleteFleetSignupMutation__
 *
 * To run a mutation, you first call `useFleetsCompleteFleetSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsCompleteFleetSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsCompleteFleetSignupMutation, { data, loading, error }] = useFleetsCompleteFleetSignupMutation({
 *   variables: {
 *      companyHouseNumber: // value for 'companyHouseNumber'
 *      companyName: // value for 'companyName'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      signupToken: // value for 'signupToken'
 *      companyType: // value for 'companyType'
 *      charityNumber: // value for 'charityNumber'
 *      vatNumber: // value for 'vatNumber'
 *      website: // value for 'website'
 *      driverEstimate: // value for 'driverEstimate'
 *      homeChargingEstimate: // value for 'homeChargingEstimate'
 *      howHeard: // value for 'howHeard'
 *      publicChargingEstimate: // value for 'publicChargingEstimate'
 *      sector: // value for 'sector'
 *      workplaceChargingEstimate: // value for 'workplaceChargingEstimate'
 *   },
 * });
 */
export function useFleetsCompleteFleetSignupMutation(baseOptions?: Apollo.MutationHookOptions<FleetsCompleteFleetSignupMutation, FleetsCompleteFleetSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsCompleteFleetSignupMutation, FleetsCompleteFleetSignupMutationVariables>(FleetsCompleteFleetSignupDocument, options);
      }
export type FleetsCompleteFleetSignupMutationHookResult = ReturnType<typeof useFleetsCompleteFleetSignupMutation>;
export type FleetsCompleteFleetSignupMutationResult = Apollo.MutationResult<FleetsCompleteFleetSignupMutation>;
export type FleetsCompleteFleetSignupMutationOptions = Apollo.BaseMutationOptions<FleetsCompleteFleetSignupMutation, FleetsCompleteFleetSignupMutationVariables>;
export const FleetsCreateAdminDocument = gql`
    mutation fleetsCreateAdmin($email: String!, $isSuperuser: Boolean!) {
  fleetsCreateAdmin(email: $email, isSuperuser: $isSuperuser) {
    success
  }
}
    `;
export type FleetsCreateAdminMutationFn = Apollo.MutationFunction<FleetsCreateAdminMutation, FleetsCreateAdminMutationVariables>;

/**
 * __useFleetsCreateAdminMutation__
 *
 * To run a mutation, you first call `useFleetsCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsCreateAdminMutation, { data, loading, error }] = useFleetsCreateAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isSuperuser: // value for 'isSuperuser'
 *   },
 * });
 */
export function useFleetsCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<FleetsCreateAdminMutation, FleetsCreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsCreateAdminMutation, FleetsCreateAdminMutationVariables>(FleetsCreateAdminDocument, options);
      }
export type FleetsCreateAdminMutationHookResult = ReturnType<typeof useFleetsCreateAdminMutation>;
export type FleetsCreateAdminMutationResult = Apollo.MutationResult<FleetsCreateAdminMutation>;
export type FleetsCreateAdminMutationOptions = Apollo.BaseMutationOptions<FleetsCreateAdminMutation, FleetsCreateAdminMutationVariables>;
export const FleetsCreateBusinessEntityDocument = gql`
    mutation fleetsCreateBusinessEntity($adminEmail: String!, $adminFamilyName: String!, $adminFirstName: String!, $adminPassword: String!, $companyName: String!) {
  fleetsCreateBusinessEntity(
    adminEmail: $adminEmail
    adminFamilyName: $adminFamilyName
    adminFirstName: $adminFirstName
    adminPassword: $adminPassword
    companyName: $companyName
  ) {
    success
  }
}
    `;
export type FleetsCreateBusinessEntityMutationFn = Apollo.MutationFunction<FleetsCreateBusinessEntityMutation, FleetsCreateBusinessEntityMutationVariables>;

/**
 * __useFleetsCreateBusinessEntityMutation__
 *
 * To run a mutation, you first call `useFleetsCreateBusinessEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsCreateBusinessEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsCreateBusinessEntityMutation, { data, loading, error }] = useFleetsCreateBusinessEntityMutation({
 *   variables: {
 *      adminEmail: // value for 'adminEmail'
 *      adminFamilyName: // value for 'adminFamilyName'
 *      adminFirstName: // value for 'adminFirstName'
 *      adminPassword: // value for 'adminPassword'
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useFleetsCreateBusinessEntityMutation(baseOptions?: Apollo.MutationHookOptions<FleetsCreateBusinessEntityMutation, FleetsCreateBusinessEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsCreateBusinessEntityMutation, FleetsCreateBusinessEntityMutationVariables>(FleetsCreateBusinessEntityDocument, options);
      }
export type FleetsCreateBusinessEntityMutationHookResult = ReturnType<typeof useFleetsCreateBusinessEntityMutation>;
export type FleetsCreateBusinessEntityMutationResult = Apollo.MutationResult<FleetsCreateBusinessEntityMutation>;
export type FleetsCreateBusinessEntityMutationOptions = Apollo.BaseMutationOptions<FleetsCreateBusinessEntityMutation, FleetsCreateBusinessEntityMutationVariables>;
export const FleetsCreateDriverDocument = gql`
    mutation fleetsCreateDriver($email: String!, $familyName: String!, $givenName: String!, $mobile: String, $preferredNameOnCard: String, $tags: [Int]) {
  fleetsCreateDriver(
    email: $email
    familyName: $familyName
    givenName: $givenName
    mobile: $mobile
    preferredNameOnCard: $preferredNameOnCard
    tags: $tags
  ) {
    success
  }
}
    `;
export type FleetsCreateDriverMutationFn = Apollo.MutationFunction<FleetsCreateDriverMutation, FleetsCreateDriverMutationVariables>;

/**
 * __useFleetsCreateDriverMutation__
 *
 * To run a mutation, you first call `useFleetsCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsCreateDriverMutation, { data, loading, error }] = useFleetsCreateDriverMutation({
 *   variables: {
 *      email: // value for 'email'
 *      familyName: // value for 'familyName'
 *      givenName: // value for 'givenName'
 *      mobile: // value for 'mobile'
 *      preferredNameOnCard: // value for 'preferredNameOnCard'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useFleetsCreateDriverMutation(baseOptions?: Apollo.MutationHookOptions<FleetsCreateDriverMutation, FleetsCreateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsCreateDriverMutation, FleetsCreateDriverMutationVariables>(FleetsCreateDriverDocument, options);
      }
export type FleetsCreateDriverMutationHookResult = ReturnType<typeof useFleetsCreateDriverMutation>;
export type FleetsCreateDriverMutationResult = Apollo.MutationResult<FleetsCreateDriverMutation>;
export type FleetsCreateDriverMutationOptions = Apollo.BaseMutationOptions<FleetsCreateDriverMutation, FleetsCreateDriverMutationVariables>;
export const FleetsCreditHistoryDocument = gql`
    query fleetsCreditHistory {
  fleetsCreditHistory {
    amount
    currency
    createdAt
  }
}
    `;

/**
 * __useFleetsCreditHistoryQuery__
 *
 * To run a query within a React component, call `useFleetsCreditHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsCreditHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsCreditHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsCreditHistoryQuery(baseOptions?: Apollo.QueryHookOptions<FleetsCreditHistoryQuery, FleetsCreditHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsCreditHistoryQuery, FleetsCreditHistoryQueryVariables>(FleetsCreditHistoryDocument, options);
      }
export function useFleetsCreditHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsCreditHistoryQuery, FleetsCreditHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsCreditHistoryQuery, FleetsCreditHistoryQueryVariables>(FleetsCreditHistoryDocument, options);
        }
export type FleetsCreditHistoryQueryHookResult = ReturnType<typeof useFleetsCreditHistoryQuery>;
export type FleetsCreditHistoryLazyQueryHookResult = ReturnType<typeof useFleetsCreditHistoryLazyQuery>;
export type FleetsCreditHistoryQueryResult = Apollo.QueryResult<FleetsCreditHistoryQuery, FleetsCreditHistoryQueryVariables>;
export const FleetsCurrentAdminAccountsDocument = gql`
    query fleetsCurrentAdminAccounts {
  fleetsCurrentAdminAccounts {
    id
    entityName
    entityDomain
    isDefault
    isFleetDriver
    isFleetAdmin
  }
}
    `;

/**
 * __useFleetsCurrentAdminAccountsQuery__
 *
 * To run a query within a React component, call `useFleetsCurrentAdminAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsCurrentAdminAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsCurrentAdminAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsCurrentAdminAccountsQuery(baseOptions?: Apollo.QueryHookOptions<FleetsCurrentAdminAccountsQuery, FleetsCurrentAdminAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsCurrentAdminAccountsQuery, FleetsCurrentAdminAccountsQueryVariables>(FleetsCurrentAdminAccountsDocument, options);
      }
export function useFleetsCurrentAdminAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsCurrentAdminAccountsQuery, FleetsCurrentAdminAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsCurrentAdminAccountsQuery, FleetsCurrentAdminAccountsQueryVariables>(FleetsCurrentAdminAccountsDocument, options);
        }
export type FleetsCurrentAdminAccountsQueryHookResult = ReturnType<typeof useFleetsCurrentAdminAccountsQuery>;
export type FleetsCurrentAdminAccountsLazyQueryHookResult = ReturnType<typeof useFleetsCurrentAdminAccountsLazyQuery>;
export type FleetsCurrentAdminAccountsQueryResult = Apollo.QueryResult<FleetsCurrentAdminAccountsQuery, FleetsCurrentAdminAccountsQueryVariables>;
export const FleetsCurrentBalanceDocument = gql`
    query fleetsCurrentBalance {
  fleetsCurrentBalance {
    amount
    currency
  }
}
    `;

/**
 * __useFleetsCurrentBalanceQuery__
 *
 * To run a query within a React component, call `useFleetsCurrentBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsCurrentBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsCurrentBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsCurrentBalanceQuery(baseOptions?: Apollo.QueryHookOptions<FleetsCurrentBalanceQuery, FleetsCurrentBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsCurrentBalanceQuery, FleetsCurrentBalanceQueryVariables>(FleetsCurrentBalanceDocument, options);
      }
export function useFleetsCurrentBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsCurrentBalanceQuery, FleetsCurrentBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsCurrentBalanceQuery, FleetsCurrentBalanceQueryVariables>(FleetsCurrentBalanceDocument, options);
        }
export type FleetsCurrentBalanceQueryHookResult = ReturnType<typeof useFleetsCurrentBalanceQuery>;
export type FleetsCurrentBalanceLazyQueryHookResult = ReturnType<typeof useFleetsCurrentBalanceLazyQuery>;
export type FleetsCurrentBalanceQueryResult = Apollo.QueryResult<FleetsCurrentBalanceQuery, FleetsCurrentBalanceQueryVariables>;
export const FleetsDeactivateDriversCardDocument = gql`
    mutation fleetsDeactivateDriversCard($card: Int!, $driver: Int!) {
  fleetsFreezeDriversCard(card: $card, driver: $driver) {
    success
  }
}
    `;
export type FleetsDeactivateDriversCardMutationFn = Apollo.MutationFunction<FleetsDeactivateDriversCardMutation, FleetsDeactivateDriversCardMutationVariables>;

/**
 * __useFleetsDeactivateDriversCardMutation__
 *
 * To run a mutation, you first call `useFleetsDeactivateDriversCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsDeactivateDriversCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsDeactivateDriversCardMutation, { data, loading, error }] = useFleetsDeactivateDriversCardMutation({
 *   variables: {
 *      card: // value for 'card'
 *      driver: // value for 'driver'
 *   },
 * });
 */
export function useFleetsDeactivateDriversCardMutation(baseOptions?: Apollo.MutationHookOptions<FleetsDeactivateDriversCardMutation, FleetsDeactivateDriversCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsDeactivateDriversCardMutation, FleetsDeactivateDriversCardMutationVariables>(FleetsDeactivateDriversCardDocument, options);
      }
export type FleetsDeactivateDriversCardMutationHookResult = ReturnType<typeof useFleetsDeactivateDriversCardMutation>;
export type FleetsDeactivateDriversCardMutationResult = Apollo.MutationResult<FleetsDeactivateDriversCardMutation>;
export type FleetsDeactivateDriversCardMutationOptions = Apollo.BaseMutationOptions<FleetsDeactivateDriversCardMutation, FleetsDeactivateDriversCardMutationVariables>;
export const FleetsDeleteTagDocument = gql`
    mutation fleetsDeleteTag($pk: Int!) {
  fleetsDeleteTag(pk: $pk) {
    success
  }
}
    `;
export type FleetsDeleteTagMutationFn = Apollo.MutationFunction<FleetsDeleteTagMutation, FleetsDeleteTagMutationVariables>;

/**
 * __useFleetsDeleteTagMutation__
 *
 * To run a mutation, you first call `useFleetsDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsDeleteTagMutation, { data, loading, error }] = useFleetsDeleteTagMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useFleetsDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<FleetsDeleteTagMutation, FleetsDeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsDeleteTagMutation, FleetsDeleteTagMutationVariables>(FleetsDeleteTagDocument, options);
      }
export type FleetsDeleteTagMutationHookResult = ReturnType<typeof useFleetsDeleteTagMutation>;
export type FleetsDeleteTagMutationResult = Apollo.MutationResult<FleetsDeleteTagMutation>;
export type FleetsDeleteTagMutationOptions = Apollo.BaseMutationOptions<FleetsDeleteTagMutation, FleetsDeleteTagMutationVariables>;
export const FleetsDriverDocument = gql`
    query fleetsDriver($pk: Int!) {
  fleetsDriver(pk: $pk) {
    pk
    createdAt
    mobileNumber
    hasSignedUp
    tags {
      pk
      id
      name
      colour
    }
    user {
      hasElectricJuiceCard
      hasAcceptedTermsAndConditions
      email
      lastName
      firstName
      preferredName
    }
    businessEntity {
      pk
      name
    }
  }
}
    `;

/**
 * __useFleetsDriverQuery__
 *
 * To run a query within a React component, call `useFleetsDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsDriverQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useFleetsDriverQuery(baseOptions: Apollo.QueryHookOptions<FleetsDriverQuery, FleetsDriverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsDriverQuery, FleetsDriverQueryVariables>(FleetsDriverDocument, options);
      }
export function useFleetsDriverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsDriverQuery, FleetsDriverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsDriverQuery, FleetsDriverQueryVariables>(FleetsDriverDocument, options);
        }
export type FleetsDriverQueryHookResult = ReturnType<typeof useFleetsDriverQuery>;
export type FleetsDriverLazyQueryHookResult = ReturnType<typeof useFleetsDriverLazyQuery>;
export type FleetsDriverQueryResult = Apollo.QueryResult<FleetsDriverQuery, FleetsDriverQueryVariables>;
export const FleetsDriversReceiptsDocument = gql`
    query fleetsDriversReceipts($startDate: Date!, $endDate: Date!, $drivers: [Int]!, $offset: Int!, $first: Int!, $ordering: [DriverReceiptsConnectionTypeOrdering]) {
  fleetsDriversReceipts(
    startDate: $startDate
    endDate: $endDate
    drivers: $drivers
    offset: $offset
    first: $first
    ordering: $ordering
  ) {
    totalCount
    edges {
      node {
        pk
        netAmount
        grossAmount
        additionalFees
        currency
        kwhUsed
        createdAt
        periodEnd
        periodStart
        duration
        chargingLocationId
        status
        invoiceNumber
        operator {
          id
          name
          logoDark
          pk
        }
        driver {
          id
          pk
          user {
            firstName
            lastName
          }
        }
        location {
          coordinates
          address
          pk
        }
      }
    }
  }
}
    `;

/**
 * __useFleetsDriversReceiptsQuery__
 *
 * To run a query within a React component, call `useFleetsDriversReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsDriversReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsDriversReceiptsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      drivers: // value for 'drivers'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useFleetsDriversReceiptsQuery(baseOptions: Apollo.QueryHookOptions<FleetsDriversReceiptsQuery, FleetsDriversReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsDriversReceiptsQuery, FleetsDriversReceiptsQueryVariables>(FleetsDriversReceiptsDocument, options);
      }
export function useFleetsDriversReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsDriversReceiptsQuery, FleetsDriversReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsDriversReceiptsQuery, FleetsDriversReceiptsQueryVariables>(FleetsDriversReceiptsDocument, options);
        }
export type FleetsDriversReceiptsQueryHookResult = ReturnType<typeof useFleetsDriversReceiptsQuery>;
export type FleetsDriversReceiptsLazyQueryHookResult = ReturnType<typeof useFleetsDriversReceiptsLazyQuery>;
export type FleetsDriversReceiptsQueryResult = Apollo.QueryResult<FleetsDriversReceiptsQuery, FleetsDriversReceiptsQueryVariables>;
export const FleetsDriverSetPasswordDocument = gql`
    mutation fleetsDriverSetPassword($newPassword: String!, $singleTimeToken: String!) {
  fleetsDriverSetPassword(
    newPassword: $newPassword
    singleTimeToken: $singleTimeToken
  ) {
    success
  }
}
    `;
export type FleetsDriverSetPasswordMutationFn = Apollo.MutationFunction<FleetsDriverSetPasswordMutation, FleetsDriverSetPasswordMutationVariables>;

/**
 * __useFleetsDriverSetPasswordMutation__
 *
 * To run a mutation, you first call `useFleetsDriverSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsDriverSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsDriverSetPasswordMutation, { data, loading, error }] = useFleetsDriverSetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      singleTimeToken: // value for 'singleTimeToken'
 *   },
 * });
 */
export function useFleetsDriverSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<FleetsDriverSetPasswordMutation, FleetsDriverSetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsDriverSetPasswordMutation, FleetsDriverSetPasswordMutationVariables>(FleetsDriverSetPasswordDocument, options);
      }
export type FleetsDriverSetPasswordMutationHookResult = ReturnType<typeof useFleetsDriverSetPasswordMutation>;
export type FleetsDriverSetPasswordMutationResult = Apollo.MutationResult<FleetsDriverSetPasswordMutation>;
export type FleetsDriverSetPasswordMutationOptions = Apollo.BaseMutationOptions<FleetsDriverSetPasswordMutation, FleetsDriverSetPasswordMutationVariables>;
export const FleetsDriversDocument = gql`
    query fleetsDrivers($first: Int!, $offset: Int!, $ordering: [DriverTypeOrdering], $search: String, $isActive: Boolean) {
  fleetsDrivers(
    first: $first
    offset: $offset
    ordering: $ordering
    search: $search
    isActive: $isActive
  ) {
    totalCount
    edgeCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        pk
        createdAt
        mobileNumber
        hasSignedUp
        isActive
        ejnCard {
          status
          number
          nameOnCard
          id
          pk
        }
        tags {
          pk
          id
          name
          colour
        }
        user {
          id
          hasElectricJuiceCard
          hasAcceptedTermsAndConditions
          email
          lastName
          firstName
          preferredName
          isActive
        }
        businessEntity {
          pk
          name
        }
      }
    }
  }
}
    `;

/**
 * __useFleetsDriversQuery__
 *
 * To run a query within a React component, call `useFleetsDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsDriversQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      ordering: // value for 'ordering'
 *      search: // value for 'search'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useFleetsDriversQuery(baseOptions: Apollo.QueryHookOptions<FleetsDriversQuery, FleetsDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsDriversQuery, FleetsDriversQueryVariables>(FleetsDriversDocument, options);
      }
export function useFleetsDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsDriversQuery, FleetsDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsDriversQuery, FleetsDriversQueryVariables>(FleetsDriversDocument, options);
        }
export type FleetsDriversQueryHookResult = ReturnType<typeof useFleetsDriversQuery>;
export type FleetsDriversLazyQueryHookResult = ReturnType<typeof useFleetsDriversLazyQuery>;
export type FleetsDriversQueryResult = Apollo.QueryResult<FleetsDriversQuery, FleetsDriversQueryVariables>;
export const FleetsDriversReceiptsAggregateDocument = gql`
    query fleetsDriversReceiptsAggregate($startDate: Date!, $endDate: Date!, $first: Int!, $offset: Int!, $ordering: [DriversReceiptsAggregateConnectionTypeOrdering]) {
  fleetsDriversReceiptsAggregate(
    startDate: $startDate
    endDate: $endDate
    first: $first
    offset: $offset
    ordering: $ordering
  ) {
    totalCount
    edgeCount
    edges {
      node {
        driver {
          pk
          user {
            firstName
            lastName
          }
        }
        totalConsumption
        totalCost
        numberOfReceipts
      }
    }
  }
}
    `;

/**
 * __useFleetsDriversReceiptsAggregateQuery__
 *
 * To run a query within a React component, call `useFleetsDriversReceiptsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsDriversReceiptsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsDriversReceiptsAggregateQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useFleetsDriversReceiptsAggregateQuery(baseOptions: Apollo.QueryHookOptions<FleetsDriversReceiptsAggregateQuery, FleetsDriversReceiptsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsDriversReceiptsAggregateQuery, FleetsDriversReceiptsAggregateQueryVariables>(FleetsDriversReceiptsAggregateDocument, options);
      }
export function useFleetsDriversReceiptsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsDriversReceiptsAggregateQuery, FleetsDriversReceiptsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsDriversReceiptsAggregateQuery, FleetsDriversReceiptsAggregateQueryVariables>(FleetsDriversReceiptsAggregateDocument, options);
        }
export type FleetsDriversReceiptsAggregateQueryHookResult = ReturnType<typeof useFleetsDriversReceiptsAggregateQuery>;
export type FleetsDriversReceiptsAggregateLazyQueryHookResult = ReturnType<typeof useFleetsDriversReceiptsAggregateLazyQuery>;
export type FleetsDriversReceiptsAggregateQueryResult = Apollo.QueryResult<FleetsDriversReceiptsAggregateQuery, FleetsDriversReceiptsAggregateQueryVariables>;
export const FleetsDriversTokensDocument = gql`
    query fleetsDriversTokens($first: Int!, $offset: Int!, $status: EjnEJNTokenStatusChoices, $search: String, $ordering: [DriverTokenTypeOrdering]) {
  fleetsDriversTokens(
    first: $first
    offset: $offset
    status: $status
    search: $search
    ordering: $ordering
  ) {
    edgeCount
    edges {
      cursor
      node {
        createdAt
        driver {
          pk
          user {
            firstName
            lastName
          }
        }
        id
        number
        pk
        status
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
  activeTokens: fleetsDriversTokens(status: ACTIVE) {
    totalCount
  }
  pendingTokens: fleetsDriversTokens(status: PENDING) {
    totalCount
  }
  unassignedTokens: fleetsDriversTokens(status: UNASSIGNED) {
    totalCount
  }
}
    `;

/**
 * __useFleetsDriversTokensQuery__
 *
 * To run a query within a React component, call `useFleetsDriversTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsDriversTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsDriversTokensQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useFleetsDriversTokensQuery(baseOptions: Apollo.QueryHookOptions<FleetsDriversTokensQuery, FleetsDriversTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsDriversTokensQuery, FleetsDriversTokensQueryVariables>(FleetsDriversTokensDocument, options);
      }
export function useFleetsDriversTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsDriversTokensQuery, FleetsDriversTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsDriversTokensQuery, FleetsDriversTokensQueryVariables>(FleetsDriversTokensDocument, options);
        }
export type FleetsDriversTokensQueryHookResult = ReturnType<typeof useFleetsDriversTokensQuery>;
export type FleetsDriversTokensLazyQueryHookResult = ReturnType<typeof useFleetsDriversTokensLazyQuery>;
export type FleetsDriversTokensQueryResult = Apollo.QueryResult<FleetsDriversTokensQuery, FleetsDriversTokensQueryVariables>;
export const FleetsEditAdminActiveStatusDocument = gql`
    mutation fleetsEditAdminActiveStatus($isActive: Boolean!, $pk: Int!) {
  fleetsEditAdminActiveStatus(isActive: $isActive, pk: $pk) {
    success
  }
}
    `;
export type FleetsEditAdminActiveStatusMutationFn = Apollo.MutationFunction<FleetsEditAdminActiveStatusMutation, FleetsEditAdminActiveStatusMutationVariables>;

/**
 * __useFleetsEditAdminActiveStatusMutation__
 *
 * To run a mutation, you first call `useFleetsEditAdminActiveStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsEditAdminActiveStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsEditAdminActiveStatusMutation, { data, loading, error }] = useFleetsEditAdminActiveStatusMutation({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useFleetsEditAdminActiveStatusMutation(baseOptions?: Apollo.MutationHookOptions<FleetsEditAdminActiveStatusMutation, FleetsEditAdminActiveStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsEditAdminActiveStatusMutation, FleetsEditAdminActiveStatusMutationVariables>(FleetsEditAdminActiveStatusDocument, options);
      }
export type FleetsEditAdminActiveStatusMutationHookResult = ReturnType<typeof useFleetsEditAdminActiveStatusMutation>;
export type FleetsEditAdminActiveStatusMutationResult = Apollo.MutationResult<FleetsEditAdminActiveStatusMutation>;
export type FleetsEditAdminActiveStatusMutationOptions = Apollo.BaseMutationOptions<FleetsEditAdminActiveStatusMutation, FleetsEditAdminActiveStatusMutationVariables>;
export const FleetsEditBusinessEntityDocument = gql`
    mutation fleetsEditBusinessEntity($deliveryAddress: EJNCardDeliveryDetailsInput!) {
  fleetsEditBusinessEntity(deliveryAddress: $deliveryAddress) {
    success
  }
}
    `;
export type FleetsEditBusinessEntityMutationFn = Apollo.MutationFunction<FleetsEditBusinessEntityMutation, FleetsEditBusinessEntityMutationVariables>;

/**
 * __useFleetsEditBusinessEntityMutation__
 *
 * To run a mutation, you first call `useFleetsEditBusinessEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsEditBusinessEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsEditBusinessEntityMutation, { data, loading, error }] = useFleetsEditBusinessEntityMutation({
 *   variables: {
 *      deliveryAddress: // value for 'deliveryAddress'
 *   },
 * });
 */
export function useFleetsEditBusinessEntityMutation(baseOptions?: Apollo.MutationHookOptions<FleetsEditBusinessEntityMutation, FleetsEditBusinessEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsEditBusinessEntityMutation, FleetsEditBusinessEntityMutationVariables>(FleetsEditBusinessEntityDocument, options);
      }
export type FleetsEditBusinessEntityMutationHookResult = ReturnType<typeof useFleetsEditBusinessEntityMutation>;
export type FleetsEditBusinessEntityMutationResult = Apollo.MutationResult<FleetsEditBusinessEntityMutation>;
export type FleetsEditBusinessEntityMutationOptions = Apollo.BaseMutationOptions<FleetsEditBusinessEntityMutation, FleetsEditBusinessEntityMutationVariables>;
export const FleetsEditDriverDocument = gql`
    mutation fleetsEditDriver($deliveryAddress: EJNCardDeliveryDetailsInput, $email: String, $givenName: String, $isActive: Boolean, $familyName: String, $mobile: String, $pk: Int!, $tags: [Int]) {
  fleetsEditDriver(
    deliveryAddress: $deliveryAddress
    email: $email
    firstName: $givenName
    isActive: $isActive
    lastName: $familyName
    mobileNumber: $mobile
    pk: $pk
    tags: $tags
  ) {
    success
  }
}
    `;
export type FleetsEditDriverMutationFn = Apollo.MutationFunction<FleetsEditDriverMutation, FleetsEditDriverMutationVariables>;

/**
 * __useFleetsEditDriverMutation__
 *
 * To run a mutation, you first call `useFleetsEditDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsEditDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsEditDriverMutation, { data, loading, error }] = useFleetsEditDriverMutation({
 *   variables: {
 *      deliveryAddress: // value for 'deliveryAddress'
 *      email: // value for 'email'
 *      givenName: // value for 'givenName'
 *      isActive: // value for 'isActive'
 *      familyName: // value for 'familyName'
 *      mobile: // value for 'mobile'
 *      pk: // value for 'pk'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useFleetsEditDriverMutation(baseOptions?: Apollo.MutationHookOptions<FleetsEditDriverMutation, FleetsEditDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsEditDriverMutation, FleetsEditDriverMutationVariables>(FleetsEditDriverDocument, options);
      }
export type FleetsEditDriverMutationHookResult = ReturnType<typeof useFleetsEditDriverMutation>;
export type FleetsEditDriverMutationResult = Apollo.MutationResult<FleetsEditDriverMutation>;
export type FleetsEditDriverMutationOptions = Apollo.BaseMutationOptions<FleetsEditDriverMutation, FleetsEditDriverMutationVariables>;
export const FleetsEditStripeDirectDebitBillingDetailsDocument = gql`
    mutation fleetsEditStripeDirectDebitBillingDetails($address1: String!, $address2: String, $city: String, $email: String!, $name: String!, $postcode: String!) {
  fleetsEditStripeDirectDebitBillingDetails(
    address1: $address1
    address2: $address2
    city: $city
    email: $email
    name: $name
    postcode: $postcode
  ) {
    success
  }
}
    `;
export type FleetsEditStripeDirectDebitBillingDetailsMutationFn = Apollo.MutationFunction<FleetsEditStripeDirectDebitBillingDetailsMutation, FleetsEditStripeDirectDebitBillingDetailsMutationVariables>;

/**
 * __useFleetsEditStripeDirectDebitBillingDetailsMutation__
 *
 * To run a mutation, you first call `useFleetsEditStripeDirectDebitBillingDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsEditStripeDirectDebitBillingDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsEditStripeDirectDebitBillingDetailsMutation, { data, loading, error }] = useFleetsEditStripeDirectDebitBillingDetailsMutation({
 *   variables: {
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      city: // value for 'city'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useFleetsEditStripeDirectDebitBillingDetailsMutation(baseOptions?: Apollo.MutationHookOptions<FleetsEditStripeDirectDebitBillingDetailsMutation, FleetsEditStripeDirectDebitBillingDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsEditStripeDirectDebitBillingDetailsMutation, FleetsEditStripeDirectDebitBillingDetailsMutationVariables>(FleetsEditStripeDirectDebitBillingDetailsDocument, options);
      }
export type FleetsEditStripeDirectDebitBillingDetailsMutationHookResult = ReturnType<typeof useFleetsEditStripeDirectDebitBillingDetailsMutation>;
export type FleetsEditStripeDirectDebitBillingDetailsMutationResult = Apollo.MutationResult<FleetsEditStripeDirectDebitBillingDetailsMutation>;
export type FleetsEditStripeDirectDebitBillingDetailsMutationOptions = Apollo.BaseMutationOptions<FleetsEditStripeDirectDebitBillingDetailsMutation, FleetsEditStripeDirectDebitBillingDetailsMutationVariables>;
export const FleetsEditTagDocument = gql`
    mutation fleetsEditTag($name: String!, $colour: String!, $pk: Int!) {
  fleetsEditTag(name: $name, colour: $colour, pk: $pk) {
    success
  }
}
    `;
export type FleetsEditTagMutationFn = Apollo.MutationFunction<FleetsEditTagMutation, FleetsEditTagMutationVariables>;

/**
 * __useFleetsEditTagMutation__
 *
 * To run a mutation, you first call `useFleetsEditTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsEditTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsEditTagMutation, { data, loading, error }] = useFleetsEditTagMutation({
 *   variables: {
 *      name: // value for 'name'
 *      colour: // value for 'colour'
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useFleetsEditTagMutation(baseOptions?: Apollo.MutationHookOptions<FleetsEditTagMutation, FleetsEditTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsEditTagMutation, FleetsEditTagMutationVariables>(FleetsEditTagDocument, options);
      }
export type FleetsEditTagMutationHookResult = ReturnType<typeof useFleetsEditTagMutation>;
export type FleetsEditTagMutationResult = Apollo.MutationResult<FleetsEditTagMutation>;
export type FleetsEditTagMutationOptions = Apollo.BaseMutationOptions<FleetsEditTagMutation, FleetsEditTagMutationVariables>;
export const FleetsGetPaymentInstructionSecretDocument = gql`
    mutation fleetsGetPaymentInstructionSecret($instructionType: String! = "CARD") {
  fleetsGetPaymentInstructionSecret(instructionType: $instructionType) {
    secretKey
  }
}
    `;
export type FleetsGetPaymentInstructionSecretMutationFn = Apollo.MutationFunction<FleetsGetPaymentInstructionSecretMutation, FleetsGetPaymentInstructionSecretMutationVariables>;

/**
 * __useFleetsGetPaymentInstructionSecretMutation__
 *
 * To run a mutation, you first call `useFleetsGetPaymentInstructionSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsGetPaymentInstructionSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsGetPaymentInstructionSecretMutation, { data, loading, error }] = useFleetsGetPaymentInstructionSecretMutation({
 *   variables: {
 *      instructionType: // value for 'instructionType'
 *   },
 * });
 */
export function useFleetsGetPaymentInstructionSecretMutation(baseOptions?: Apollo.MutationHookOptions<FleetsGetPaymentInstructionSecretMutation, FleetsGetPaymentInstructionSecretMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsGetPaymentInstructionSecretMutation, FleetsGetPaymentInstructionSecretMutationVariables>(FleetsGetPaymentInstructionSecretDocument, options);
      }
export type FleetsGetPaymentInstructionSecretMutationHookResult = ReturnType<typeof useFleetsGetPaymentInstructionSecretMutation>;
export type FleetsGetPaymentInstructionSecretMutationResult = Apollo.MutationResult<FleetsGetPaymentInstructionSecretMutation>;
export type FleetsGetPaymentInstructionSecretMutationOptions = Apollo.BaseMutationOptions<FleetsGetPaymentInstructionSecretMutation, FleetsGetPaymentInstructionSecretMutationVariables>;
export const FleetsInvoicesDocument = gql`
    query fleetsInvoices($ordering: [FleetInvoiceTypeOrdering], $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
  fleetsInvoices(
    ordering: $ordering
    offset: $offset
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        createdAt
        invoiceNumber
        subscriptionTotal
        chargesTotal
        id
        pk
        downloadLink
        chargeBreakdownDownloadLink
        totalGross
        totalNet
        totalVat
        totalCredit
        status
      }
    }
  }
}
    `;

/**
 * __useFleetsInvoicesQuery__
 *
 * To run a query within a React component, call `useFleetsInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsInvoicesQuery({
 *   variables: {
 *      ordering: // value for 'ordering'
 *      offset: // value for 'offset'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useFleetsInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<FleetsInvoicesQuery, FleetsInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsInvoicesQuery, FleetsInvoicesQueryVariables>(FleetsInvoicesDocument, options);
      }
export function useFleetsInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsInvoicesQuery, FleetsInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsInvoicesQuery, FleetsInvoicesQueryVariables>(FleetsInvoicesDocument, options);
        }
export type FleetsInvoicesQueryHookResult = ReturnType<typeof useFleetsInvoicesQuery>;
export type FleetsInvoicesLazyQueryHookResult = ReturnType<typeof useFleetsInvoicesLazyQuery>;
export type FleetsInvoicesQueryResult = Apollo.QueryResult<FleetsInvoicesQuery, FleetsInvoicesQueryVariables>;
export const FleetsReassignCardToDriverDocument = gql`
    mutation fleetsReassignCardToDriver($card: Int!, $newDriver: Int!, $oldDriver: Int!) {
  fleetsReassignCardToDriver(
    card: $card
    newDriver: $newDriver
    oldDriver: $oldDriver
  ) {
    success
  }
}
    `;
export type FleetsReassignCardToDriverMutationFn = Apollo.MutationFunction<FleetsReassignCardToDriverMutation, FleetsReassignCardToDriverMutationVariables>;

/**
 * __useFleetsReassignCardToDriverMutation__
 *
 * To run a mutation, you first call `useFleetsReassignCardToDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsReassignCardToDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsReassignCardToDriverMutation, { data, loading, error }] = useFleetsReassignCardToDriverMutation({
 *   variables: {
 *      card: // value for 'card'
 *      newDriver: // value for 'newDriver'
 *      oldDriver: // value for 'oldDriver'
 *   },
 * });
 */
export function useFleetsReassignCardToDriverMutation(baseOptions?: Apollo.MutationHookOptions<FleetsReassignCardToDriverMutation, FleetsReassignCardToDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsReassignCardToDriverMutation, FleetsReassignCardToDriverMutationVariables>(FleetsReassignCardToDriverDocument, options);
      }
export type FleetsReassignCardToDriverMutationHookResult = ReturnType<typeof useFleetsReassignCardToDriverMutation>;
export type FleetsReassignCardToDriverMutationResult = Apollo.MutationResult<FleetsReassignCardToDriverMutation>;
export type FleetsReassignCardToDriverMutationOptions = Apollo.BaseMutationOptions<FleetsReassignCardToDriverMutation, FleetsReassignCardToDriverMutationVariables>;
export const FleetsRedeemCreditCodeDocument = gql`
    mutation fleetsRedeemCreditCode($code: String!) {
  fleetsRedeemCreditCode(code: $code) {
    success
  }
}
    `;
export type FleetsRedeemCreditCodeMutationFn = Apollo.MutationFunction<FleetsRedeemCreditCodeMutation, FleetsRedeemCreditCodeMutationVariables>;

/**
 * __useFleetsRedeemCreditCodeMutation__
 *
 * To run a mutation, you first call `useFleetsRedeemCreditCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsRedeemCreditCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsRedeemCreditCodeMutation, { data, loading, error }] = useFleetsRedeemCreditCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useFleetsRedeemCreditCodeMutation(baseOptions?: Apollo.MutationHookOptions<FleetsRedeemCreditCodeMutation, FleetsRedeemCreditCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsRedeemCreditCodeMutation, FleetsRedeemCreditCodeMutationVariables>(FleetsRedeemCreditCodeDocument, options);
      }
export type FleetsRedeemCreditCodeMutationHookResult = ReturnType<typeof useFleetsRedeemCreditCodeMutation>;
export type FleetsRedeemCreditCodeMutationResult = Apollo.MutationResult<FleetsRedeemCreditCodeMutation>;
export type FleetsRedeemCreditCodeMutationOptions = Apollo.BaseMutationOptions<FleetsRedeemCreditCodeMutation, FleetsRedeemCreditCodeMutationVariables>;
export const FleetsReferralSummaryDocument = gql`
    query fleetsReferralSummary {
  fleetsReferralSummary {
    totalReferrals
    totalCompletedReferrals
    totalRewarded
    currency {
      name
      namePlural
      symbol
      nativeSymbol
      decimalDigits
      minorUnitConversion
      isoCode
      id
    }
  }
}
    `;

/**
 * __useFleetsReferralSummaryQuery__
 *
 * To run a query within a React component, call `useFleetsReferralSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsReferralSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsReferralSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsReferralSummaryQuery(baseOptions?: Apollo.QueryHookOptions<FleetsReferralSummaryQuery, FleetsReferralSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsReferralSummaryQuery, FleetsReferralSummaryQueryVariables>(FleetsReferralSummaryDocument, options);
      }
export function useFleetsReferralSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsReferralSummaryQuery, FleetsReferralSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsReferralSummaryQuery, FleetsReferralSummaryQueryVariables>(FleetsReferralSummaryDocument, options);
        }
export type FleetsReferralSummaryQueryHookResult = ReturnType<typeof useFleetsReferralSummaryQuery>;
export type FleetsReferralSummaryLazyQueryHookResult = ReturnType<typeof useFleetsReferralSummaryLazyQuery>;
export type FleetsReferralSummaryQueryResult = Apollo.QueryResult<FleetsReferralSummaryQuery, FleetsReferralSummaryQueryVariables>;
export const FleetsRemoveDriverVehicleDocument = gql`
    mutation fleetsRemoveDriverVehicle($driver: Int!, $fleetVehicle: Int!) {
  fleetsRemoveDriverVehicle(driver: $driver, fleetVehicle: $fleetVehicle) {
    success
  }
}
    `;
export type FleetsRemoveDriverVehicleMutationFn = Apollo.MutationFunction<FleetsRemoveDriverVehicleMutation, FleetsRemoveDriverVehicleMutationVariables>;

/**
 * __useFleetsRemoveDriverVehicleMutation__
 *
 * To run a mutation, you first call `useFleetsRemoveDriverVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsRemoveDriverVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsRemoveDriverVehicleMutation, { data, loading, error }] = useFleetsRemoveDriverVehicleMutation({
 *   variables: {
 *      driver: // value for 'driver'
 *      fleetVehicle: // value for 'fleetVehicle'
 *   },
 * });
 */
export function useFleetsRemoveDriverVehicleMutation(baseOptions?: Apollo.MutationHookOptions<FleetsRemoveDriverVehicleMutation, FleetsRemoveDriverVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsRemoveDriverVehicleMutation, FleetsRemoveDriverVehicleMutationVariables>(FleetsRemoveDriverVehicleDocument, options);
      }
export type FleetsRemoveDriverVehicleMutationHookResult = ReturnType<typeof useFleetsRemoveDriverVehicleMutation>;
export type FleetsRemoveDriverVehicleMutationResult = Apollo.MutationResult<FleetsRemoveDriverVehicleMutation>;
export type FleetsRemoveDriverVehicleMutationOptions = Apollo.BaseMutationOptions<FleetsRemoveDriverVehicleMutation, FleetsRemoveDriverVehicleMutationVariables>;
export const FleetsRemoveVehicleDocument = gql`
    mutation fleetsRemoveVehicle($fleetVehicle: Int!) {
  fleetsRemoveVehicle(fleetVehicle: $fleetVehicle) {
    success
  }
}
    `;
export type FleetsRemoveVehicleMutationFn = Apollo.MutationFunction<FleetsRemoveVehicleMutation, FleetsRemoveVehicleMutationVariables>;

/**
 * __useFleetsRemoveVehicleMutation__
 *
 * To run a mutation, you first call `useFleetsRemoveVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsRemoveVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsRemoveVehicleMutation, { data, loading, error }] = useFleetsRemoveVehicleMutation({
 *   variables: {
 *      fleetVehicle: // value for 'fleetVehicle'
 *   },
 * });
 */
export function useFleetsRemoveVehicleMutation(baseOptions?: Apollo.MutationHookOptions<FleetsRemoveVehicleMutation, FleetsRemoveVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsRemoveVehicleMutation, FleetsRemoveVehicleMutationVariables>(FleetsRemoveVehicleDocument, options);
      }
export type FleetsRemoveVehicleMutationHookResult = ReturnType<typeof useFleetsRemoveVehicleMutation>;
export type FleetsRemoveVehicleMutationResult = Apollo.MutationResult<FleetsRemoveVehicleMutation>;
export type FleetsRemoveVehicleMutationOptions = Apollo.BaseMutationOptions<FleetsRemoveVehicleMutation, FleetsRemoveVehicleMutationVariables>;
export const FleetsRetrieveCharityInfoDocument = gql`
    mutation fleetsRetrieveCharityInfo($charityNumber: String!, $signupToken: String!) {
  fleetsRetrieveCharityInfo(
    charityNumber: $charityNumber
    signupToken: $signupToken
  ) {
    charityInfo {
      charityName
      charityNumber
      address {
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        postalCode
      }
    }
    success
  }
}
    `;
export type FleetsRetrieveCharityInfoMutationFn = Apollo.MutationFunction<FleetsRetrieveCharityInfoMutation, FleetsRetrieveCharityInfoMutationVariables>;

/**
 * __useFleetsRetrieveCharityInfoMutation__
 *
 * To run a mutation, you first call `useFleetsRetrieveCharityInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsRetrieveCharityInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsRetrieveCharityInfoMutation, { data, loading, error }] = useFleetsRetrieveCharityInfoMutation({
 *   variables: {
 *      charityNumber: // value for 'charityNumber'
 *      signupToken: // value for 'signupToken'
 *   },
 * });
 */
export function useFleetsRetrieveCharityInfoMutation(baseOptions?: Apollo.MutationHookOptions<FleetsRetrieveCharityInfoMutation, FleetsRetrieveCharityInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsRetrieveCharityInfoMutation, FleetsRetrieveCharityInfoMutationVariables>(FleetsRetrieveCharityInfoDocument, options);
      }
export type FleetsRetrieveCharityInfoMutationHookResult = ReturnType<typeof useFleetsRetrieveCharityInfoMutation>;
export type FleetsRetrieveCharityInfoMutationResult = Apollo.MutationResult<FleetsRetrieveCharityInfoMutation>;
export type FleetsRetrieveCharityInfoMutationOptions = Apollo.BaseMutationOptions<FleetsRetrieveCharityInfoMutation, FleetsRetrieveCharityInfoMutationVariables>;
export const FleetsRetrieveCompanyHouseInfoDocument = gql`
    mutation fleetsRetrieveCompanyHouseInfo($companyHouseNumber: String!, $signupToken: String!) {
  fleetsRetrieveCompanyHouseInfo(
    companyHouseNumber: $companyHouseNumber
    signupToken: $signupToken
  ) {
    success
    companyHouseInfo {
      companyName
      companyNumber
      registeredOfficeAddress {
        postalCode
        locality
        addressLine1
        addressLine2
      }
    }
  }
}
    `;
export type FleetsRetrieveCompanyHouseInfoMutationFn = Apollo.MutationFunction<FleetsRetrieveCompanyHouseInfoMutation, FleetsRetrieveCompanyHouseInfoMutationVariables>;

/**
 * __useFleetsRetrieveCompanyHouseInfoMutation__
 *
 * To run a mutation, you first call `useFleetsRetrieveCompanyHouseInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsRetrieveCompanyHouseInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsRetrieveCompanyHouseInfoMutation, { data, loading, error }] = useFleetsRetrieveCompanyHouseInfoMutation({
 *   variables: {
 *      companyHouseNumber: // value for 'companyHouseNumber'
 *      signupToken: // value for 'signupToken'
 *   },
 * });
 */
export function useFleetsRetrieveCompanyHouseInfoMutation(baseOptions?: Apollo.MutationHookOptions<FleetsRetrieveCompanyHouseInfoMutation, FleetsRetrieveCompanyHouseInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsRetrieveCompanyHouseInfoMutation, FleetsRetrieveCompanyHouseInfoMutationVariables>(FleetsRetrieveCompanyHouseInfoDocument, options);
      }
export type FleetsRetrieveCompanyHouseInfoMutationHookResult = ReturnType<typeof useFleetsRetrieveCompanyHouseInfoMutation>;
export type FleetsRetrieveCompanyHouseInfoMutationResult = Apollo.MutationResult<FleetsRetrieveCompanyHouseInfoMutation>;
export type FleetsRetrieveCompanyHouseInfoMutationOptions = Apollo.BaseMutationOptions<FleetsRetrieveCompanyHouseInfoMutation, FleetsRetrieveCompanyHouseInfoMutationVariables>;
export const FleetsRetrieveVatRegistrationDocument = gql`
    mutation fleetsRetrieveVatRegistration($vatNumber: String!, $signupToken: String!) {
  fleetsRetrieveVatRegistration(vatNumber: $vatNumber, signupToken: $signupToken) {
    vatRegistration {
      vatNumber
      name
    }
    success
  }
}
    `;
export type FleetsRetrieveVatRegistrationMutationFn = Apollo.MutationFunction<FleetsRetrieveVatRegistrationMutation, FleetsRetrieveVatRegistrationMutationVariables>;

/**
 * __useFleetsRetrieveVatRegistrationMutation__
 *
 * To run a mutation, you first call `useFleetsRetrieveVatRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsRetrieveVatRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsRetrieveVatRegistrationMutation, { data, loading, error }] = useFleetsRetrieveVatRegistrationMutation({
 *   variables: {
 *      vatNumber: // value for 'vatNumber'
 *      signupToken: // value for 'signupToken'
 *   },
 * });
 */
export function useFleetsRetrieveVatRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<FleetsRetrieveVatRegistrationMutation, FleetsRetrieveVatRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsRetrieveVatRegistrationMutation, FleetsRetrieveVatRegistrationMutationVariables>(FleetsRetrieveVatRegistrationDocument, options);
      }
export type FleetsRetrieveVatRegistrationMutationHookResult = ReturnType<typeof useFleetsRetrieveVatRegistrationMutation>;
export type FleetsRetrieveVatRegistrationMutationResult = Apollo.MutationResult<FleetsRetrieveVatRegistrationMutation>;
export type FleetsRetrieveVatRegistrationMutationOptions = Apollo.BaseMutationOptions<FleetsRetrieveVatRegistrationMutation, FleetsRetrieveVatRegistrationMutationVariables>;
export const FleetsSendDriverSignupEmailDocument = gql`
    mutation fleetsSendDriverSignupEmail($pk: Int!) {
  fleetsSendSignupEmail(pk: $pk) {
    success
  }
}
    `;
export type FleetsSendDriverSignupEmailMutationFn = Apollo.MutationFunction<FleetsSendDriverSignupEmailMutation, FleetsSendDriverSignupEmailMutationVariables>;

/**
 * __useFleetsSendDriverSignupEmailMutation__
 *
 * To run a mutation, you first call `useFleetsSendDriverSignupEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsSendDriverSignupEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsSendDriverSignupEmailMutation, { data, loading, error }] = useFleetsSendDriverSignupEmailMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useFleetsSendDriverSignupEmailMutation(baseOptions?: Apollo.MutationHookOptions<FleetsSendDriverSignupEmailMutation, FleetsSendDriverSignupEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsSendDriverSignupEmailMutation, FleetsSendDriverSignupEmailMutationVariables>(FleetsSendDriverSignupEmailDocument, options);
      }
export type FleetsSendDriverSignupEmailMutationHookResult = ReturnType<typeof useFleetsSendDriverSignupEmailMutation>;
export type FleetsSendDriverSignupEmailMutationResult = Apollo.MutationResult<FleetsSendDriverSignupEmailMutation>;
export type FleetsSendDriverSignupEmailMutationOptions = Apollo.BaseMutationOptions<FleetsSendDriverSignupEmailMutation, FleetsSendDriverSignupEmailMutationVariables>;
export const FleetsSendFleetsSignupEmailDocument = gql`
    mutation fleetsSendFleetsSignupEmail($email: EmailType!, $referralCode: String, $campaignCode: String) {
  fleetsSendFleetSignupEmail(
    email: $email
    referralCode: $referralCode
    campaignCode: $campaignCode
  ) {
    success
  }
}
    `;
export type FleetsSendFleetsSignupEmailMutationFn = Apollo.MutationFunction<FleetsSendFleetsSignupEmailMutation, FleetsSendFleetsSignupEmailMutationVariables>;

/**
 * __useFleetsSendFleetsSignupEmailMutation__
 *
 * To run a mutation, you first call `useFleetsSendFleetsSignupEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsSendFleetsSignupEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsSendFleetsSignupEmailMutation, { data, loading, error }] = useFleetsSendFleetsSignupEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      referralCode: // value for 'referralCode'
 *      campaignCode: // value for 'campaignCode'
 *   },
 * });
 */
export function useFleetsSendFleetsSignupEmailMutation(baseOptions?: Apollo.MutationHookOptions<FleetsSendFleetsSignupEmailMutation, FleetsSendFleetsSignupEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsSendFleetsSignupEmailMutation, FleetsSendFleetsSignupEmailMutationVariables>(FleetsSendFleetsSignupEmailDocument, options);
      }
export type FleetsSendFleetsSignupEmailMutationHookResult = ReturnType<typeof useFleetsSendFleetsSignupEmailMutation>;
export type FleetsSendFleetsSignupEmailMutationResult = Apollo.MutationResult<FleetsSendFleetsSignupEmailMutation>;
export type FleetsSendFleetsSignupEmailMutationOptions = Apollo.BaseMutationOptions<FleetsSendFleetsSignupEmailMutation, FleetsSendFleetsSignupEmailMutationVariables>;
export const FleetsSendResetPasswordEmailDocument = gql`
    mutation fleetsSendResetPasswordEmail($pk: Int!) {
  fleetsSendResetPasswordEmail(pk: $pk) {
    success
  }
}
    `;
export type FleetsSendResetPasswordEmailMutationFn = Apollo.MutationFunction<FleetsSendResetPasswordEmailMutation, FleetsSendResetPasswordEmailMutationVariables>;

/**
 * __useFleetsSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useFleetsSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsSendResetPasswordEmailMutation, { data, loading, error }] = useFleetsSendResetPasswordEmailMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useFleetsSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<FleetsSendResetPasswordEmailMutation, FleetsSendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsSendResetPasswordEmailMutation, FleetsSendResetPasswordEmailMutationVariables>(FleetsSendResetPasswordEmailDocument, options);
      }
export type FleetsSendResetPasswordEmailMutationHookResult = ReturnType<typeof useFleetsSendResetPasswordEmailMutation>;
export type FleetsSendResetPasswordEmailMutationResult = Apollo.MutationResult<FleetsSendResetPasswordEmailMutation>;
export type FleetsSendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<FleetsSendResetPasswordEmailMutation, FleetsSendResetPasswordEmailMutationVariables>;
export const FleetsStartDirectDebitCheckoutSessionDocument = gql`
    mutation fleetsStartDirectDebitCheckoutSession {
  fleetsStartDirectDebitCheckoutSession {
    checkoutUrl
  }
}
    `;
export type FleetsStartDirectDebitCheckoutSessionMutationFn = Apollo.MutationFunction<FleetsStartDirectDebitCheckoutSessionMutation, FleetsStartDirectDebitCheckoutSessionMutationVariables>;

/**
 * __useFleetsStartDirectDebitCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useFleetsStartDirectDebitCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsStartDirectDebitCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsStartDirectDebitCheckoutSessionMutation, { data, loading, error }] = useFleetsStartDirectDebitCheckoutSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useFleetsStartDirectDebitCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<FleetsStartDirectDebitCheckoutSessionMutation, FleetsStartDirectDebitCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsStartDirectDebitCheckoutSessionMutation, FleetsStartDirectDebitCheckoutSessionMutationVariables>(FleetsStartDirectDebitCheckoutSessionDocument, options);
      }
export type FleetsStartDirectDebitCheckoutSessionMutationHookResult = ReturnType<typeof useFleetsStartDirectDebitCheckoutSessionMutation>;
export type FleetsStartDirectDebitCheckoutSessionMutationResult = Apollo.MutationResult<FleetsStartDirectDebitCheckoutSessionMutation>;
export type FleetsStartDirectDebitCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<FleetsStartDirectDebitCheckoutSessionMutation, FleetsStartDirectDebitCheckoutSessionMutationVariables>;
export const FleetsStorePaymentInstructionFromCheckoutDocument = gql`
    mutation fleetsStorePaymentInstructionFromCheckout($sessionId: String!, $validFrom: DateTime!) {
  fleetsStorePaymentInstructionFromCheckout(
    sessionId: $sessionId
    validFrom: $validFrom
  ) {
    success
  }
}
    `;
export type FleetsStorePaymentInstructionFromCheckoutMutationFn = Apollo.MutationFunction<FleetsStorePaymentInstructionFromCheckoutMutation, FleetsStorePaymentInstructionFromCheckoutMutationVariables>;

/**
 * __useFleetsStorePaymentInstructionFromCheckoutMutation__
 *
 * To run a mutation, you first call `useFleetsStorePaymentInstructionFromCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsStorePaymentInstructionFromCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsStorePaymentInstructionFromCheckoutMutation, { data, loading, error }] = useFleetsStorePaymentInstructionFromCheckoutMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      validFrom: // value for 'validFrom'
 *   },
 * });
 */
export function useFleetsStorePaymentInstructionFromCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<FleetsStorePaymentInstructionFromCheckoutMutation, FleetsStorePaymentInstructionFromCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsStorePaymentInstructionFromCheckoutMutation, FleetsStorePaymentInstructionFromCheckoutMutationVariables>(FleetsStorePaymentInstructionFromCheckoutDocument, options);
      }
export type FleetsStorePaymentInstructionFromCheckoutMutationHookResult = ReturnType<typeof useFleetsStorePaymentInstructionFromCheckoutMutation>;
export type FleetsStorePaymentInstructionFromCheckoutMutationResult = Apollo.MutationResult<FleetsStorePaymentInstructionFromCheckoutMutation>;
export type FleetsStorePaymentInstructionFromCheckoutMutationOptions = Apollo.BaseMutationOptions<FleetsStorePaymentInstructionFromCheckoutMutation, FleetsStorePaymentInstructionFromCheckoutMutationVariables>;
export const FleetsStorePaymentInstructionDocument = gql`
    mutation fleetsStorePaymentInstruction($validFrom: DateTime!, $vendorReference: String!) {
  fleetsStorePaymentInstruction(
    validFrom: $validFrom
    vendorReference: $vendorReference
  ) {
    success
  }
}
    `;
export type FleetsStorePaymentInstructionMutationFn = Apollo.MutationFunction<FleetsStorePaymentInstructionMutation, FleetsStorePaymentInstructionMutationVariables>;

/**
 * __useFleetsStorePaymentInstructionMutation__
 *
 * To run a mutation, you first call `useFleetsStorePaymentInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsStorePaymentInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsStorePaymentInstructionMutation, { data, loading, error }] = useFleetsStorePaymentInstructionMutation({
 *   variables: {
 *      validFrom: // value for 'validFrom'
 *      vendorReference: // value for 'vendorReference'
 *   },
 * });
 */
export function useFleetsStorePaymentInstructionMutation(baseOptions?: Apollo.MutationHookOptions<FleetsStorePaymentInstructionMutation, FleetsStorePaymentInstructionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsStorePaymentInstructionMutation, FleetsStorePaymentInstructionMutationVariables>(FleetsStorePaymentInstructionDocument, options);
      }
export type FleetsStorePaymentInstructionMutationHookResult = ReturnType<typeof useFleetsStorePaymentInstructionMutation>;
export type FleetsStorePaymentInstructionMutationResult = Apollo.MutationResult<FleetsStorePaymentInstructionMutation>;
export type FleetsStorePaymentInstructionMutationOptions = Apollo.BaseMutationOptions<FleetsStorePaymentInstructionMutation, FleetsStorePaymentInstructionMutationVariables>;
export const FleetsSubscriptionsDocument = gql`
    query fleetsSubscriptions {
  fleetsSubscriptions(ordering: {orderBy: PK, direction: ASC}) {
    edges {
      cursor
      node {
        pk
        id
        createdAt
        isActive
        unitType
        price
        currencyCode
        freeCount
        nextPaymentDate
        trialEndDate
        estimatedCost {
          count
          price
          amount
          currencyCode
          type
        }
      }
    }
  }
}
    `;

/**
 * __useFleetsSubscriptionsQuery__
 *
 * To run a query within a React component, call `useFleetsSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<FleetsSubscriptionsQuery, FleetsSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsSubscriptionsQuery, FleetsSubscriptionsQueryVariables>(FleetsSubscriptionsDocument, options);
      }
export function useFleetsSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsSubscriptionsQuery, FleetsSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsSubscriptionsQuery, FleetsSubscriptionsQueryVariables>(FleetsSubscriptionsDocument, options);
        }
export type FleetsSubscriptionsQueryHookResult = ReturnType<typeof useFleetsSubscriptionsQuery>;
export type FleetsSubscriptionsLazyQueryHookResult = ReturnType<typeof useFleetsSubscriptionsLazyQuery>;
export type FleetsSubscriptionsQueryResult = Apollo.QueryResult<FleetsSubscriptionsQuery, FleetsSubscriptionsQueryVariables>;
export const FleetsSummaryStatsDocument = gql`
    query fleetsSummaryStats($startDate: Date!, $endDate: Date!) {
  fleetsSummaryStats(startDate: $startDate, endDate: $endDate) {
    totalCost
    combinedCpoSplit {
      cpo {
        pk
        name
      }
      percentage
    }
    cpoSplitPerDriver {
      driverId
      firstName
      lastName
      cpoSplit {
        cpo {
          pk
          name
        }
        percentage
      }
    }
    averageSessionLength
    totalCo2Saved
    chargerSpeedSplit {
      chargerSpeed
      percentage
    }
  }
}
    `;

/**
 * __useFleetsSummaryStatsQuery__
 *
 * To run a query within a React component, call `useFleetsSummaryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsSummaryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsSummaryStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useFleetsSummaryStatsQuery(baseOptions: Apollo.QueryHookOptions<FleetsSummaryStatsQuery, FleetsSummaryStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsSummaryStatsQuery, FleetsSummaryStatsQueryVariables>(FleetsSummaryStatsDocument, options);
      }
export function useFleetsSummaryStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsSummaryStatsQuery, FleetsSummaryStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsSummaryStatsQuery, FleetsSummaryStatsQueryVariables>(FleetsSummaryStatsDocument, options);
        }
export type FleetsSummaryStatsQueryHookResult = ReturnType<typeof useFleetsSummaryStatsQuery>;
export type FleetsSummaryStatsLazyQueryHookResult = ReturnType<typeof useFleetsSummaryStatsLazyQuery>;
export type FleetsSummaryStatsQueryResult = Apollo.QueryResult<FleetsSummaryStatsQuery, FleetsSummaryStatsQueryVariables>;
export const FleetsTagDriversDocument = gql`
    mutation fleetsTagDrivers($drivers: [Int], $tags: [Int]!) {
  fleetsTagDrivers(drivers: $drivers, tags: $tags) {
    success
  }
}
    `;
export type FleetsTagDriversMutationFn = Apollo.MutationFunction<FleetsTagDriversMutation, FleetsTagDriversMutationVariables>;

/**
 * __useFleetsTagDriversMutation__
 *
 * To run a mutation, you first call `useFleetsTagDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsTagDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsTagDriversMutation, { data, loading, error }] = useFleetsTagDriversMutation({
 *   variables: {
 *      drivers: // value for 'drivers'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useFleetsTagDriversMutation(baseOptions?: Apollo.MutationHookOptions<FleetsTagDriversMutation, FleetsTagDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsTagDriversMutation, FleetsTagDriversMutationVariables>(FleetsTagDriversDocument, options);
      }
export type FleetsTagDriversMutationHookResult = ReturnType<typeof useFleetsTagDriversMutation>;
export type FleetsTagDriversMutationResult = Apollo.MutationResult<FleetsTagDriversMutation>;
export type FleetsTagDriversMutationOptions = Apollo.BaseMutationOptions<FleetsTagDriversMutation, FleetsTagDriversMutationVariables>;
export const FleetsTagsDocument = gql`
    query fleetsTags {
  fleetsTags {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        pk
        name
        colour
      }
    }
    totalCount
    edgeCount
  }
}
    `;

/**
 * __useFleetsTagsQuery__
 *
 * To run a query within a React component, call `useFleetsTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetsTagsQuery(baseOptions?: Apollo.QueryHookOptions<FleetsTagsQuery, FleetsTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsTagsQuery, FleetsTagsQueryVariables>(FleetsTagsDocument, options);
      }
export function useFleetsTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsTagsQuery, FleetsTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsTagsQuery, FleetsTagsQueryVariables>(FleetsTagsDocument, options);
        }
export type FleetsTagsQueryHookResult = ReturnType<typeof useFleetsTagsQuery>;
export type FleetsTagsLazyQueryHookResult = ReturnType<typeof useFleetsTagsLazyQuery>;
export type FleetsTagsQueryResult = Apollo.QueryResult<FleetsTagsQuery, FleetsTagsQueryVariables>;
export const FleetsUntagDriversDocument = gql`
    mutation fleetsUntagDrivers($drivers: [Int], $tags: [Int]!) {
  fleetsUntagDrivers(drivers: $drivers, tags: $tags) {
    success
  }
}
    `;
export type FleetsUntagDriversMutationFn = Apollo.MutationFunction<FleetsUntagDriversMutation, FleetsUntagDriversMutationVariables>;

/**
 * __useFleetsUntagDriversMutation__
 *
 * To run a mutation, you first call `useFleetsUntagDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetsUntagDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetsUntagDriversMutation, { data, loading, error }] = useFleetsUntagDriversMutation({
 *   variables: {
 *      drivers: // value for 'drivers'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useFleetsUntagDriversMutation(baseOptions?: Apollo.MutationHookOptions<FleetsUntagDriversMutation, FleetsUntagDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FleetsUntagDriversMutation, FleetsUntagDriversMutationVariables>(FleetsUntagDriversDocument, options);
      }
export type FleetsUntagDriversMutationHookResult = ReturnType<typeof useFleetsUntagDriversMutation>;
export type FleetsUntagDriversMutationResult = Apollo.MutationResult<FleetsUntagDriversMutation>;
export type FleetsUntagDriversMutationOptions = Apollo.BaseMutationOptions<FleetsUntagDriversMutation, FleetsUntagDriversMutationVariables>;
export const FleetsVehiclesDocument = gql`
    query fleetsVehicles($driver: Int, $first: Int!, $offset: Int!) {
  fleetsVehicles(driver: $driver, first: $first, offset: $offset) {
    totalCount
    edgeCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        pk
        name
        regNumber
        vehicle {
          pk
          id
          availabilityDateFrom
          availabilityDateTo
          vehicleMake
          vehicleModelVersion
          vehicleModel
          octoEvDbId
          rangeReal
          batteryCapacityFull
          batteryCapacityUseable
          chargePlug
          fastchargePlug
          isHybrid
          chargeStandardPower
          efficiencyReal
        }
        drivers {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edgeCount
          totalCount
          edges {
            cursor
            node {
              createdAt
              id
              drivingLicence
              businessEntity {
                pk
                name
                registeredAt
                deliveryAddressLine1
                deliveryAddressLine2
                deliveryPostcode
                deliveryAddressLine3
                deliveryAddressLine4
                deliveryCountry
                isVerified
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFleetsVehiclesQuery__
 *
 * To run a query within a React component, call `useFleetsVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetsVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetsVehiclesQuery({
 *   variables: {
 *      driver: // value for 'driver'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFleetsVehiclesQuery(baseOptions: Apollo.QueryHookOptions<FleetsVehiclesQuery, FleetsVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FleetsVehiclesQuery, FleetsVehiclesQueryVariables>(FleetsVehiclesDocument, options);
      }
export function useFleetsVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FleetsVehiclesQuery, FleetsVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FleetsVehiclesQuery, FleetsVehiclesQueryVariables>(FleetsVehiclesDocument, options);
        }
export type FleetsVehiclesQueryHookResult = ReturnType<typeof useFleetsVehiclesQuery>;
export type FleetsVehiclesLazyQueryHookResult = ReturnType<typeof useFleetsVehiclesLazyQuery>;
export type FleetsVehiclesQueryResult = Apollo.QueryResult<FleetsVehiclesQuery, FleetsVehiclesQueryVariables>;
export const GetEmbeddedSecretForNewPaymentInstructionDocument = gql`
    mutation getEmbeddedSecretForNewPaymentInstruction($instructionType: String = "CARD") {
  getEmbeddedSecretForNewPaymentInstruction(instructionType: $instructionType) {
    secretKey
  }
}
    `;
export type GetEmbeddedSecretForNewPaymentInstructionMutationFn = Apollo.MutationFunction<GetEmbeddedSecretForNewPaymentInstructionMutation, GetEmbeddedSecretForNewPaymentInstructionMutationVariables>;

/**
 * __useGetEmbeddedSecretForNewPaymentInstructionMutation__
 *
 * To run a mutation, you first call `useGetEmbeddedSecretForNewPaymentInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetEmbeddedSecretForNewPaymentInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getEmbeddedSecretForNewPaymentInstructionMutation, { data, loading, error }] = useGetEmbeddedSecretForNewPaymentInstructionMutation({
 *   variables: {
 *      instructionType: // value for 'instructionType'
 *   },
 * });
 */
export function useGetEmbeddedSecretForNewPaymentInstructionMutation(baseOptions?: Apollo.MutationHookOptions<GetEmbeddedSecretForNewPaymentInstructionMutation, GetEmbeddedSecretForNewPaymentInstructionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetEmbeddedSecretForNewPaymentInstructionMutation, GetEmbeddedSecretForNewPaymentInstructionMutationVariables>(GetEmbeddedSecretForNewPaymentInstructionDocument, options);
      }
export type GetEmbeddedSecretForNewPaymentInstructionMutationHookResult = ReturnType<typeof useGetEmbeddedSecretForNewPaymentInstructionMutation>;
export type GetEmbeddedSecretForNewPaymentInstructionMutationResult = Apollo.MutationResult<GetEmbeddedSecretForNewPaymentInstructionMutation>;
export type GetEmbeddedSecretForNewPaymentInstructionMutationOptions = Apollo.BaseMutationOptions<GetEmbeddedSecretForNewPaymentInstructionMutation, GetEmbeddedSecretForNewPaymentInstructionMutationVariables>;
export const GetStripePublicKeyDocument = gql`
    mutation getStripePublicKey {
  getStripePublicKey {
    key
  }
}
    `;
export type GetStripePublicKeyMutationFn = Apollo.MutationFunction<GetStripePublicKeyMutation, GetStripePublicKeyMutationVariables>;

/**
 * __useGetStripePublicKeyMutation__
 *
 * To run a mutation, you first call `useGetStripePublicKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetStripePublicKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getStripePublicKeyMutation, { data, loading, error }] = useGetStripePublicKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetStripePublicKeyMutation(baseOptions?: Apollo.MutationHookOptions<GetStripePublicKeyMutation, GetStripePublicKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetStripePublicKeyMutation, GetStripePublicKeyMutationVariables>(GetStripePublicKeyDocument, options);
      }
export type GetStripePublicKeyMutationHookResult = ReturnType<typeof useGetStripePublicKeyMutation>;
export type GetStripePublicKeyMutationResult = Apollo.MutationResult<GetStripePublicKeyMutation>;
export type GetStripePublicKeyMutationOptions = Apollo.BaseMutationOptions<GetStripePublicKeyMutation, GetStripePublicKeyMutationVariables>;
export const QuickAuthDocument = gql`
    mutation quickAuth($shortLivedToken: String!, $user: Int!) {
  quickAuth(shortLivedToken: $shortLivedToken, user: $user) {
    token
    refreshToken
    refreshExpiresIn
    payload
  }
}
    `;
export type QuickAuthMutationFn = Apollo.MutationFunction<QuickAuthMutation, QuickAuthMutationVariables>;

/**
 * __useQuickAuthMutation__
 *
 * To run a mutation, you first call `useQuickAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickAuthMutation, { data, loading, error }] = useQuickAuthMutation({
 *   variables: {
 *      shortLivedToken: // value for 'shortLivedToken'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useQuickAuthMutation(baseOptions?: Apollo.MutationHookOptions<QuickAuthMutation, QuickAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuickAuthMutation, QuickAuthMutationVariables>(QuickAuthDocument, options);
      }
export type QuickAuthMutationHookResult = ReturnType<typeof useQuickAuthMutation>;
export type QuickAuthMutationResult = Apollo.MutationResult<QuickAuthMutation>;
export type QuickAuthMutationOptions = Apollo.BaseMutationOptions<QuickAuthMutation, QuickAuthMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refreshToken: String) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
    refreshExpiresIn
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $newPassword: String!) {
  resetPassword(token: $token, newPassword: $newPassword) {
    payload
    refreshExpiresIn
    token
    refreshToken
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const VehicleMakesDocument = gql`
    query vehicleMakes {
  vehicleMakes
}
    `;

/**
 * __useVehicleMakesQuery__
 *
 * To run a query within a React component, call `useVehicleMakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleMakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleMakesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleMakesQuery(baseOptions?: Apollo.QueryHookOptions<VehicleMakesQuery, VehicleMakesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleMakesQuery, VehicleMakesQueryVariables>(VehicleMakesDocument, options);
      }
export function useVehicleMakesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleMakesQuery, VehicleMakesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleMakesQuery, VehicleMakesQueryVariables>(VehicleMakesDocument, options);
        }
export type VehicleMakesQueryHookResult = ReturnType<typeof useVehicleMakesQuery>;
export type VehicleMakesLazyQueryHookResult = ReturnType<typeof useVehicleMakesLazyQuery>;
export type VehicleMakesQueryResult = Apollo.QueryResult<VehicleMakesQuery, VehicleMakesQueryVariables>;
export const VehicleModelVersionsDocument = gql`
    query vehicleModelVersions($vehicleMake: String!, $vehicleModel: String!) {
  vehicleModelVersions(vehicleMake: $vehicleMake, vehicleModel: $vehicleModel)
}
    `;

/**
 * __useVehicleModelVersionsQuery__
 *
 * To run a query within a React component, call `useVehicleModelVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleModelVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleModelVersionsQuery({
 *   variables: {
 *      vehicleMake: // value for 'vehicleMake'
 *      vehicleModel: // value for 'vehicleModel'
 *   },
 * });
 */
export function useVehicleModelVersionsQuery(baseOptions: Apollo.QueryHookOptions<VehicleModelVersionsQuery, VehicleModelVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleModelVersionsQuery, VehicleModelVersionsQueryVariables>(VehicleModelVersionsDocument, options);
      }
export function useVehicleModelVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleModelVersionsQuery, VehicleModelVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleModelVersionsQuery, VehicleModelVersionsQueryVariables>(VehicleModelVersionsDocument, options);
        }
export type VehicleModelVersionsQueryHookResult = ReturnType<typeof useVehicleModelVersionsQuery>;
export type VehicleModelVersionsLazyQueryHookResult = ReturnType<typeof useVehicleModelVersionsLazyQuery>;
export type VehicleModelVersionsQueryResult = Apollo.QueryResult<VehicleModelVersionsQuery, VehicleModelVersionsQueryVariables>;
export const VehicleModelsDocument = gql`
    query vehicleModels($vehicleMake: String!) {
  vehicleModels(vehicleMake: $vehicleMake)
}
    `;

/**
 * __useVehicleModelsQuery__
 *
 * To run a query within a React component, call `useVehicleModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleModelsQuery({
 *   variables: {
 *      vehicleMake: // value for 'vehicleMake'
 *   },
 * });
 */
export function useVehicleModelsQuery(baseOptions: Apollo.QueryHookOptions<VehicleModelsQuery, VehicleModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleModelsQuery, VehicleModelsQueryVariables>(VehicleModelsDocument, options);
      }
export function useVehicleModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleModelsQuery, VehicleModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleModelsQuery, VehicleModelsQueryVariables>(VehicleModelsDocument, options);
        }
export type VehicleModelsQueryHookResult = ReturnType<typeof useVehicleModelsQuery>;
export type VehicleModelsLazyQueryHookResult = ReturnType<typeof useVehicleModelsLazyQuery>;
export type VehicleModelsQueryResult = Apollo.QueryResult<VehicleModelsQuery, VehicleModelsQueryVariables>;
export const VehiclesByMakeModelVersionDocument = gql`
    query vehiclesByMakeModelVersion($vehicleMake: String!, $vehicleModel: String!, $vehicleModelVersion: String) {
  vehicles(
    vehicleMake: $vehicleMake
    vehicleModel: $vehicleModel
    vehicleModelVersion: $vehicleModelVersion
  ) {
    totalCount
    edges {
      node {
        id
        pk
        availabilityDateFrom
        availabilityDateTo
        vehicleMake
        vehicleModelVersion
        vehicleModel
        octoEvDbId
        rangeReal
        batteryCapacityFull
        batteryCapacityUseable
        chargePlug
        fastchargePlug
        isHybrid
        chargeStandardPower
        efficiencyReal
      }
    }
  }
}
    `;

/**
 * __useVehiclesByMakeModelVersionQuery__
 *
 * To run a query within a React component, call `useVehiclesByMakeModelVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesByMakeModelVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesByMakeModelVersionQuery({
 *   variables: {
 *      vehicleMake: // value for 'vehicleMake'
 *      vehicleModel: // value for 'vehicleModel'
 *      vehicleModelVersion: // value for 'vehicleModelVersion'
 *   },
 * });
 */
export function useVehiclesByMakeModelVersionQuery(baseOptions: Apollo.QueryHookOptions<VehiclesByMakeModelVersionQuery, VehiclesByMakeModelVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehiclesByMakeModelVersionQuery, VehiclesByMakeModelVersionQueryVariables>(VehiclesByMakeModelVersionDocument, options);
      }
export function useVehiclesByMakeModelVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehiclesByMakeModelVersionQuery, VehiclesByMakeModelVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehiclesByMakeModelVersionQuery, VehiclesByMakeModelVersionQueryVariables>(VehiclesByMakeModelVersionDocument, options);
        }
export type VehiclesByMakeModelVersionQueryHookResult = ReturnType<typeof useVehiclesByMakeModelVersionQuery>;
export type VehiclesByMakeModelVersionLazyQueryHookResult = ReturnType<typeof useVehiclesByMakeModelVersionLazyQuery>;
export type VehiclesByMakeModelVersionQueryResult = Apollo.QueryResult<VehiclesByMakeModelVersionQuery, VehiclesByMakeModelVersionQueryVariables>;
export const VerifyTokenDocument = gql`
    mutation verifyToken($token: String) {
  verifyToken(token: $token) {
    payload
  }
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, options);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;