import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Button,
  RadioGroup,
  Radio,
  Skeleton,
  Typography,
} from '@electro/shared-ui-components'
import {
  OrderElectrocardsProvider,
  useOrderElectrocards,
  OrderElectrocardsSteps,
} from '@electro/fleets/src/components/ElectrocardsList/components/OrderElectrocardsForm/hooks'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import {
  useFleetsFetchAdminUser,
  useFleetsFetchBusinessEntity,
  useFleetsFetchDrivers,
  useFleetsFetchDriversTokens,
} from '@electro/fleets/src/services'
import { useMount } from 'react-use'
import { isNumber } from '@electro/shared/utils/typed'
import { SpinnerIcon } from '@electro/fleets/src/icons'
import { getMaxNumberOfCards } from '@electro/fleets/src/utils/getMaxNumberOfCards'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { DemoModeWrapper } from '@electro/fleets/src/components'

type OrderAddress = 'delivery' | 'billing'

export enum OrderCardsFormFieldsNames {
  QUANTITY = 'quantity',
  ADDRESS1 = 'address1',
  ADDRESS2 = 'address2',
  ADDRESS3 = 'address3',
  ADDRESS4 = 'address4',
  POSTCODE = 'postcode',
}

const { QUANTITY, ADDRESS1, ADDRESS2, ADDRESS3, ADDRESS4, POSTCODE } = OrderCardsFormFieldsNames

export interface OrderElectrocardsFormFields {
  [QUANTITY]: number
  [ADDRESS1]: string
  [ADDRESS2]?: string
  [ADDRESS3]?: string
  [ADDRESS4]?: string
  [POSTCODE]: string
}

export interface OrderCardsFormProps {
  formFields?: Partial<OrderElectrocardsFormFields>
  children: ReactNode | ReactNode[]
}

const OrderElectrocardsForm = ({ children, formFields = { quantity: 0 } }: OrderCardsFormProps) => {
  const [fetchDrivers, drivers] = useFleetsFetchDrivers({
    variables: {
      first: 0,
      offset: 0,
    },
  })

  const [fetchElectrocards, electroCards] = useFleetsFetchDriversTokens({
    variables: {
      first: 0,
      offset: 0,
    },
  })

  useEffect(() => {
    fetchDrivers()
    fetchElectrocards()
  }, [fetchDrivers, fetchElectrocards])

  const maxNumberOfCards = getMaxNumberOfCards({
    driversCount: drivers.data?.fleetsDrivers?.totalCount,
    pendingCards: electroCards?.data?.pendingTokens?.totalCount,
    activeCards: electroCards?.data?.activeTokens?.totalCount,
    unassignedCards: electroCards?.data?.unassignedTokens?.totalCount,
  })

  const loading = useMemo(
    () => drivers.loading || electroCards.loading,
    [drivers.loading, electroCards.loading],
  )

  const error = useMemo(
    () => drivers.error || electroCards.error,
    [drivers.error, electroCards.error],
  )

  return (
    <>
      {loading ? (
        <div className="flex flex-col gap-4">
          <Skeleton />
          <Skeleton />
          <div className="flex gap-4 items-center justify-center">
            <Skeleton className="h-12 w-12" />
            <Skeleton className="h-24 w-24" />
            <Skeleton className="h-12 w-12" />
          </div>
          <Skeleton />
          <Skeleton />
          <Skeleton className="h-12" variant="circular" />
        </div>
      ) : null}
      {!loading && !error ? (
        <OrderElectrocardsProvider formFields={formFields} maxNumberOfCards={maxNumberOfCards}>
          {children}
        </OrderElectrocardsProvider>
      ) : null}

      {!loading && error ? (
        <Alert variant="error" title="Error">
          {error.message}
        </Alert>
      ) : null}
    </>
  )
}

const Fields = () => {
  const { t } = useTranslation('common')
  const { formik, step, validateOnBlur, maxNumberOfCards } = useOrderElectrocards()
  const [
    fetchBusinessEntity,
    { data: businessEntityData, error: businessEntityError, loading: businessEntityLoading },
  ] = useFleetsFetchBusinessEntity()
  const [fetchAdminUser, { data: adminUserData }] = useFleetsFetchAdminUser()
  const [addressSelected, setAddressSelected] = useState<OrderAddress>('delivery')
  const [initializeAddressField, setInitializeAddressField] = useState(false)
  const deliveryAddressLabel = useMemo(() => {
    const { deliveryAddressLine1, deliveryAddressLine2, deliveryPostcode } =
      businessEntityData?.fleetsBusinessEntity || {}
    const deliveryAddressLine2Label = deliveryAddressLine2 ? `${deliveryAddressLine2}, ` : ''

    return `${deliveryAddressLine1}, ${deliveryAddressLine2Label}${deliveryPostcode}`
  }, [businessEntityData])
  const billingAddressLabel = useMemo(() => {
    const { billingDetails } = businessEntityData?.fleetsBusinessEntity?.billingAccount || {}
    const { billingAddressLine1, billingAddressLine2, billingAddressPostcode } =
      billingDetails || {}
    const billingAddressLine2Label = billingAddressLine2 ? `${billingAddressLine2}, ` : ''

    return `${billingAddressLine1}, ${billingAddressLine2Label}${billingAddressPostcode}`
  }, [businessEntityData])

  useMount(() => {
    fetchAdminUser()
    fetchBusinessEntity()
  })

  const generateFormFieldsWithAddress = useCallback(
    (
      orderAddress: OrderAddress,
      defaultValues: Partial<OrderElectrocardsFormFields> = {},
    ): OrderElectrocardsFormFields => {
      if (orderAddress === 'billing') {
        const { billingDetails } = businessEntityData?.fleetsBusinessEntity?.billingAccount || {}
        const { billingAddressLine1, billingAddressLine2, billingAddressPostcode } =
          billingDetails || {}

        return {
          quantity: 0,
          ...defaultValues,
          address1: billingAddressLine1,
          address2: billingAddressLine2,
          postcode: billingAddressPostcode,
        }
      }
      const {
        deliveryAddressLine1,
        deliveryAddressLine2,
        deliveryAddressLine3,
        deliveryAddressLine4,
        deliveryPostcode,
      } = businessEntityData?.fleetsBusinessEntity || {}

      return {
        quantity: 0,
        ...defaultValues,
        address1: deliveryAddressLine1,
        address2: deliveryAddressLine2,
        address3: deliveryAddressLine3,
        address4: deliveryAddressLine4,
        postcode: deliveryPostcode,
      }
    },
    [businessEntityData],
  )

  // this runs only once just to initialize the form address
  useEffect(() => {
    if (!initializeAddressField && businessEntityData && adminUserData?.fleetsCurrentAdmin.user) {
      const formFields = generateFormFieldsWithAddress('delivery', formik.values)
      formik.setValues(formFields)
      setInitializeAddressField(true)
    }
  }, [
    businessEntityData,
    adminUserData?.fleetsCurrentAdmin.user,
    formik,
    generateFormFieldsWithAddress,
    initializeAddressField,
  ])

  const addressChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name as OrderAddress
    const formFields = generateFormFieldsWithAddress(name, formik.values)

    formik.setValues({ ...formFields, quantity: formik.values[QUANTITY] })
    setAddressSelected(name)
  }

  if (step !== OrderElectrocardsSteps.FORM) return null

  const disabledMinusButton = formik.values[QUANTITY] === 0
  const inputWidth = formik.values[QUANTITY].toString().length * 24
  const hasError = !!Object.values(formik.errors).length

  return (
    <form
      aria-label="order-electrocards-form"
      id="order-electrocards-form"
      onBlur={validateOnBlur}
      onSubmit={formik.handleSubmit}
    >
      {businessEntityLoading && !businessEntityError && (
        <div data-testid="loading-spinner" className="h-20 flex items-center justify-center">
          <SpinnerIcon className="animate-spin w-8, h-8" />
        </div>
      )}

      {!businessEntityLoading && businessEntityError && (
        <Alert variant="error">{t('electrocards.order_cards_form.unable_fetch_address')}</Alert>
      )}

      {!businessEntityLoading && !businessEntityError && (
        <>
          <div className="font-medium mb-4">
            <Trans
              i18nKey="common:electrocards.order_cards_form.max_card_order_message"
              components={{ maxNumberOfCards: <span>{maxNumberOfCards}</span> }}
            />
          </div>

          {hasError && <Alert variant="error">{Object.values(formik.errors)[0]}</Alert>}

          <div className="flex flex-col my-8 break-all">
            <div className="flex justify-center">
              <Button
                size="xs"
                disabled={disabledMinusButton}
                variant="naked"
                data-testid="button-minus"
                onClick={() => formik.setFieldValue(QUANTITY, formik.values[QUANTITY] - 1)}
              >
                <MinusIcon className="w-8 h-8" />
              </Button>

              <div>
                <input
                  style={{ minWidth: 40, width: inputWidth }}
                  name={QUANTITY}
                  value={formik.values[QUANTITY]}
                  data-testid="input-number"
                  data-hj-allow
                  className="h-full text-center text-4xl font-medium max-w-[250px] bg-transparent"
                  onChange={(event) => {
                    const { value } = event.currentTarget

                    if (value === '') {
                      formik.setFieldValue(QUANTITY, value)
                    } else {
                      const number = parseInt(value, 10)

                      if (isNumber(number)) formik.setFieldValue(QUANTITY, number)
                    }
                  }}
                />
              </div>

              <Button
                disabled={formik.values[QUANTITY] >= maxNumberOfCards}
                size="xs"
                variant="naked"
                data-testid="button-plus"
                onClick={() => formik.setFieldValue(QUANTITY, formik.values[QUANTITY] + 1)}
              >
                <PlusIcon className="w-8 h-8" />
              </Button>
            </div>

            <RadioGroup
              className="mt-8"
              direction="column"
              value={formik.values[POSTCODE]}
              onChange={addressChangeHandler}
            >
              <Radio
                name="delivery"
                checked={addressSelected === 'delivery'}
                label={
                  <>
                    <Typography>
                      {t('electrocards.order_cards_form.order_to_delivery_address')}
                    </Typography>
                    <Typography variant="small" className="opacity-75">
                      {deliveryAddressLabel}
                    </Typography>
                  </>
                }
              />
              <Radio
                name="billing"
                checked={addressSelected === 'billing'}
                label={
                  <>
                    <Typography>
                      {t('electrocards.order_cards_form.order_to_billing_address')}
                    </Typography>
                    <Typography variant="small" className="opacity-75">
                      {billingAddressLabel}
                    </Typography>
                  </>
                }
              />
            </RadioGroup>
          </div>
        </>
      )}
    </form>
  )
}

const Review = () => {
  const { t } = useTranslation('common')
  const { formik, step, success, error, validateOnBlur } = useOrderElectrocards()
  const addressLine2Label = formik.values[ADDRESS2] ? `${formik.values[ADDRESS2]}, ` : ''

  if (step !== OrderElectrocardsSteps.REVIEW || success) return null

  return (
    <form
      aria-label="order-electrocards-form"
      id="order-electrocards-form"
      onBlur={validateOnBlur}
      onSubmit={formik.handleSubmit}
    >
      {!!error && (
        <Alert variant="error" title="Error" className="mb-4">
          {error}
        </Alert>
      )}

      <div className="font-medium mb-4">{t('electrocards.order_cards_form.review.heading')}</div>

      <div className="mb-4 border-b border-gray-200" />

      <div className=" flex flex-col divide-y">
        <div className="flex flex-col pb-2">
          <Typography variant="h5">{t('common.electrocards')}</Typography>

          <div className="flex justify-between py-2">
            <Typography>{t('common.amount')}</Typography>
            <Typography className="text-right">{formik.values[QUANTITY]}</Typography>
          </div>
        </div>

        <div className="flex flex-col py-4 ">
          <Typography variant="h5">
            {t('electrocards.order_cards_form.review.shipping_address')}
          </Typography>

          <div className="grid grid-cols-3 gap-4 py-2">
            <Typography>{t('common.address')}</Typography>
            <Typography className="text-right col-span-2 break-all">{`${formik.values[ADDRESS1]}, ${addressLine2Label}${formik.values[POSTCODE]}`}</Typography>
          </div>
        </div>

        <div className="border-b border-gray-200" />
      </div>
    </form>
  )
}

const Success = ({ children }) => {
  const { success } = useOrderElectrocards()

  return success ? children : null
}

const SubmitButton = () => {
  const { t } = useTranslation('common')
  const { formik, step, loading, success } = useOrderElectrocards()
  const disabled = !formik.values[QUANTITY]

  if (success) return null

  const buttonText = step === OrderElectrocardsSteps.FORM ? t('common.review') : t('common.order')
  const isReviewStep = step === OrderElectrocardsSteps.REVIEW

  return (
    <DemoModeWrapper show tooltip={isReviewStep ? t('demo.tooltip.card_ordering_disabled') : null}>
      {({ isDemoMode }) => {
        const buttonDisabled = isReviewStep && isDemoMode
        return (
          <Button
            className={tw(`mt-8`, { 'cursor-not-allowed': buttonDisabled })}
            disabled={buttonDisabled || disabled || loading}
            form="order-electrocards-form"
            data-testid="submit"
            fullWidth
            type="submit"
          >
            {loading ? t('electrocards.order_cards_form.review.button.ordering') : buttonText}
          </Button>
        )
      }}
    </DemoModeWrapper>
  )
}

OrderElectrocardsForm.Fields = Fields
OrderElectrocardsForm.Review = Review
OrderElectrocardsForm.Success = Success
OrderElectrocardsForm.SubmitButton = SubmitButton

export { OrderElectrocardsForm }
