import React, { createContext, useContext, ReactNode, useState } from 'react'
import { FormikProps, useFormik } from 'formik'
import { OrderElectrocardsFormFields } from '@electro/fleets/src/components'
import * as Yup from 'yup'
import { postcodeValidator } from '@electro/shared/utils/validators'
import { useFleetsBulkCardOrder } from '@electro/fleets/src/services/useFleetsBulkCardOrder'
import { useFleetsCardOrdersQuery } from '@electro/fleets/generated/graphql'

type OrderElectrocardsContextType = {
  formik: FormikProps<OrderElectrocardsFormFields>
  validateOnBlur: (e: React.FocusEvent<any>) => void
  step: OrderElectrocardsSteps
  loading: boolean
  success: boolean
  error: string
  maxNumberOfCards: number
}

export enum OrderElectrocardsSteps {
  FORM,
  REVIEW,
}

const getOrderElectrocardsFormValidationSchema = ({ maxNumberOfCards = 10 }) =>
  Yup.object().shape({
    quantity: Yup.number().max(maxNumberOfCards).positive().required('Card quantity is required.'),
    address1: Yup.string().required('Your address first line is required.'),
    address2: Yup.string(),
    address3: Yup.string(),
    address4: Yup.string(),
    postcode: Yup.string()
      .test('postcode', 'This postcode is not valid.', postcodeValidator)
      .required('Your postcode is required.'),
    country: Yup.string().required('Country code is required.'),
  })

const OrderElectrocardsContext = createContext(null)

const useOrderElectrocardsProvider = ({
  formFields,
  maxNumberOfCards,
}): OrderElectrocardsContextType => {
  const [bulkCardOrder] = useFleetsBulkCardOrder()
  const { refetch: refetchCardOrders } = useFleetsCardOrdersQuery()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [step, setStep] = useState(OrderElectrocardsSteps.FORM)

  const submitHandler = async (values: OrderElectrocardsFormFields) => {
    if (step === OrderElectrocardsSteps.FORM) {
      setStep(OrderElectrocardsSteps.REVIEW)
    } else if (step === OrderElectrocardsSteps.REVIEW) {
      try {
        setLoading(true)

        await bulkCardOrder({
          variables: values,
        })
        await refetchCardOrders()
        setSuccess(true)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      ...formFields,
      country: 'GBR',
    },
    validationSchema: getOrderElectrocardsFormValidationSchema({ maxNumberOfCards }),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: submitHandler,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  return { formik, validateOnBlur, step, loading, success, error, maxNumberOfCards }
}

interface Props {
  formFields?: Partial<OrderElectrocardsFormFields>
  children: ReactNode | ReactNode[]
  maxNumberOfCards: number
}

export const OrderElectrocardsProvider = ({ formFields, children, maxNumberOfCards }: Props) => {
  const value = useOrderElectrocardsProvider({ formFields, maxNumberOfCards })

  return (
    <OrderElectrocardsContext.Provider value={value}>{children}</OrderElectrocardsContext.Provider>
  )
}

export const useOrderElectrocards = (): OrderElectrocardsContextType => {
  const context = useContext(OrderElectrocardsContext)

  if (!context)
    throw new Error('useOrderElectrocards() cannot be used outside of <OrderElectrocardsProvider/>')
  return context
}
