import { Modal, Tooltip, TruncatedText } from '@electro/shared-ui-components'
import { FleetReceiptLocationType } from '@electro/fleets/generated/graphql'
import { useCallback, useState } from 'react'

interface DriverReceiptLocationProps {
  location: FleetReceiptLocationType
  chargingLocationId: string
}

export const DriverReceiptLocation = ({
  location,
  chargingLocationId,
}: DriverReceiptLocationProps) => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = useCallback(() => setShowModal((prevShowModal) => !prevShowModal), [])

  const iframeSrc = `https://electroverse.octopus.energy/map/embed/location/${chargingLocationId}`

  return (
    <>
      <Tooltip>
        <Tooltip.Trigger ariaLabel="show full address of selected drivers receipt" delay={500}>
          <div>
            <button
              className="underline text-secondary-light text-sm text-left w-auto"
              onClick={toggleModal}
            >
              <TruncatedText maxLength={20}>{location.address}</TruncatedText>
            </button>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Body>{location.address}</Tooltip.Body>
      </Tooltip>
      <Modal open={showModal} data-testid="location-modal" onClose={toggleModal} size="full">
        <Modal.Body>
          <Modal.CloseButton />
          <section className="h-[calc(100vh-theme(spacing.20))]">
            <iframe
              data-testid="charge-location-map-embed"
              src={iframeSrc}
              width="100%"
              height="100%"
              title="Map"
            />
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}
