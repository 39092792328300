import { ApolloError } from '@apollo/client'
import { ErrorCode } from '@electro/fleets/generated/graphql'
import { getErrorMessages } from '@electro/fleets/src/utils/getErrorMessages'

export default async function getMessageFromError(error: ApolloError, locale) {
  const [firstError] = error?.graphQLErrors || []

  if (typeof firstError === 'object' && 'errorCode' in firstError) {
    const errorMessages = await getErrorMessages(locale)
    const errorCode = firstError?.errorCode as ErrorCode
    const errorMessage = errorMessages[errorCode] || errorMessages.DEFAULT_ERROR_MESSAGE

    return errorMessage
  }

  return null
}
