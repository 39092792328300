import {
  Card,
  Button,
  Menu,
  useToastNotification,
  DataGridColumnDef,
  DataGridChangeParams,
} from '@electro/shared-ui-components'
import { AddEvModal } from '@electro/fleets/src/components/VehicleList/components'
import {
  VehicleListProvider,
  useVehicleList,
} from '@electro/fleets/src/components/VehicleList/hooks'
import {
  useFleetsVehiclesLazyQuery,
  useFleetsRemoveVehicleMutation,
} from '@electro/fleets/generated/graphql'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import FLEETS_VEHICLE_QUERY from '@electro/fleets/graphql/fleetsVehicles.graphql'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { FIRST_PARAM, OFFSET_PARAM } from '@electro/fleets/src/constants/urlParams'
import useTranslation from 'next-translate/useTranslation'
import { useQueryParams } from '@electro/fleets/src/hooks/useQueryParams'
import { useMount } from 'react-use'
import { QueryKeyEnum } from '@electro/fleets/src/hooks/useQueryParams/queryParams.types'
import { DataGridWithPagination } from '@electro/fleets/src/components/DataGridWithPagination'

const VehicleList = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const { queryParams, syncQueryParams } = useQueryParams(QueryKeyEnum.VehicleListPage)
  const { setShowAddVehicleModal } = useVehicleList()
  const { showToastNotification } = useToastNotification()
  const [getVehicles, { data, loading, error }] = useFleetsVehiclesLazyQuery()
  const [removeVehicle, { loading: removeVehicleLoading }] = useFleetsRemoveVehicleMutation({
    refetchQueries: [
      {
        query: FLEETS_VEHICLE_QUERY,
        variables: {
          first: Number(router.query[FIRST_PARAM]) || 20,
          offset: Number(router.query[OFFSET_PARAM]),
        },
      },
    ],
    onError: (err) => {
      Sentry.captureException(err)
      showToastNotification({
        variant: 'error',
        heading: t('errors.common.oops'),
        body: err?.message,
      })
    },
    onCompleted: () =>
      showToastNotification({
        variant: 'success',
        heading: t('common.success'),
        body: t('vehicles.toast.success_remove_vehicle'),
        timeout: 3000,
      }),
  })

  useMount(() => {
    const { first, offset } = queryParams

    getVehicles({ variables: { first, offset } })
    syncQueryParams({ first, offset })
  })

  const handleDataGridChange = ({ first, offset }: DataGridChangeParams) => {
    getVehicles({ variables: { first, offset } })
    syncQueryParams({ first, offset })
  }

  const handleShowAddVehicleModal = () => {
    setShowAddVehicleModal(true)
  }

  const handleRemoveVehicle = (vehiclePK: number) => {
    removeVehicle({
      variables: {
        fleetVehicle: vehiclePK,
      },
    })
  }

  const columns: DataGridColumnDef[] = [
    {
      name: t('vehicles.table.make'),
      field: 'vehicle',
      valueGetter: (vehicle) => vehicle.vehicleMake,
    },
    {
      name: t('vehicles.table.model'),
      field: 'name',
    },
    {
      name: t('vehicles.table.registration'),
      field: 'regNumber',
    },
    {
      name: '',
      field: 'menu',
    },
  ]

  // I have bodged in the menu here until DataGrid is refactored to accept more props - Tim CG
  const rows = data?.fleetsVehicles?.edges?.map((edge) => ({
    ...edge.node,
    menu: (
      <Menu>
        <Menu.Button noPadding chevron={false} data-testid="remove-vehicle-menu-button">
          <EllipsisHorizontalIcon className="w-6 h-6" />
        </Menu.Button>
        <Menu.MenuItemList variant="dark">
          <Menu.MenuItem
            disabled={removeVehicleLoading}
            onSelect={() => handleRemoveVehicle(edge.node.pk)}
          >
            {removeVehicleLoading
              ? t('vehicles.button.removing_vehicle')
              : t('vehicles.button.remove_vehicle')}
          </Menu.MenuItem>
        </Menu.MenuItemList>
      </Menu>
    ),
  }))

  // console.log('QUERY PARAMS ', queryParams)

  return (
    <div>
      <div className="pt-4">
        <Button onClick={handleShowAddVehicleModal}>{t('vehicles.button.add_vehicle')}</Button>
      </div>
      <Card className="p-4 mt-10 lg:p-8">
        <DataGridWithPagination
          resultPerPage={queryParams.first}
          data-testid="vehicle-list"
          loading={loading}
          loadingMessage={t('vehicles.table.loading')}
          emptyTable={data?.fleetsVehicles?.totalCount === 0}
          emptyTableMessage={t('vehicles.table.empty_table')}
          error={!!error}
          errorMessage={error?.message}
          columns={columns}
          rows={rows}
          totalCount={data?.fleetsVehicles?.totalCount}
          onChange={handleDataGridChange}
          initialState={{
            offset: queryParams.offset,
          }}
        />
      </Card>
      <AddEvModal />
    </div>
  )
}

const VehicleListWithProvider = () => (
  <VehicleListProvider>
    <VehicleList />
  </VehicleListProvider>
)

export { VehicleListWithProvider as VehicleList }
