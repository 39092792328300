import { Alert, Button, Modal, Skeleton, Typography } from '@electro/shared-ui-components'
import {
  FleetVehicleType,
  useFleetsVehiclesQuery,
  VehicleType,
} from '@electro/fleets/generated/graphql'
import Link from 'next/link'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import { useState } from 'react'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import { AssignVehicleOptionsSelect } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/components/AssignVehicleOptionsSelect'
import { PlusIcon } from '@heroicons/react/24/solid'
import useTranslation from 'next-translate/useTranslation'

export const AssignVehicleModalScreen = () => {
  const { t } = useTranslation('common')
  const [fleetVehicle, setFleetVehicle] = useState<Omit<FleetVehicleType, 'drivers'>>(null)
  const { driver, assignVehicle, assignVehicleError, assignVehicleLoading } = useDriverWidgets()
  const { data, loading, error } = useFleetsVehiclesQuery({
    fetchPolicy: 'network-only',
    variables: {
      first: 100,
      offset: 0,
    },
  })

  useErrorNotificationEffect({
    error: assignVehicleError,
    message: assignVehicleError?.message,
  })

  const handleSelectVehicleOption = (nextVehicle: VehicleType) => {
    const fleetsVehicleEdge = data?.fleetsVehicles.edges.find(
      (vehicle) => vehicle.node.vehicle.pk === nextVehicle.pk,
    )

    setFleetVehicle(fleetsVehicleEdge?.node)
  }

  const handleAssignVehicle = () =>
    assignVehicle({ variables: { driver: driver.pk, fleetVehicle: fleetVehicle.pk } })
  return (
    <>
      <Modal.Header>{t('drivers.widgets.assign_vehicle.button.assign_vehicle')}</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col max-h-[calc(100vh-212px)]">
          <Typography>
            {t('drivers.widgets.assign_vehicle.heading.select_vehicle_to_assign')}
          </Typography>

          <div className=" mt-7 overflow-auto">
            {loading && (
              <div className="pb-5 space-y-2">
                <Skeleton className="  rounded-xl" width="100%" height={74} />
                <Skeleton className="  rounded-xl" width="100%" height={74} />
                <Skeleton className="  rounded-xl" width="100%" height={74} />
              </div>
            )}

            {!loading && !!data && (
              <AssignVehicleOptionsSelect
                options={data?.fleetsVehicles?.edges}
                onSelect={handleSelectVehicleOption}
              />
            )}
          </div>
        </div>

        {!!error && (
          <Alert variant="error">
            {t('drivers.widgets.assign_vehicle.error.could_not_get_vehicle_data')}
          </Alert>
        )}
      </Modal.Body>

      <Modal.Actions stacked>
        <Button
          form="assign-vehicle-submit-button"
          disabled={!fleetVehicle || assignVehicleLoading}
          onClick={handleAssignVehicle}
          data-testid="assign-vehicle-modal-button"
          fullWidth
        >
          {assignVehicleLoading
            ? t('drivers.widgets.assign_vehicle.button.loading.assigning_vehicle')
            : t('drivers.widgets.assign_vehicle.button.assign_vehicle')}
        </Button>

        <Link
          data-testid="add-more-evs-link-button"
          href="/dashboard/vehicles"
          className="no-underline"
        >
          <Button variant="outline" fullWidth>
            <PlusIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
            {t('drivers.widgets.assign_vehicle.button.add_new_vehicle')}
          </Button>
        </Link>
      </Modal.Actions>
    </>
  )
}
