import { NavSection } from '@electro/fleets/src/components/SideBarNavigation/SideBarNavigation.types'
import { useUiStore } from '@electro/fleets/src/hooks/stores'
import { Tooltip, Typography, Tag, TagColours } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { RestrictToCountries } from '@electro/fleets/src/components/'

interface Props {
  navSection: NavSection
}

const styles = {
  section: {
    root: 'mb-6 !block',
    title: 'uppercase text-white py-3',
    item: {
      root: 'lg:py-3 py-1',
      disabled: 'hover:cursor-not-allowed opacity-50',
      link: {
        root: 'font-normal no-underline focus:text-primary focus:ring-opacity-0 whitespace-nowrap group/menuItem',
        inactive: 'text-white hover:text-primary',
        active: 'text-primary',
        disabled: 'pointer-events-none opacity-40',
      },
      expanded: {
        root: 'ml-16',
        menuItemContainer:
          'flex flex-row items-center  group-hover/menuItem:text-primary w-96 max-w-72',
        text: 'text-[1rem] pl-2 whitespace-normal text-center leading-normal  group-hover/menuItem:text-primary flex items-center',
      },
      contracted: {
        root: '',
        menuItemContainer:
          'flex flex-col items-center group-hover/menuItem:text-primary w-[calc(theme(spacing.24)-theme(spacing.2))]',
        text: 'text-[10px] whitespace-normal text-center leading-normal group-hover/menuItem:text-primary flex flex-col items-center',
      },
    },
  },
  hide: 'opacity-0',
}

export const SideBarNavigationSection = ({ navSection }: Props) => {
  const { t } = useTranslation('common')
  const expanded = useUiStore((state) => state.navigationExpanded)

  const newPages = [t('credit_and_referrals.page.heading'), t('workplace_charging.page.heading')]
  return (
    <Transition
      className={tw({
        [styles.section.root]: true,
        [styles.section.item.expanded.root]: expanded,
      })}
      unmount={false}
      as="li"
      enter="transition-all duration-300"
      enterFrom="ml-0"
      enterTo="opacity-100 ml-16"
      leave="transition-all duration-300"
      leaveFrom="opacity-100 ml-16"
      leaveTo="ml-0"
      show={expanded}
    >
      <Typography
        variant="h3"
        className={tw({
          [styles.section.title]: true,
          [styles.hide]: !expanded,
        })}
      >
        {navSection.title}
      </Typography>

      <ul>
        {navSection.navItems.map((navItem) => (
          <RestrictToCountries countries={navItem.showInCountries} key={navItem.url}>
            <Tooltip disabled={!(navItem.toolTipSection?.text.length > 0)} key={navItem.url}>
              <Tooltip.Trigger ariaLabel={navItem.toolTipSection?.ariaLabel} delay={1000}>
                <li
                  className={clsx({
                    [styles.section.item.root]: true,
                    [styles.section.item.disabled]: navItem.disabled,
                  })}
                >
                  <Link
                    href={navItem.url}
                    className={tw({
                      [styles.section.item.link.root]: true,
                      [styles.section.item.link.inactive]: !navItem.active,
                      [styles.section.item.link.active]: navItem.active,
                      [styles.section.item.link.disabled]: navItem.disabled,
                    })}
                  >
                    <div
                      className={tw({
                        [styles.section.item.expanded.menuItemContainer]: expanded,
                        [styles.section.item.contracted.menuItemContainer]: !expanded,
                      })}
                    >
                      {navItem.icon}
                      <Typography
                        className={tw({
                          [styles.section.item.expanded.text]: expanded,
                          [styles.section.item.contracted.text]: !expanded,
                          [styles.section.item.link.inactive]: !navItem.active,
                          [styles.section.item.link.active]: navItem.active,
                        })}
                      >
                        <Typography
                          className={tw({
                            [styles.section.item.expanded.text]: expanded,
                            [styles.section.item.contracted.text]: !expanded,
                            [styles.section.item.link.inactive]: !navItem.active,
                            [styles.section.item.link.active]: navItem.active,
                          })}
                        >
                          {navItem.label}
                          {newPages.includes(t(navItem.label)) && (
                            <Tag
                              colour={'green' as TagColours}
                              label={t('tags.name.new')}
                              className={`${expanded ? 'ml-4' : ''}`}
                            />
                          )}
                        </Typography>
                      </Typography>
                    </div>
                  </Link>
                </li>
              </Tooltip.Trigger>
              <Tooltip.Body width={200}>{navItem.toolTipSection?.text}</Tooltip.Body>
            </Tooltip>
          </RestrictToCountries>
        ))}
      </ul>
    </Transition>
  )
}
