import { create } from 'zustand'

interface State {
  navigationExpanded: boolean
  setNavigationExpanded: (payload: boolean) => void
}

export const useUiStore = create<State>((set) => ({
  navigationExpanded: true,
  setNavigationExpanded: (payload: boolean) => set({ navigationExpanded: payload }),
}))
