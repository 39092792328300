import React, { useState, useMemo, useCallback } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Typography } from '@electro/shared-ui-components'
import { FleetVehicleTypeEdge, VehicleType } from '@electro/fleets/generated/graphql' // type VehicleTypeProps = VehicleType & { name: string; regNumber: string; nodePk: number }

interface VehicleOptionsSelectProps {
  options: FleetVehicleTypeEdge[]
  onSelect: (vehicle: VehicleType) => void
}

const styles = {
  root: tw(
    'rounded-xl w-full text-left px-4 py-3 mb-1.5 border max-h-20 min-h-8 flex justify-between',
    'border-secondary hover:bg-base/20 hover:border-secondary hover:ring-tertiary',
  ),
  selected: 'ring-1 border-secondary bg-secondary ring-secondary',
}

export const AssignVehicleOptionsSelect = ({ options, onSelect }: VehicleOptionsSelectProps) => {
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null)
  const vehicles: FleetVehicleTypeEdge[] = useMemo(() => {
    if (options?.length === 0) return []

    return [...options].sort((a, b) => a.node.name.localeCompare(b.node.name))
  }, [options])

  const handleSelect = useCallback(
    (vehicle: VehicleType) => () => {
      const { pk } = vehicle
      const selectedVehicle = vehicles.find((v) => v.node.vehicle.pk === pk)
      if (selectedVehicle) {
        setSelectedItemId(selectedVehicle.node.pk)
        onSelect(selectedVehicle.node.vehicle)
      }
    },
    [onSelect, vehicles],
  )

  if (!options) return null

  return (
    <div className="pt-2 pb-5 px-0.5" data-testid="vehicle-options-select">
      {vehicles.map((vehicle) => (
        <button
          className={tw({
            [styles.root]: true,
            [styles.selected]: selectedItemId === vehicle?.node.pk,
          })}
          data-testid="select-box-option"
          key={vehicle.node.pk}
          value={vehicle.node.vehicle.octoEvDbId}
          onClick={handleSelect(vehicle.node.vehicle)}
        >
          <Typography className="w-2/3 truncate">
            {`${vehicle.node.vehicle.vehicleMake} ${vehicle.node.vehicle.vehicleModel}`}
          </Typography>
          {vehicle.node.regNumber !== 'undefined' ? (
            <Typography>{vehicle.node.regNumber}</Typography>
          ) : null}
        </button>
      ))}
    </div>
  )
}
