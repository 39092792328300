import { LazyQueryResult, OperationVariables } from '@apollo/client'
import { FleetsBusinessEntityReceiptsAggregateQuery } from '@electro/fleets/generated/graphql'
import useTranslation from 'next-translate/useTranslation'

interface DriverConsumptionReportSummaryProps {
  fleetsBusinessReceipts: LazyQueryResult<
    FleetsBusinessEntityReceiptsAggregateQuery,
    OperationVariables
  >
}

export const DriverConsumptionReportSummary = ({
  fleetsBusinessReceipts,
}: DriverConsumptionReportSummaryProps) => {
  const { t } = useTranslation('common')

  const businessHasHeadlineData =
    fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges.length > 0

  if (!businessHasHeadlineData) return null

  return (
    <div className="grid lg:grid-cols-3 gap-4 lg:gap-8">
      <div className="border-tertiary border-2 p-4 rounded-2xl">
        <h3 className="mb-1 text-tertiary">{t('dashboard.widgets.title.total_cost')}</h3>
        {fleetsBusinessReceipts.loading && <div className="cp-thickLine" />}
        {businessHasHeadlineData && (
          <div data-testid="total-costs" className="text-xl lg:text-3xl text-tertiary break-all">
            {`£${fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges[0]?.node?.totalCost}`}
          </div>
        )}
      </div>
      <div className="border-2 p-4 rounded-2xl">
        <h3 className="mb-1">{t('reporting.drivers_report.total_consumption')}</h3>
        {fleetsBusinessReceipts.loading && <div className="cp-thickLine" />}
        {businessHasHeadlineData && (
          <div data-testid="total-consumption" className="text-xl lg:text-3xl text-white break-all">
            {`${fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges[0]?.node?.totalConsumption} kWh`}
          </div>
        )}
      </div>
      <div className=" border-action-success border-2 p-4 rounded-2xl">
        <h3 className="mb-1 text-action-success">
          {t('reporting.drivers_report.average_price_per_kwh')}
        </h3>
        {fleetsBusinessReceipts.loading && <div className="cp-thickLine" />}
        {businessHasHeadlineData && (
          <div
            data-testid="total-cost-per-kw"
            className="text-xl lg:text-3xl text-action-success break-all"
          >
            {`${Math.ceil(
              (parseFloat(
                fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges[0]?.node
                  ?.totalCost,
              ) *
                100) /
                parseFloat(
                  fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges[0]?.node
                    ?.totalConsumption,
                ),
            )} p/kWh`}
          </div>
        )}
      </div>
    </div>
  )
}
